<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">   <div class="topback"   :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}">
          <headtip :changeVisable="changeVisable"  @showDialog="showPartner"></headtip>
        </div>

        <div class="makeHole1" @click="changeheadVisable">
          <div class="screen1">
             <kefu @toptop="topTop"></kefu>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1"> <img loading="lazy" style="border-radius: 9px" src="@/assets/AnxunLogo.png"></div>
              <div class="title2">Based on various application scenarios in Oil & Gas fields, Anvision provides professional accompanying services with the most reliable project landing, the fastest project cycle, and the lowest cost to meet the rigid demand for Oil & Gas field digital intelligence. Our services include comprehensive solutions for smart oilfields and digital intelligence services for all Oil & Gas field scenes.</div>
              <div class="title4">Oil & Gas Digitalization, Simple and Practical</div>
              <div class="titlle3"   @click="clickConsult('Oil & Gas Generic AI')">
                Product Inquiry
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenmake0">
            <div class="heighLight">

              Smart Oil & Gas Infrastructure Construction</div>
            <div class="heightLight2" style="font-weight: bold">Smart oil & gas infrastructure construction integrates advanced technology with proprietary products to <span style=" color: #FF6400;">deliver comprehensive system integration and global maintenance support, enabling the oil & gas industry to achieve efficient, safe, and sustainable growth</span>.
            </div>
          </div>
          <div class="screenmake3" style="padding-top: 0;padding-bottom: 0px">
            <div class="heighLight" style="font-size: 1.7vw">   Business Pain Points and Challenges</div>
            <div class="line"></div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign" style="margin:0 auto">
                    <img loading="lazy"  style="border-radius: 50%" src="@/assets/basic01.jpg">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">
                      The automation system has a high degree of closure
                    </div>
                    <div class="title2">
                      The high degree of encapsulation in automation systems makes it difficult to achieve unified management and precise control across different systems.
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign"  style="margin:0 auto">
                    <img loading="lazy"  style="border-radius: 50%;" src="@/assets/basic02.jpg">

                  </div>
                  <div class="titleDesign">
                    <div class="title1">The efficiency of data asset management is low
                    </div>
                    <div class="title2">
                      The lack of effective data asset management results in a heavy reliance on personnel for inefficient searching and analysis.
                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake0">
            <div class="heighLight" style="margin-top:0vh">Service Capabilities</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <el-tooltip class="item" effect="dark" content="Click to enlarge for a clearer view!" placement="top">
                      <img loading="lazy" @click="showModalTrue(require('@/assets/anxun81.png'))" style="border-radius: 9px" src="@/assets/anxun81.png">

                    </el-tooltip>
                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      <li><span style="font-weight: bold;font-size: 1vw">Infrastructure Construction </span><br>Covers the construction of IT rooms, data centers, communication networks, control centers, large screen systems, automatic control systems, instruments, mobile cabinets, and intelligent metering and handover systems.</li>
                      <li style="margin-top: 2vh"><span style="font-weight: bold;font-size: 1vw">Independent Software and Hardware Products </span><br>Provides a series of independently developed software and hardware products, including PLC, RTU, DCS, SIS, FGS, SCADA, etc.</li>
                      <li style="margin-top: 2vh"><span style="font-weight: bold;font-size: 1vw">System Integration and Implementation </span><br>Focuses on system integration, ensuring the coordination of different components and software and hardware products, and provides comprehensive implementation services to ensure stable system operation.</li>
                      <li style="margin-top: 2vh"><span style="font-weight: bold;font-size: 1vw">Maintenance and Operation Services </span><br>  Provides equipment maintenance and operation services to ensure the continuous reliability and efficient performance of digital infrastructure.</li>

                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake0" style="background-color: transparent">
            <div class="heighLight">Service Advantages</div>
            <div class="grid1">
              <div class="card">
                <div class="back">
                  <div style="display: flex">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px" src="@/assets/basic01.png">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">Customized Solutions</div>
                      <div class="title2">Offer tailor-made services to ensure that projects align precisely with the client's business objectives.
                      </div>
                    </div>

                  </div>


                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div style="display: flex">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px" src="@/assets/basic02.png">
                    </div>
                    <div class="titleDesign">    <div class="title1">Global Experience with Local Support</div>
                      <div class="title2">
                        Combine international expertise with localized implementation to ensure smooth project deployment and efficient operations.
                      </div>
                    </div>

                  </div>

                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div style="display: flex">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px" src="@/assets/basic03.png">
                    </div>
                    <div class="titleDesign">     <div class="title1">Advanced Technology and System Integration</div>
                      <div class="title2">
                        Utilize cutting-edge technology to provide seamless integration and implementation services, ensuring stable system operation.
                      </div>
                    </div>

                  </div>

                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div style="display: flex">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px" src="@/assets/basic04.png">
                    </div>

                    <div class="titleDesign"> <div class="title1">Maintenance and Sustainable Development</div>
                      <div class="title2">
                        Provide 24/7 maintenance support, promote green transformation, and ensure reliable and environmentally friendly operational support.
                      </div>
                    </div>
                  </div>

                </div>


              </div>
            </div>

          </div>
          <div class="screenmake4">
            <div class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <el-tooltip class="item" effect="dark" content="Click to enlarge for a clearer view!" placement="top">
                          <img loading="lazy" @click="showModalTrue(item.img)" :src="item.img"/>

                        </el-tooltip>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{item.title1}}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <bottomTip></bottomTip>
        </div>

        <div v-if="showModal" @click="closeModal" class="modal-overlay">
          <div class="modal-content">
            <img :src="largeSrc" alt="Large Image" class="large-image">
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <headtip @showDialog="showPartner"></headtip>
            <kefuMobile @topMobile="topTopmobile"></kefuMobile>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1"> <img loading="lazy" style="border-radius: 9px" src="@/assets/AnxunLogo.png"></div>
              <div class="title2">Based on various application scenarios in Oil & Gas fields, Anvision provides professional accompanying services with the most reliable project landing, the fastest project cycle, and the lowest cost to meet the rigid demand for Oil & Gas field digital intelligence. Our services include comprehensive solutions for smart oilfields and digital intelligence services for all Oil & Gas field scenes.</div>

            </div>
            <div class="title4">Oil & Gas Digitalization, Simple and Practical</div>

            <div class="titlle3"   @click="clickConsult('Oil & Gas Generic AI')">
              Product Inquiry
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenmake0">
            <div class="heighLight">

              Smart Oil & Gas Infrastructure Construction</div>
            <div class="heightLight2" style="font-weight: bold">Smart oil & gas infrastructure construction integrates advanced technology with proprietary products to <span style=" color: #FF6400;">deliver comprehensive system integration and global maintenance support, enabling the oil & gas industry to achieve efficient, safe, and sustainable growth</span>.
            </div>
          </div>
          <div class="screenmake3" style="padding-top: 15px">
            <div class="heighLight" style="height: fit-content"> Business Pain Points and Challenges</div>
            <div class="grid">
              <div class="card" style="margin-top: 10px">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/basic01.jpg" style="border-top-left-radius: 9px;border-top-right-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">The automation system has a high degree of closure
                    </div>
                    <div class="title2">
                      The high degree of encapsulation in automation systems makes it difficult to achieve unified management and precise control across different systems.
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/basic02.jpg" style="border-top-left-radius: 9px;border-top-right-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">The efficiency of data asset management is low
                    </div>
                    <div class="title2">
                      The lack of effective data asset management results in a heavy reliance on personnel for inefficient searching and analysis.
                    </div>
                  </div>
                </div>


              </div>

            </div>

          </div>
          <div class="screenmake0">
            <div class="heighLight" style="margin-top:15px">Service Capabilities</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <el-tooltip class="item" effect="dark" content="Click to enlarge for a clearer view!" placement="top">
                      <img loading="lazy" @click="showModalTrue(require('@/assets/anxun81.png'))" style="border-radius: 9px" src="@/assets/anxun81.png">

                    </el-tooltip>
                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      <li><span style="font-weight: bold">Infrastructure Construction </span><br>Covers the construction of IT rooms, data centers, communication networks, control centers, large screen systems, automatic control systems, instruments, mobile cabinets, and intelligent metering and handover systems.</li>
                      <li style="margin-top: 5px"><span style="font-weight: bold;">Independent Software and Hardware Products </span><br>Provides a series of independently developed software and hardware products, including PLC, RTU, DCS, SIS, FGS, SCADA, etc.</li>
                      <li style="margin-top: 5px"><span style="font-weight: bold">System Integration and Implementation </span><br>Focuses on system integration, ensuring the coordination of different components and software and hardware products, and provides comprehensive implementation services to ensure stable system operation.</li>
                      <li style="margin-top: 5px"><span style="font-weight: bold">Maintenance and Operation Services </span><br>  Provides equipment maintenance and operation services to ensure the continuous reliability and efficient performance of digital infrastructure.</li>

                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake0" style="background-color: transparent">
            <div class="heighLight">Service Advantages</div>
            <div class="grid1">
              <div class="card">
                <div class="back">
                  <div style="display: flex">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px" src="@/assets/basic01.png">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">Customized Solutions</div>
                      <div class="title2">Offer tailor-made services to ensure that projects align precisely with the client's business objectives.
                      </div>
                    </div>

                  </div>


                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div style="display: flex">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px" src="@/assets/basic02.png">
                    </div>
                    <div class="titleDesign">    <div class="title1">Global Experience with Local Support</div>
                      <div class="title2">
                        Combine international expertise with localized implementation to ensure smooth project deployment and efficient operations.
                      </div>
                    </div>

                  </div>

                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div style="display: flex">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px" src="@/assets/basic03.png">
                    </div>
                    <div class="titleDesign">     <div class="title1">Advanced Technology and System Integration</div>
                      <div class="title2">
                        Utilize cutting-edge technology to provide seamless integration and implementation services, ensuring stable system operation.
                      </div>
                    </div>

                  </div>

                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div style="display: flex">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px" src="@/assets/basic04.png">
                    </div>

                    <div class="titleDesign"> <div class="title1">Maintenance and Sustainable Development</div>
                      <div class="title2">
                        Provide 24/7 maintenance support, promote green transformation, and ensure reliable and environmentally friendly operational support.
                      </div>
                    </div>
                  </div>

                </div>


              </div>
            </div>

          </div>
          <div class="screenmake4">
            <div class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img :src="item.img"/>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{item.title1}}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
export default {

  name: "",

  props: [],

  components: {PartnerTip, consultTip, headtip,bottomTip,kefu,kefuMobile},

  data() {

  return {
    changeVisable:1,
    showModal: false,
    largeSrc:'',

    title:'Oil & Gas Generic AI',
      pageTitle: 'Smart Oil & Gas infrastructure construction_Anvision-Oil & Gas Communication GPT',
      pageDescription: 'Anvision Digital Smart Oil & Gas Infrastructure Construction provides facility construction, independent software and hardware products, system integration and implementation, maintenance and operation services, and provides a series of independently developed software and hardware products, including PLC, RTU, DCS, SIS, and FGS, SCADA, etc.',
      pageKeywords: 'Smart Oil & Gas, infrastructure construction, smart Oil & Gas infrastructure construction, wellhead integrated remote transmission system, remote pressure regulation solution, overall solution for skid-mounted cabinets, digital pumping unit control cabinet, Anvision Zhiyun, voice robot assistant, Anvision, Oil & Gas Communication GPT',
      model:false,   scrollback:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/anxun82.png"),
        title1:"Smart Oil & Gas Infrastructure Construction",
        title2:"<li>Construction of Automatic Control System for A Domestic Hydrocarbon Removal Station</li><li>Construction of Data Center in An Oil Field in Iraq</li>",
      },{
        img:require("@/assets/anxun83.png"),
        title1:"Smart Oil & Gas Infrastructure Construction",
        title2:"<li>Construction of Automatic Control System for A Domestic Hydrocarbon Removal Station</li><li>Construction of Data Center in An Oil Field in Iraq</li>",
      },{
        img:require("@/assets/anxun84.png"),
        title1:"Smart Oil & Gas Infrastructure Construction",
        title2:"<li>Construction of Automatic Control System for A Domestic Hydrocarbon Removal Station</li><li>Construction of Data Center in An Oil Field in Iraq</li>",
      },
      ]
    }

  },

  methods: {
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
       window.scrollTo({
         top: 0,
         behavior: 'smooth'
       });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
       const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },

    showModalTrue(item){
      this.showModal = true;
      console.log(item)
      this.largeSrc=item
    },
    closeModal() {
      this.showModal = false;
    },

    clickConsult(item){

      console.log(item)
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
   handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);

    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

   if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Smart Oil & Gas infrastructure construction_Anvision-Oil & Gas Communication GPT',
        pageDescription: 'Anvision Digital Smart Oil & Gas Infrastructure Construction provides facility construction, independent software and hardware products, system integration and implementation, maintenance and operation services, and provides a series of independently developed software and hardware products, including PLC, RTU, DCS, SIS, and FGS, SCADA, etc.',
        pageKeywords: 'Smart Oil & Gas, infrastructure construction, smart Oil & Gas infrastructure construction, wellhead integrated remote transmission system, remote pressure regulation solution, overall solution for skid-mounted cabinets, digital pumping unit control cabinet, Anvision Zhiyun, voice robot assistant, Anxun Digital Intelligence, Oil & Gas Communication GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .screen1 {
      position: relative;
      width: 100%;
      height: 74vh;
      padding: 7.8430160692vh 2.860774119vw 2.8430160692vh 5.860774119vw;
      background-image: url("../../assets/banner.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 16.1495673671199vh;
        left: 8.17157712305026vw;


        .title1 {
          width: fit-content;
          height: 5.77626699629172vh;
          font-size: 3.50606585788562vw;
          font-family: Instrument Sans,Arial;
          font-weight: 400;
          color: #2f6eff;
          line-height: 12.23733003708282vh;
        }

        .title2 {
          margin-top: 4.82076637824475vh;
          width: 37vw;
          //text-align: justify;
          height: fit-content;
          font-size:0.9597920277vw;
          font-family: Instrument Sans,Arial;
          font-weight: 400;
          color: black;
          line-height: 3.51520395550062vh;
        }
        .title4{
          height: 8.77626699629172vh;
          font-size: 1.3060658579vw;
          font-family: Instrument Sans,Arial;
          font-weight: 400;
          color: #2f6eff;
          line-height: 9.31520395550062vh;
        }
        .titlle3{
          margin-top: 2.07911001236094vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Instrument Sans,Arial;
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }

      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenmake3{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);
        border-bottom: dashed black 1px;
        position: absolute;
        top:12.5vh;
        z-index: 1;

      }
      .grid{
        z-index: 2;
        margin-top:0vh ;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap: 2vw;
        .card{
          //cursor: pointer;
          width: 100%;
          height:100% ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            //background: #c9d9f5;
            border-radius: 9px;
            position: relative;
            //border: white 1px solid;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:100%;
            overflow:hidden;
            .imgDesign{
              width:8vh;
              height:8vh;
              opacity: 1;
            }
            .titleDesign{
              padding: 0;
              margin:2vh auto 0 auto;
              color: black;
              width: 100%;
              float: right;
              .title1{
                margin: 0 auto;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                margin: 2vh auto 0 auto;
                width: fit-content;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:6.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        font-weight: 600;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: left;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 80vw;
        font-size: 1.15vw;
        font-family: Instrument Sans,Arial;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: #eeeeef;
            border-radius: 9px;
            display: flex;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height:65vh ;
            overflow:hidden;
            .imgDesign{
              width: fit-content;
              height:100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5vw 0 1.5vw;
              margin:auto 1vw auto auto;
              color: black;
              width:40%;
              float: right;
              .title1{
                margin-top: 2vh;
                font-size: 1vw;
                font-weight: bold;
              }
              .title2{
                line-height: 2.8vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
      .grid1{
        margin-top:2.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: white;
            border-radius: 9px;
            padding: 1.5vh 0;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content;
            overflow:hidden;
            .imgDesign{
              width: fit-content;
              height:10vh;
              opacity: 1;
              margin:2vh 1vw 3vh 1.5vw;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .title1{
              margin-top: 4vh;
              font-size: 1.1709416522vw;
              font-weight: bold;
            }
            .titleDesign{
              padding: 0 1.5vw 2.5248454883vh 1vw;
              margin:0 auto;
              color: black;
              width:90%;
              float: right;
              .title1{
                margin-top: 3vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                margin-top: 1vh;
                line-height: 2.8vh;
                font-size: 0.9vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }


    .screenmake4{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #212a3c;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 1.7vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color:white;
        line-height: 4.23733003708282vh;

      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 86%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:65vh ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: fit-content;
              height:100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw 1.5248454883vh 2vw;
              margin:auto 0 auto 0;
              color: white;
              width: 40%;
              float: right;
              .title1{
                font-size: 1vw;
              }
              .title2{
                margin-top: 3vh;
                line-height: 4vh;
                font-size: 0.8243212016vw;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:68vh !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
  }
  .modal-overlay {
    z-index: 999999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  }

  .large-image {
    max-width: 100%;
    max-height: 80vh;
  }

}

.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .screen1 {
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 650px;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      background-image: url("../../assets/bannerModel.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 80px;
        left: 9.17157712305026vw;

        .title1 {
          width:120px;
          font-size: 3.50606585788562vw;
          font-family: Instrument Sans,Arial;
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin-top: 10px;
          width: 95%;
          height: fit-content;
          font-size: 14px;
          font-family: Instrument Sans,Arial;
          font-weight: 400;
          color: #4B5A8B;
          line-height: 20px;
        }



      }
      .title4{
        position: absolute;
        bottom: 100px;
        left: 50%;
        transform: translateX(-50%);
        height: fit-content;
        font-weight: 400;
        width: 100%;
        font-size: 16px;
        color: #2f6dfd;
        line-height: 20px;
        text-align: center; /* 用于内部文本的水平居中 */
      }
      .titlle3{
        position: absolute;
        bottom:30px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
         font-family: Instrument Sans,Arial;
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }
      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenmake3{
      width: 100vw;
      height: fit-content;
      margin-top: -25px;
      padding:0  4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            margin: 0 0 0 15px;
          }
          .back{
            background: #ebf1fd;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 0  20px 0;
            height:fit-content;
            overflow:hidden;
            .imgDesign{
              width:100%;
              height:155px;
              margin:0 auto 20px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Instrument Sans,Arial;
        font-weight: bold;
        color: #2C3E50;
        line-height: 25px;
      }

      .grid{
        position: relative;
        height: fit-content;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            margin-top: 20px;
          }
          .title3{
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0,1fr);
            line-height: 22px;
            font-size: 14px;
          }
          .back{
            margin-top: 15px;
            background: white;
            border-radius: 9px;
            position: relative;
            //height:580px ;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              height:100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
      .grid1{
        position: relative;
        height: fit-content;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;
            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
          }
          .back{
            background: white;
            padding: 10px 10px;
            border-radius: 9px;
            position: relative;
            height:fit-content;
            overflow:hidden;
            .imgDesign{
              width: 80px;
              margin: 0 10px 0 auto;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake4{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding:20px 4.6875vw;
      background: black;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
          //cursor: pointer;
          width: 98%;
          margin:0 auto;
          min-height: 250px ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 340px ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:214px;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 15px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:15px;
                font-size: 16px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:14px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 400px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -88px -10px  ;
          cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          ////z-index::: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
