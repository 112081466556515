<template>
  <div>
    <div v-if="model===false">   <div class="totalmakeHole" ref="total">
      <div class="topback"   :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}">
        <headtip :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
      </div>

      <div class="makeHole1" @click="changeheadVisable">
          <div class="background">
             <kefu @toptop="topTop"></kefu>
          <!--     文字-->
          <div class="titlle1">Equipment And Facility Monitoring Solutions </div>
          <div class="title4">*The solution is the field of Oil & Gas development technology services and AI model fusion, through the field of the solution to provide services to clients, for further understanding of the solution cooperation, please feel free to contact us.</div>
          <div class="titlle3" @click="dialogVisible=true">
            Solution Consulting
          </div>


        </div>
        <div class="screenmake0">
          <div class="heighLight">Oilfield Asset Intelligence Services Overview</div>
          <div class="heightLight2">Providing Oil & Gas enterprises with technologically independent and diversified equipment intelligent transformation solutions as well as intelligent oilfield management solutions, driving the comprehensive digital transformation of Oil & Gas asset management.
          </div>
          <div class="heightLight3">Oilfield Asset Intelligent Service</div>
          <div class="line"></div>
          <div class="line0"></div>
          <div class="grid0">
            <div class="card">
              <div class="heightLight3">Equipment Intelligent Monitoring</div>
            </div>
            <div class="card">
              <div class="line"></div>
              <div class="heightLight3">Production Intelligent Monitoring</div>
            </div>
            <div class="card">
              <div class="heightLight3">Inspection Management Software Services</div>
            </div>

          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="Title2">
                    <li>Self-developed intelligent software platform, digital management of detection + monitoring, integration of offline + online technical service model, to achieve an integrated asset intelligent management system;</li>
                    <li>Integration of global digital intelligent technology, strategic cooperation with Baidu, Jingdong, China University of Petroleum, Kunlun Digital Intelligence, etc., to provide Oil & Gas full-scene intelligent inspection services;</li>
                    <li>Utilizing over 20 years of experience in on-site inspection of equipment and facilities. Deeply understand the difficulties and pain points of customer asset operation, and provide targeted intelligent services.</li>
                  </div>
                </div>
                <div class="imgDesign">
                  <div>
                    <img src="@/assets/equup1.png">
                  </div>
                  <div>
                    <img src="@/assets/equup2.png">
                  </div>
                </div>

              </div>


            </div>

          </div>

        </div>
        <div class="screenmake21" style="padding:0 11.0976314269vw  7.7873918418vh">
          <div class="heighLight" style="font-size: 1.7vw;font-weight: unset">  Business Pain Points and Challenges</div>
          <div class="line"></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/managementicon.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">Pain Points in Equipment Management
                  </div>
                  <div class="title2">
                    Dispersed systems lead to complex management and low efficiency. Traditional maintenance methods are backward and cannot predict equipment failures, increasing downtime and maintenance costs. Lack of comprehensive equipment health monitoring makes it difficult to prevent problems in a timely manner.
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/monitor.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">Pain Points in Production Monitoring
                  </div>
                  <div class="title2">
                    Lack of real-time production data leads to delayed decision-making. Insufficient data analysis capabilities make it impossible to predict production trends and production efficiency. Inadequate emergency response increases risks.
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/managementicon.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">Pain Points in Asset Management
                  </div>
                  <div class="title2">
                    Incomplete asset management cannot achieve maximum value. Insufficient environmental protection and safety management. Low degree of digitization cannot support intelligent decision-making and affects operational efficiency and compliance.
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/All.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">Pain Points in Comprehensive Management
                  </div>
                  <div class="title2">
                    Information silos and lack of a unified platform to integrate various functions. Lack of intelligent and digital support affects the digital transformation of enterprises. Unable to comprehensively improve management efficiency and operational optimization.
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="screenmake1">
          <div class="heighLight">
            <span style="margin-top:0.5vh;background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">1</span>
            <span>&nbsp;&nbsp;Intelligent Solution of Equipment and Facilities</span>
          </div>
          <div class="Light"> The Equipment Health Management System is a novel integrated platform that unifies the management of hardware, software, data, parameters, and models for plant equipment. It facilitates closed-loop management of advanced intelligent maintenance models like TPM, TBM, CBM, and PHM.
          </div>
            <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
              </div>


            </div>

          </div>

        </div>
        <div class="screenmake2">
          <div class="heighLight">
            <span style="margin-top:0.5vh;background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">2</span>
            <span>&nbsp;&nbsp; Intelligent Production Monitoring</span>
          </div>
          <div class="Light">Utilizing open IoT technology, we provide real-time production monitoring, Oil & Gas production and management reports, production trend forecasts, rate analysis, anomaly detection, etc.
          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
              </div>


            </div>

          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight"> <span style="margin-top:0.5vh;background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">3</span>
            <span>&nbsp;&nbsp;Intelligent Inspection Management Software</span></div>
          <div class="Light">Dedicated to the empowerment of digital technology, around the entire life cycle of assets to build a smart, environmentally friendly, safe digital application ecosystem, to maximize the value of assets, to help customers asset digital intelligent management.
          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
              </div>


            </div>

          </div>

        </div>
        <div class="screenmake4">
          <div class="heighLight">Successful Cases</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in success" :key="item">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img :src="item.img"/>
                    </div>
                    <div class="titleDesign">
                      <div class="title1" v-html="item.title1"></div>
                      <div class="title2" v-html="item.title3">  </div>


                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <bottomTip></bottomTip>
      </div>
      <partnerTip
          :dialogVisible="dialogconsult"
          @hideDialog="hide"
      ></partnerTip>
      <resultTip
          :dialogVisible="dialogVisible"
          @hideDialog="hideDialog"
      ></resultTip>
    </div></div>
    <div v-else>  <div class="totalmakeHoleModel">
      <div class="makeHole1">
        <div class="background">
          <kefuMobile @topMobile="topTopmobile"></kefuMobile>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->
          <headtip @showDialog="showPartner"></headtip>
          <!--     文字-->
          <div class="titlle1">Equipment And Facility Monitoring Solutions</div>
          <div class="title4">*The solution is the field of Oil & Gas development technology services and AI model fusion, through the field of the solution to provide services to clients, for further understanding of the solution cooperation, please feel free to contact us.</div>
          <div class="titlle3" @click="dialogVisible=true">
            Solution Consulting
          </div>


        </div>
        <div class="screenmake0">
          <div class="heighLight">Oilfield Asset Intelligence Services Overview</div>
          <div class="heightLight2">Provide Oil & Gas enterprises with technologically independent and controllable equipment intelligent transformation solutions and oilfield intelligent management solutions with rich product lines, and provide reliable Oil & Gas production, storage, transportation, refining and chemical intelligent consulting and services based on profound Oil & Gas industry experience, so as to promote the comprehensive digital transformation of Oil & Gas asset management.</div>

          <div class="heightLight3">Oilfield Asset Intelligent Service</div>
          <div class="line"></div>
          <div class="line0"></div>
          <div class="grid0">
            <div class="card">
              <div class="heightLight3">Equipment Intelligent Monitoring</div>
            </div>
            <div class="card">
              <div class="line"></div>
              <div class="heightLight3">Production Intelligent Monitoring</div>
            </div>
            <div class="card">
              <div class="heightLight3">Inspection Management Software Services</div>
            </div>

          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <div class="img">
                    <img src="@/assets/equup1.png">
                  </div>
                  <div class="img">
                    <img src="@/assets/equup2.png">
                  </div>
                </div>
                <div class="titleDesign">
                  <div class="Title2">
                    <li>Self-developed intelligent software platform, digital management of detection + monitoring, integration of offline + online technical service model, to achieve an integrated asset intelligent management system;</li>
                    <li>Integration of global digital intelligent technology, strategic cooperation with Baidu, Jingdong, China University of Petroleum, Kunlun Digital Intelligence, etc., to provide Oil & Gas full-scene intelligent inspection services;</li>
                    <li>Utilizing over 20 years of experience in on-site inspection of equipment and facilities. Deeply understand the difficulties and pain points of customer asset operation, and provide targeted intelligent services.</li>
                  </div>
                </div>


              </div>


            </div>

          </div>

        </div>
        <div class="screenmake21" >
          <div class="heighLight">  Business Pain Points and Challenges</div>
          <div class="line"></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/managementicon.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">Pain Points in Equipment Management
                  </div>
                  <div class="title2">
                    Dispersed systems lead to complex management and low efficiency. Traditional maintenance methods are backward and cannot predict equipment failures, increasing downtime and maintenance costs. Lack of comprehensive equipment health monitoring makes it difficult to prevent problems in a timely manner.
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/monitor.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">Pain Points in Production Monitoring
                  </div>
                  <div class="title2">
                    Lack of real-time production data leads to delayed decision-making. Insufficient data analysis capabilities make it impossible to predict production trends and production efficiency. Inadequate emergency response increases risks.
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/managementicon.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">Pain Points in Asset Management
                  </div>
                  <div class="title2">
                    Incomplete asset management cannot achieve maximum value. Insufficient environmental protection and safety management. Low degree of digitization cannot support intelligent decision-making and affects operational efficiency and compliance.
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/All.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">Pain Points in Comprehensive Management
                  </div>
                  <div class="title2">
                    Information silos and lack of a unified platform to integrate various functions. Lack of intelligent and digital support affects the digital transformation of enterprises. Unable to comprehensively improve management efficiency and operational optimization.
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="screenmake1">
          <div class="heighLight">
            <span>①&nbsp;Intelligent Solution of Equipment and Facilities</span>
          </div>
          <div class="Light">Equipment health management system is a new type of integrated platform for plant and equipment engineering, which manages the integrated management of equipment hardware, running software, data, parameters and models on equipment, etc. Engineers don't have to shuttle back and forth between various systems, and based on the industrial Internet technology and equipment classification strategy, it realizes TPM (Total Personnel Repair), TBM (Periodic Repair), CBM (Conditional Repair), and PHM (Predictive Repair) and so on. Closed-loop management with advanced models of intelligent operation and maintenance.</div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
              </div>


            </div>

          </div>

        </div>
        <div class="screenmake2">
          <div class="heighLight">
            <span>②&nbsp;Intelligent Production Monitoring</span>
          </div>
          <div class="Light">Provide real-time production monitoring services for the whole process from wellhead, field station to pipeline based on open IoT technology; generate professional reports on Oil & Gas production and management, realize production trend prediction, production hourly rate analysis, and provide operation optimization services for integrated production management, decision-making and emergency event handling; based on the data from the data pedestal, build the analytical and predictive capability for various abnormal events in the production process of intelligent analysis service</div>

          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
              </div>


            </div>

          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight">     <span>③&nbsp;&nbsp;Intelligent Inspection Management Software</span></div>
          <div class="Light">          <div class="Light">Dedicated to the empowerment of digital technology, around the entire life cycle of assets to build a smart, environmentally friendly, safe digital application ecosystem, to maximize the value of assets, to help customers asset digital intelligent management</div>
          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
              </div>


            </div>

          </div>

        </div>
        <div class="screenmake4">
          <div class="heighLight">Successful Cases</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in success" :key="item">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img :src="item.img"/>
                    </div>
                    <div class="titleDesign">
                      <div class="title1">{{item.title1}}</div>
                      <div class="title2" v-html="item.title3">  </div>


                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <div ref="bottom">
          <bottomTip></bottomTip>
        </div>
      </div>
      <partnerTip
          :dialogVisible="dialogconsult"
          @hideDialog="hide"
      ></partnerTip>
      <resultTip
          :dialogVisible="dialogVisible"
          @hideDialog="hideDialog"
      ></resultTip>
    </div></div>
  </div>


</template>


<script>

import headtip from "@/views/headTip.vue";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import bottomTip from "@/views/bottomTip.vue";
import resultTip from "@/components/ResultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {PartnerTip,resultTip, headtip,bottomTip,kefu,kefuMobile},

  data() {

    return {
        changeVisable:1,
      pageTitle: 'Equipment and facility monitoring solutions_GPT application project services for Oil & Gas development operations-Oil & Gas Communication GPT',
      pageDescription: 'Provide Oil & Gas enterprises with technologically independent and controllable equipment intelligent transformation solutions and oilfield intelligent management solutions with rich product lines, and provide reliable Oil & Gas production, storage, transportation, refining and chemical intelligent consulting and services based on profound Oil & Gas industry experience, so as to promote the comprehensive digital transformation of Oil & Gas asset management.',
      pageKeywords: 'Equipment and facility monitoring, oilfield asset intelligent services, equipment intelligent monitoring, production intelligent monitoring, inspection management software services, Oil & Gas development operation GPT, Oil & Gas communication GPT',
      scrollback:false,
      model:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/success11.png"),
        title1:"Helping an oil production plant in Changqing Oilfield to realize the health management of ground engineering equipment and facilities",
        title3:"Oilfield ground engineering is an important subsystem in the big system of Oil & Gas field development and production, including Oil & Gas gathering and transportation, sewage treatment, preparation and injection system, etc., which consists of core equipments such as centrifugal pumps, plunger pumps, heating furnace, etc. The level of health management of core equipments directly affects the development technology and economic efficiency of Oil & Gas fields. The level of health management of core equipment directly affects the level of Oil & Gas field development technology and economic benefits.",
      },{
        img:require("@/assets/success12.png"),
        title1: "Helping Tianjin Petrochemical Equipment Health Management",
        title3:"<div ><li>Reciprocating compressor valve temperature collection wireless sensor replacement;</li><li>Realize the unified management of equipment multi-source parameter acquisition;</li><li>Realization of equipment, measurement points, data independent configuration configuration;</li><li>Realize the application of multi-dimensional mapping analysis tools.</li></div>",

      },

        {
          img:require("@/assets/success14.png"),
          title1: "Production Monitoring And Operation Optimization Of An Oilfield",
          title3:"Based on the collected real-time data and warning rules, it realizes real-time warning for oil production, gas production, water injection, gathering and transmission processes as well as environmentally sensitive areas. With the help of intelligent cameras, it realizes early warning of personnel intrusion and dangerous movement recognition. Based on the intelligent early warning model constructed by big data, it realizes the analysis of gas and working conditions, diagnosis of pumping machine's function diagram, and trend warning of key parameters of equipment.",

        },
        {
          img:require("@/assets/success15.png"),
          title1: "Production Monitoring And Operation Optimization Of An Oilfield",
          title3:"It collects real-time operation data of Oil & Gas well equipment, compressors, injection pumps and other key equipment from RTU, PLC, SCADA and other systems, realizes online monitoring and alarm management of equipment, builds digital LiSheng of equipment, realizes three-dimensional monitoring, failure analysis, trend early warning, and health status assessment and manages equipment files, maintenance, inspection, spare parts, and fault library, etc., so as to realize the full-life-cycle of equipment. Operation and maintenance management.",

        },
        {
          img:require("@/assets/success16.png"),
          title1: "Western Drilling Tools Asset Management System",
          title3:"Western Drilling Tubing and Well Control Company has realized the whole life cycle management from the purchase of drilling tools to the end of drilling tools, including the following: After the first inspection of drilling tools, the tools are entered into the system, and the relevant information of drilling tools is saved in the system (including the steel number, manufacturer, joint length size, joint OD size, sealing surface size, full-length size, wall-thickness size, ultrasonic flaw results, magnetic particle flaw results, electromagnetic flaw results, and threads, etc.). Judgment repair, etc.).",

        },

        {
          img:require("@/assets/success17.png"),
          title1: "\n" +
              "A natural gas pipeline integrity management system",
          title3:"Tongao Zhilian has completed the construction of the pipeline integrity system based on the actual production needs of the customer, realizing basic pipeline information query, full life cycle management and inspection management of equipment in the station, cathode protection management and key river management, high-consequence area management, risk assessment, SCADA system, UAV system docking and three-dimensional station construction. Finally, the comprehensive construction of the pipeline integrity management system was realized.",

        },
      ]
    }

  },

  methods: {
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
       window.scrollTo({
         top: 0,
         behavior: 'smooth'
       });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
 skipp3() {
  const element = this.$refs.bottom;
  const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

  this.$nextTick(() => {
    this.$refs.totalModel.scrollTop = rect
  });
},
 handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);

    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Equipment and facility monitoring solutions_GPT application project services for Oil & Gas development operations-Oil & Gas Communication GPT',
        pageDescription: 'Provide Oil & Gas enterprises with technologically independent and controllable equipment intelligent transformation solutions and oilfield intelligent management solutions with rich product lines, and provide reliable Oil & Gas production, storage, transportation, refining and chemical intelligent consulting and services based on profound Oil & Gas industry experience, so as to promote the comprehensive digital transformation of Oil & Gas asset management.',
        pageKeywords: 'Equipment and facility monitoring, oilfield asset intelligent services, equipment intelligent monitoring, production intelligent monitoring, inspection management software services, Oil & Gas development operation GPT, Oil & Gas communication GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      padding: 7.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/equ1.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 2vw;
         font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 6.82076637824475vh auto 0 auto;
        width: 62vw;
        font-size: 1vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 8.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screenmake0{
      padding: 5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:6.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto 3vh auto;
        text-align: center;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height:3.5vh;

        width: 66.9693818602vw;
        font-size: 1vw;
        font-family: Instrument Sans,Arial;
      }
      .heightLight3{
        margin: 0 auto 0 auto;
        // cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: Instrument Sans,Arial;
        font-weight: 500;
        color: #2f6eff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 2.1989601386vw;
        height:7.5624227441vh;
        background: #B3D0FF;
        border: #B3D0FF 1px solid;
      }
      .line{
        height: 3.831891223733vh;
        width: 4px;
        left: 50%;
        margin:0 auto;
        background-color: #B3D0FF;
      }
      .line0{
        width:48%;
        border-top-right-radius: 63px;
        border-top-left-radius: 63px;
        border-top:  #B3D0FF 4px solid;
        border-left:  #B3D0FF 4px solid;
        border-right:  #B3D0FF 4px solid;;
        height: 4.831891223733vh;
        margin:0 auto ;
      }
      .grid0{
        display: grid;
        width: 70%;
        margin:0 auto 3vh auto;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        //column-gap: 1vw;
        .card{
          position: relative;
          margin: 0 auto;
          text-align: center;
          .line{
            position: absolute;
            height:5.831891223733vh;
            width: 4px;
            left: 50%;
            top:-5.831891223733vh;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto 3vh auto;
          }

          .heightLight3{
            margin:0 auto 3vh auto;

            // cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1vw;
            font-family: Instrument Sans,Arial;
            font-weight: 500;
            color: #2f6eff;
            line-height: 3.3374536465vh;
            text-align: center;
            border-radius: 53px;
            width: fit-content;
            padding: 2.4624227441vh 2.1989601386vw;
            height:8.5624227441vh;
            background: transparent;
            border: #B3D0FF 4px solid;
          }
        }


      }
      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            display: flex;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: #EDF3FF ;
            border: white 1px solid;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              height:100%;
              width: 32vw;
              opacity: 1;
             display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title2{
                display: flex;
                flex-direction: column;
                justify-content: center; /* Center vertically */
                align-items: flex-start;
                margin: 8vh 6vw;
                text-align: justify;
                width: 32.23743500866551vw;
                font-size: 0.8243212016vw;
                height: fit-content;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 4.7194066749vh;
              }
              li{
                margin-top: 3vh;
              }
            }
          }
        }


      }
    }
    .screenmake21 {
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);

        border-bottom: dashed black 1px;
        position: absolute;
        top:11vh;
        z-index: 1;

      }

      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 2vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              height: 5vh;
              margin:0 auto 2vh auto;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 auto;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1{
      padding: 5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:6.23733003708282vh;
        font-size: 1.7vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto 3vh auto;
        text-align: center;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height:3.5vh;

        width: 66.9693818602vw;
        font-size: 1vw;
        font-family: Instrument Sans,Arial;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          width: 100%;
          .back{
            border-radius: 9px ;;
            display: flex;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:70.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF ;
            .imgDesign{
              width: 100%;
              height:100%;
              opacity: 1;
              background-image: url("../../assets/equ2.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title2{
                display: flex;
                flex-direction: column;
                justify-content: start; /* Center vertically */
                align-items: flex-start;
                margin: 5vh 5vw;
                text-align: justify;
                width: 16.23743500866551vw;
                font-size: 0.8243212016vw;
                height: fit-content;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 4.7194066749vh;
              }
              li{
                margin-top: 4.7194066749vh;
              }
            }
          }
        }


      }
    }
    .screenmake2{
      padding: 5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:6.23733003708282vh;
        font-size: 1.7vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto 3vh auto;
        text-align: center;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height:3.5vh;

        width: 66.9693818602vw;
        font-size: 1vw;
        font-family: Instrument Sans,Arial;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          width: 100%;
          .back{
            border-radius: 9px ;;
            display: flex;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:54.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF ;
            .imgDesign{
              width: 100%;
              height:100%;
              opacity: 1;
              background-image: url("../../assets/equ3.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title2{
                display: flex;
                flex-direction: column;
                justify-content: start; /* Center vertically */
                align-items: flex-start;
                margin: 5vh 5vw;
                text-align: justify;
                width: 16.23743500866551vw;
                font-size: 0.8243212016vw;
                height: fit-content;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 4.7194066749vh;
              }
              li{
                margin-top: 4.7194066749vh;
              }
            }
          }
        }


      }
    }
    .screenmake3{
      padding: 5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:6.23733003708282vh;
        font-size: 1.7vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto 3vh auto;
        text-align: center;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height:3.5vh;

        width: 66.9693818602vw;
        font-size: 1vw;
        font-family: Instrument Sans,Arial;
      }
      .grid{
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          width: 100%;
          .back{
            border-radius: 9px ;;
            display: flex;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:70.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF ;
            .imgDesign{
              width: 100%;
              height:100%;
              opacity: 1;
              background-image: url("../../assets/equ4.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title2{
                display: flex;
                flex-direction: column;
                justify-content: start; /* Center vertically */
                align-items: flex-start;
                margin: 5vh 5vw;
                text-align: justify;
                width: 16.23743500866551vw;
                font-size: 0.8243212016vw;
                height: fit-content;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 4.7194066749vh;
              }
              li{
                margin-top: 4.7194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake4{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;
      //background-color: #ebf1fd;
      .heighLight{
        color:white;
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 1.7vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        .card{
          //// cursor: pointer;
          margin:0 auto 3vh auto;

          width: 86%;
          padding:0.98887515451174vh 0 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:65vh;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width:fit-content;

              height:100%;
              opacity: 1;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              color: white;
              text-align: center;
              width: 30vw;
              margin:auto auto auto auto;
              float: right;
              .title1{
                text-align: left;
                font-size: 1vw;
              }
              .title2{
                text-align: left;
                margin-top:10px;
                line-height: 3vh;
                font-size: 0.95vw;
              }
              .success{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                //column-gap: 1vw;
                margin-top: 2vh;
                .suc1{
                  width: 100%;
                  height: 8vh;
                  .title3{
                    text-align: center;
                    margin-top: 2vh;
                    font-size: 0.8243212016vw;
                  }

                }

              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:68vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 550px;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          // cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
    //.screenmake4{
    //  padding:6.8430160692vh  10.9763142692085vw ;
    //  width: 100%;
    //  height: 100.82941903584672vh;
    //  background: #ffffff;
    //  .Light{
    //    text-align: center;
    //    margin-top:2.4388133498vh ;
    //    height: 7.54017305315204vh;
    //    font-size: 1.5597920277vw;
    //    font-family: Instrument Sans,Arial;
    //    font-weight: 400;
    //    //color: #2168DB;
    //    line-height:2.8430160692vh;
    //  }
    //  .heighLight{
    //    text-align: center;
    //    height: 7.54017305315204vh;
    //    font-size: 2vw;
    //    font-family: Instrument Sans,Arial;
    //    font-weight: 400;
    //    //color: #2168DB;
    //    line-height: 2.71940667490729vh;
    //  }
    //  .grid{
    //    margin-top:2.78739184177998vh ;
    //    display: grid;
    //    grid-template-columns: minmax(0,1fr);
    //    column-gap:0 ;
    //    .card{
    //      // cursor: pointer;
    //      width: fit-content;
    //      height:fit-content ;
    //      padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
    //      opacity: 1;
    //      width: 100%;
    //      .back{
    //        border-radius: 9px ;;
    //        display: flex;
    //        position: relative;
    //        box-shadow: 5px 0 14px 0px #D4E4FF;
    //        height:55.87144622991347vh ;
    //        overflow:hidden;
    //        background: #FFFFFF ;
    //        .imgDesign{
    //          width: 48.60311958405546vw;
    //          height:100%;
    //          opacity: 1;
    //          background-image: url("../../assets/equ5.jpg");
    //          background-position: center;
    //          background-size:cover;
    //          background-repeat: no-repeat;
    //          background-color: transparent;
    //        }
    //        .titleDesign{
    //          .Title2{
    //            display: flex;
    //            flex-direction: column;
    //            justify-content: start;/* Center vertically */
    //            align-items: flex-start;
    //            margin: 10vh 5vw;
    //            text-align: justify;
    //            width: 20.23743500866551vw;
    //            font-size: 0.8243212016vw;
    //            height: fit-content;
    //            font-family: Instrument Sans,Arial;
    //            font-weight: 400;
    //            line-height: 4.7194066749vh;
    //          }
    //          li{
    //            margin-top: 4.7194066749vh;
    //          }
    //        }
    //      }
    //    }
    //    .card:hover{
    //      .imgDesign {
    //        transform: scale(1.05);
    //      }
    //
    //    }
    //
    //
    //  }
    //}
  }

}
.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 460px;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      background-image: url("../../assets/gptModel61.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin:50px auto;
        width: fit-content;
        text-align: center;
        height: 23px;
        font-size: 23px;
        font-family: Instrument Sans,Arial;
        font-weight: 500;
        color: #111111;
        line-height: 32px;
      }
      .titlle3{
        position: absolute;
        bottom:75px;
        left:50%;
        transform: translateX(-60%);
        font-size:12px;
         font-family: Instrument Sans,Arial;
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }
      .title4{
        position: absolute;
        left:10%;
        bottom:-85px;
        margin: 35px auto 0 auto;
        width: 80%;
        height: fit-content;
        font-size: 12px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #111111;
        line-height: 18px;
        border-radius: 15px;
        padding: 15px;
        background:white;  border: #e8eefa 1px solid;
      }
    }

    .screenmake0{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:90px 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 20px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .heightLight3{
        margin: 0 auto 0 auto;
        // cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:14px;
        font-family: Instrument Sans,Arial;
        font-weight: 500;
        color: #2f6eff;
        line-height: 22px;
        text-align: center;
        border-radius: 9px;
        width: fit-content;
        padding: 10px 15px;
        height:40px;
        background: #B3D0FF;
        border: #B3D0FF 1px solid;
      }
      .line{
        height: 15px;
        width: 4px;
        left: 50%;
        margin:0 auto 0 auto;
        background-color: #B3D0FF;
      }
      .line0{
        width: 245px;
        border-top-right-radius: 63px;
        border-top-left-radius: 63px;
        border-top:  #B3D0FF 4px solid;
        border-left:  #B3D0FF 4px solid;
        border-right:  #B3D0FF 4px solid;;
        height: 35px;
        margin:0 auto 0 auto;
      }
      .grid0{
        width: 350px;
        margin:0 auto 3vh auto;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 5px;

        .card{
          position: relative;
          margin: 0 auto;
          text-align: center;
          .line{
            position: absolute;
            height: 35px;
            width: 4px;
            left: 50%;
            top:-35px;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto 3vh auto;
          }
          .line1{
            position: absolute;
            height: 75px;
            width: 4px;
            left: 50%;
            top:-75px;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto 3vh auto;
          }
          .heightLight3{
            margin:0 auto 3vh auto;

            // cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 8px;
            font-family: Instrument Sans,Arial;
            font-weight: 500;
            color: #2f6eff;
            line-height: 16px;
            text-align: center;
            border-radius: 9px;
            width: fit-content;
            padding: 5px 5px;
            height:45px;
            background: transparent;
            border: #B3D0FF 4px solid;
          }
        }


      }
      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: #EDF3FF ;
            .imgDesign{
              display: flex;
              opacity: 1;
              .img{
                width: 50%;
              }
            }
            .titleDesign{
              .Title2{
                display: flex;
                flex-direction: column;
                justify-content: center; /* Center vertically */
                align-items: flex-start;
                margin:5px 15px;
                text-align: justify;
                font-size:12px;
                height: fit-content;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 22px;
              }
              li{
                margin-top: 5px;
              }
            }
          }
        }


      }
    }
    .screenmake21{
      width: 100vw;
      height: fit-content;

      padding:20px 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1{
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:   #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }

      .Light{
        margin:19px 2vw 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:15px;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:15px;
          opacity: 1;
          width: 100%;
          .back{
            border-radius: 9px ;;
            display: flex;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: #FFFFFF ;
            .imgDesign{
              width: 100%;
              height:158px;
              opacity: 1;
              background-image: url("../../assets/equ2.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title2{
                display: flex;
                flex-direction: column;
                justify-content: start; /* Center vertically */
                align-items: flex-start;
                margin: 5vh 5vw;
                text-align: justify;
                width: 16.23743500866551vw;
                font-size: 0.8243212016vw;
                height: fit-content;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 4.7194066749vh;
              }
              li{
                margin-top: 4.7194066749vh;
              }
            }
          }
        }


      }
    }
    .screenmake2{
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:  white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }

      .Light{
        margin:19px 2vw 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:15px;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:15px;
          opacity: 1;
          width: 100%;
          .back{
            border-radius: 9px ;;
            display: flex;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background:  #ebf1fd;
            .imgDesign{
              width: 100%;
              height:158px;
              opacity: 1;
              background-image: url("../../assets/equ3.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title2{
                display: flex;
                flex-direction: column;
                justify-content: start; /* Center vertically */
                align-items: flex-start;
                margin: 5vh 5vw;
                text-align: justify;
                width: 16.23743500866551vw;
                font-size: 0.8243212016vw;
                height: fit-content;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 4.7194066749vh;
              }
              li{
                margin-top: 4.7194066749vh;
              }
            }
          }
        }


      }
    }
    .screenmake3{
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:   #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }

      .Light{
        margin:19px 2vw 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:15px;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:15px;
          opacity: 1;
          width: 100%;
          .back{
            border-radius: 9px ;;
            display: flex;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: #FFFFFF ;
            .imgDesign{
              width: 100%;
              height:158px;
              opacity: 1;
              background-image: url("../../assets/equ4.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title2{
                display: flex;
                flex-direction: column;
                justify-content: start; /* Center vertically */
                align-items: flex-start;
                margin: 5vh 5vw;
                text-align: justify;
                width: 16.23743500866551vw;
                font-size: 0.8243212016vw;
                height: fit-content;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 4.7194066749vh;
              }
              li{
                margin-top: 4.7194066749vh;
              }
            }
          }
        }
        .card:hover{
          .imgDesign {
            transform: scale(1.05);
          }

        }

      }
    }
    .screenmake4{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: black;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 20px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
          cursor: pointer;
          width: 98%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:585px;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:10px;
                font-size:12px;
                line-height: 20px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:12px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:585px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -170px -10px  ;
          cursor: pointer;
          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size:12px;
        }
      }
    }
    //.screenmake4{
    //  padding:6.8430160692vh  10.9763142692085vw ;
    //  width: 100%;
    //  height: 100.82941903584672vh;
    //  background: #ffffff;
    //  .Light{
    //    text-align: center;
    //    margin-top:2.4388133498vh ;
    //    height: 7.54017305315204vh;
    //    font-size: 1.5597920277vw;
    //    font-family: Instrument Sans,Arial;
    //    font-weight: 400;
    //    //color: #2168DB;
    //    line-height:2.8430160692vh;
    //  }
    //  .heighLight{
    //    text-align: center;
    //    height: 7.54017305315204vh;
    //    font-size: 2vw;
    //    font-family: Instrument Sans,Arial;
    //    font-weight: 400;
    //    //color: #2168DB;
    //    line-height: 2.71940667490729vh;
    //  }
    //  .grid{
    //    margin-top:2.78739184177998vh ;
    //    display: grid;
    //    grid-template-columns: minmax(0,1fr);
    //    column-gap:0 ;
    //    .card{
    //      // cursor: pointer;
    //      width: fit-content;
    //      height:fit-content ;
    //      padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
    //      opacity: 1;
    //      width: 100%;
    //      .back{
    //        border-radius: 9px ;;
    //        display: flex;
    //        position: relative;
    //        box-shadow: 5px 0 14px 0px #D4E4FF;
    //        height:55.87144622991347vh ;
    //        overflow:hidden;
    //        background: #FFFFFF ;
    //        .imgDesign{
    //          width: 48.60311958405546vw;
    //          height:100%;
    //          opacity: 1;
    //          background-image: url("../../assets/equ5.jpg");
    //          background-position: center;
    //          background-size:cover;
    //          background-repeat: no-repeat;
    //          background-color: transparent;
    //        }
    //        .titleDesign{
    //          .Title2{
    //            display: flex;
    //            flex-direction: column;
    //            justify-content: start;/* Center vertically */
    //            align-items: flex-start;
    //            margin: 10vh 5vw;
    //            text-align: justify;
    //            width: 20.23743500866551vw;
    //            font-size: 0.8243212016vw;
    //            height: fit-content;
    //            font-family: Instrument Sans,Arial;
    //            font-weight: 400;
    //            line-height: 4.7194066749vh;
    //          }
    //          li{
    //            margin-top: 4.7194066749vh;
    //          }
    //        }
    //      }
    //    }
    //    .card:hover{
    //      .imgDesign {
    //        transform: scale(1.05);
    //      }
    //
    //    }
    //
    //
    //  }
    //}
  }

}
</style>
