import { render, staticRenderFns } from "./business.vue?vue&type=template&id=78c000b9&scoped=true"
import script from "./business.vue?vue&type=script&lang=js"
export * from "./business.vue?vue&type=script&lang=js"
import style0 from "./business.vue?vue&type=style&index=0&id=78c000b9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_420b40f8fe8ddaf3248c7dea96ec9571/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78c000b9",
  null
  
)

export default component.exports