<template>
  <div>
    <div v-if="model===false">


      <div class="totalmakeHole" ref="total">
        <div class="topback"   :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}">
         <headtip :changeVisable="changeVisable"  @showDialog="showPartner"></headtip>
        </div>

        <div class="makeHole1" @click="changeheadVisable">
          <div class="background">
             <kefu @toptop="topTop"></kefu>
            <!--     文字-->
            <div class="titlle1">Drilling Parameter Optimization Platform</div>
            <div class="title4">*The generalization ability of the Oil & Gas AI model is limited, so it may need to be adapted and optimized according to specific scenes and needs in practical applications. </div>
            <div class="title2">①&nbsp;Process-related services are provided by Antonoil's offline technical personnel<br>②&nbsp;GPT development services are provided by Antonoil's AI professionals</div>
            <div class="titlle3" @click="clickConsult('Oil & Gas Generic AI')">
              Product Inquiry
            </div>
          </div>
          <div class="screenmake1">
            <div class="heighLight">Function Introduction</div>

            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img src="@/assets/drilling1.png">
                  </div>
                  <div class="titleDesign">
                    <div class="Title1" style="font-weight: bolder;">Optimization of Drilling Parameters </div>
                    <div class="Title2" style="">
                      In drilling operation, Intelligent algorithm accurately recommends appropriate adjustable drilling parameters such as drilling pressure, rotary speed and pumping pressure according to input well depth, basic attributes of drilling tool, physical and chemical properties of drilling fluid such as cylinder diameter, mud density, etc. This will provide a protection for safety of real-time drilling.


                    </div>

                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img src="@/assets/drilling2.png">
                  </div>
                  <div class="titleDesign">
                    <div class="Title1" style="font-weight: bolder;">Drilling Parameters Threshold Planning</div>
                    <div class="Title2">
                      The customized setting of drilling parameters is realized by using drilling design and drilling tool characteristic analysis. Threshold values for key metrics such as weight on bit and RPM can be manually adjusted to ensure that each drilling operation meets the precise requirements of specific geographical and geological conditions.
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake21" style="padding-top: 0">
            <div class="heighLight" style="font-size: 1.7vw"> Business Pain Points and Challenges</div>
            <div class="line"></div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/drilling01.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1"> Lacking Scientific and Systematic Data Analysis

                    </div>
                    <div class="title2">
                      The selection of drilling parameters mainly relies on engineers' personal experience, lacking scientific and systematic data analysis, which leads to inaccurate decisions and affects drilling efficiency and safety.
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/drilling02.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Low Level of Intelligence.
                    </div>
                    <div class="title2">
                      With changes in blocks and formations, traditional drilling parameters need to be re-explored and adjusted, which is not only time-consuming and laborious but also significantly affects drilling speed and timeliness, increasing operational costs.
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div class="screenmake0">
            <div class="heighLight">Function Experience</div>
            <div class="heighLight1">By accurately recommending appropriate drilling parameters, we can maximize drilling efficiency, reduce energy consumption and costs, achieve a highly efficient and stable drilling process. <span style="font-weight: bold;color: red;">You can use function below,free experience!</span> <br>If you have cooperation intention and product consultation, please<span style="color: #FF6400;cursor: pointer" @click="clickConsult('Oil & Gas Generic AI')"> contact us</span>. </div>
            <div class="grid">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane  name="first">
                  <div class="card">
                    <div class="back">
                      <div class="titleDesign">
                        <div style="display: flex">
                          <div class="Title1">
                            1. Data source:
                          </div>
                          <div class="Title2">
                            &nbsp;Drilling Data;
                            Logging Data;
                            Geological Parameters;
                            Real-Time drilling data collection
                          </div>
                        </div>
                        <div>
                          <div class="Title1">
                            2.    Operation Procedures:<span class="Title2" > Fill in the well depth, cylinder diameter and other data,Click 【Confirm】 to output recommended application parameters in the right column of page.
                 </span>
                          </div>
                          <div class="Title2" style="display: flex">
                          </div>
                        </div>
                        <div style="display: flex">
                          <div class="Title1">
                            *&nbsp;  Attention:
                          </div>
                          <div class="Title2">
                            &nbsp;According to specified thresholds in project to set parameter ranges.
                          </div>
                        </div>
                      </div>
                      <div ref="iframeContainer" class="iframeStyle">
                        <iframe :src="iframeUrl1" frameborder="0" allowfullscreen></iframe> </div>
                    </div>


                  </div>
                </el-tab-pane>

              </el-tabs>


            </div>

          </div>

          <bottomTip></bottomTip>
        </div>
      <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalModel" ref="totalModel">
        <div class="screen1">
         <headtip :changeVisable="changeVisable"  @showDialog="showPartner"></headtip>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->

          <kefuMobile @topMobile="topTopmobile"></kefuMobile>
          <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
          <div class="layer2">
            <div class="title1">Drilling Parameter Optimization Platform</div>
            <div class="title2">*The generalization ability of the Oil & Gas AI model is limited, so it may need to be adapted and optimized according to specific scenes and needs in practical applications.</div>
            <div class="title3">①&nbsp;Process-related services are provided by Antonoil's offline technical personnel<br>②&nbsp;GPT development services are provided by Antonoil's AI professionals</div>
          </div>

          <div class="titlle3" @click="clickConsult('油气通')">
            Product Inquiry
          </div>
          <!-- <div class="title4">*油气人工智能模型的泛化能力有限，实际应用中可能需根据具体场景和需求进行适配和优化。如您有合作意向或产品咨询，请随时与我们联系。</div> -->
          <!--      平台大图-->
          <div class="layer3"></div>
        </div>
        <div class="screenmake2">
          <div class="heighLight">   <span>Function Introduction</span></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="imgDesign">  <img src="@/assets/frackIcon1.png"></div>
                  <div>
                    <div class="Title1">Optimization of Drilling Parameters</div>
                    <div class="Title2">
                      In drilling operation, Intelligent algorithm accurately recommends appropriate adjustable drilling parameters such as drilling pressure, rotary speed and pumping pressure according to input well depth, basic attributes of drilling tool, physical and chemical properties of drilling fluid such as cylinder diameter, mud density, etc. This will provide a protection for safety of real-time drilling.

                    </div>
                  </div>

                </div>
                <div class="titleDesign" style="margin-top: 10px">
                  <div class="imgDesign">  <img src="@/assets/frackIcon2.png"></div>
                  <div>
                    <div class="Title1">Drilling Parameters Threshold Planning</div>
                    <div class="Title2">
                      The customized setting of drilling parameters is realized by using drilling design and drilling tool characteristic analysis. Threshold values for key metrics such as weight on bit and RPM can be manually adjusted to ensure that each drilling operation meets the precise requirements of specific geographical and geological conditions.
                    </div>
                  </div>

                </div>

              </div>


            </div>




          </div>

        </div>
        <div class="screenmake21" style="padding-top: 15px">
          <div class="heighLight" style="height: fit-content"> Business Pain Points and Challenges</div>

          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/drilling03.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">Lacking Scientific and Systematic Data Analysis

                  </div>
                  <div class="title2">
                    The selection of drilling parameters mainly relies on engineers' personal experience, lacking scientific and systematic data analysis, which leads to inaccurate decisions and affects drilling efficiency and safety.

                  </div>
                </div>
              </div>


            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/drilling04.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">Low Level of Intelligence.
                  </div>
                  <div class="title2">
                    With changes in blocks and formations, traditional drilling parameters need to be re-explored and adjusted, which is not only time-consuming and laborious but also significantly affects drilling speed and timeliness, increasing operational costs.
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="screenmake2" style="background: #D2E3FF !important;">
          <div class="heighLight">   <span>Function Experience</span></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="titleDesign" style="margin-top: 0">
                  <div class="Title2" style="margin-left: 0;line-height: 168%;">
                    Drilling Parameter Optimization Platform covers <span style="color:#FF6400;">parameter display, abnormal data detection</span>.You can go to the computer to experience the free functions!<br>Experience link (PC version): <br><span style="border-bottom: #333333 1px solid">https://www.oilgasgpts.com/drilling</span><br>
                    If you have cooperation intention and product consultation, please <span style="color:red" @click="clickConsult('Oil & Gas Generic AI')">contact us</span>！
                  </div>

                </div>
              </div>


            </div>




          </div>

        </div>
        <div ref="bottom">
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>

</template>
<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
// import consultTip from "@/components/consultTip.vue";
// import PartnerTip from "@/components/partnerTip.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import kefu from  "@/views/kefu.vue";
export default {

  name: "",

  props: [],

  // components: {PartnerTip, consultTip, headtip,bottomTip},
  components: {PartnerTip, consultTip, headtip,bottomTip,kefu,kefuMobile},

  data() {

    return {
      changeVisable:1,
      model:false,
      title:"",
      dialogconsult:false,   scrollback:false,
      dialogVisible:false,
      activeName: 'first',
      pageTitle: 'Drilling Parameter Optimization Platform',
      pageDescription: 'In order to carry out better exploration and development, improve drilling efficiency, reduce drilling cost and reduce drilling risk, one of the most effective methods is to increase the mechanical drilling speed. Optimization of mechanical drilling speed is one of the urgent',
      pageKeywords: 'Drilling Parameter Optimization Platform, process GPTs, intelligent geosteering, intelligent injection and production decision-making, Oil & Gas field video monitoring, drilling risk warning, artificial lift optimization, mechanical production intelligent management and control, Oil & Gas field energy consumption optimization, Oil & Gas communication, Oil & Gas communication GPT, Oil & Gas communication GPT cloud Platform, Oil & Gas channel large model, Oil & Gas channel GPT large model',

      userId:localStorage.getItem("userId"),
      iframeUrl1:"https://ab.oilgasgpts.com/frackEn/zjcshyx",
      // iframeUrl2:"http://localhost:8891/zjcshyx?tag=params-config",
      // iframeUrl1:"http://54.223.240.188:8686/jyylcshdycjcshfy?type=correlation",
      // iframeUrl2:"http://54.223.240.188:8686/jyylcshdycjcshfy?type=eur",
      success:[{
        img:require("@/assets/success11.png"),
        title1:"助力长庆油田某采油厂实现地面工程设备设施健康管理",
        title3:"油田地面工程是油气田开发生产这个大系统中的]一个重要子系统，包括油气集输、污水处理、配制注入系统等，由离心泵、柱塞泵、加热炉等核心设备构成。核心设备健康管理水平的高低，!直接影响着油气田开发技术的高低和经济效益。",
      },{
        title1: "助力天津石化设备健康管理",
        title3:"<li>往复式压缩机气阀温度采集无线传感器替代</li><li>实现设备多源参数采集统管理</li><li>实现设备、测点、数据自主配置组态</li><li>实现多维图谱分析工具应用</li>",

      },
      ]
    }

  },

   methods: {
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      const element = this.$refs.totalModel;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
 const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
     handleClick(tab, event) {
       console.log(tab, event);
     },

    clickConsult(item){
      // console.log("联系联系")
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
     changeheadVisable(){
       if(this.changeVisable===1){
         this.changeVisable=0
       }
       else{
         this.changeVisable=1
       }
       console.log("父组件", this.changeVisable)
     },
     handleScroll() {
         this.scrollHeight = this.$refs.total.scrollTop;


       if (this.scrollHeight < 100) {
         this.scrollTrue = true
       } else {
         this.scrollTrue = false
       }
       if (this.scrollHeight < 50) {
         this.scrollback = false
       } else {
         this.scrollback = true
       }
       console.log("gaodu", this.scrollHeight < 100)
     },
   },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);


    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
  },
  watch:{
    "$store.state.userId"(newValue) {
      this.userId = newValue;

    },
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    document.title=this.pageTitle;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Drilling Parameter Optimization Platform',
        pageDescription: 'In order to carry out better exploration and development, improve drilling efficiency, reduce drilling cost and reduce drilling risk, one of the most effective methods is to increase the mechanical drilling speed. Optimization of mechanical drilling speed is one of the urgent',
        pageKeywords: 'Drilling Parameter Optimization Platform, process GPTs, intelligent geosteering, intelligent injection and production decision-making, Oil & Gas field video monitoring, drilling risk warning, artificial lift optimization, mechanical production intelligent management and control, Oil & Gas field energy consumption optimization, Oil & Gas communication, Oil & Gas communication GPT, Oil & Gas communication GPT cloud Platform, Oil & Gas channel large model, Oil & Gas channel GPT large model',

      };
      shareUrl(shareData);
    }, 1000);

  },
}

</script>

<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .background{
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      width: 100%;
      height: 50vh;
      background-image: url("../../assets/third4.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 8.09270704573548vh;
        text-align: center;
        font-size: 2vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 6.82076637824475vh auto 0 auto;
        width: 62vw;
        font-size: 1vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .title2 {
        text-align: left;
        margin: 3.82076637824475vh auto 0 auto;
        width: fit-content;
        font-size: 0.9vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 6.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;
        margin-top: 9px;
      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screenmake0{
      padding:6.8430160692vh  9vw 2.8430160692vh 9vw;
      width: 100%;
      height: fit-content;
      background: transparent;

      .heighLight{
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 1.7vw;
        font-family: Instrument Sans,Arial;
        font-weight: bold;
        //color: #2168DB;
        line-height: 4vh;
      }
      .heighLight1{
        text-align: left;
        margin: 0 auto;
        width: 85vw;
        height:fit-content;
        font-size: 1.1vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        //color: #2168DB;
        line-height: 3.5vh;
      }
      ::v-deep .el-tabs__nav{
       text-align: center;
        float: none;
      }
      ::v-deep .el-tabs__item{
        width:50%;
        font-size:  1.2vw !important;
        padding:2vh  5vh;
        height: fit-content;
      }
      ::v-deep .el-tabs__active-bar{
        background-color: transparent;
      }
      ::v-deep .el-tabs__header{

        margin:0 0 0 0;
      }

      ::v-deep .el-tabs__item.is-active{
        color: #316fff;
      }

      .grid{
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content;
          //padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            //display: flex;
            position: relative;
            //background-color: #ececec;
            padding: 0 0 0 0;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height:85vh ;
            overflow:hidden;
            .iframeStyle{
              background-color: #ececec;
              margin: -9vh 0 0 -10vw;
              width:100vw;
              transform: scale(0.82);
              //border: #0a0a0a 5px solid;

              border-bottom-left-radius: 9px;
              border-bottom-right-radius: 9px;

              overflow: hidden;
            }
            .iframeStyle iframe {
              background-color: #ececec;
              width:100vw;
              min-height:90vh;
              height: fit-content;
              //top: 0;
              //left: 0;
              transform-origin: center center;
              overflow: hidden;
            }
            .imgDesign{

              width:fit-content;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 2vh 3vw;
              background-color: #ececec;
              border-top-left-radius: 0;
              border-top-right-radius: 9px;
              .Title1{

                color:#111111;
                text-align: justify;
                font-size: 0.8vw;
                height: fit-content;
                font-family: Instrument Sans,Arial;
                font-weight:bold;
                line-height: 1.2vw;
              }
              .Title2{
                color:#111111;
                text-align: justify;
                font-size: 0.8vw;
                height: fit-content;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 1.2vw;
              }
            }
          }
        }

      }
    }
    .screenmake21 {
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);

        border-bottom: dashed black 1px;
        position: absolute;
        top:11vh;
        z-index: 1;

      }

      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 2vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              height: 5vh;
              margin:0 auto 2vh auto;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 auto;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1{
      padding:6.8430160692vh  9vw ;
      width: 100%;
      height:  fit-content;
      background: white;

      .heighLight{
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 1.7vw;
        font-family: Instrument Sans,Arial;
        font-weight: bold;
        //color: #2168DB;
        line-height: 4vh;
      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap:0 ;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw ;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            display: flex;
            border: white 1px solid;
            background-color: #D4E4FF;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:38vh ;
            padding: 0 0 3vh 0;
            overflow:hidden;
            .imgDesign{
              height:38vh;
              width: 400vh;
              margin:0vh auto;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              margin:4vh 1vw ;
              .Title1{
                width: 42vh;
                margin: 1vh auto;
                display: flex;
                flex-direction: column;
                justify-content: center; /* Center vertically */
                align-items: center;
                color:#111111;
                text-align: center;
                font-size: 1vw;
                height: fit-content;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 2.7194066749vh;
              }
              .Title2{
                // display: flex;
                // flex-direction: column;
                // justify-content: center; /* Center vertically */
                // align-items: flex-start;
                // color:#111111;
                // text-align: justify;
                // font-size: 0.9vw;
                // height: fit-content;
                // font-family: Instrument Sans,Arial;
                // font-weight: 400;
                // line-height: 4vh;
                margin: 1vh auto;
                display: flex;
                flex-direction: column;
                justify-content: center; /* Center vertically */
                align-items: center;
                color:#111111;
                text-align: left;
                font-size: 0.9vw;
                height: fit-content;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 2.8vh;
              }
            }
          }
        }

      }
    }
  }

}
.totalModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .screen1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 450px;
    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
    background-image: url("../../assets/groundB.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */
    .titlle3{
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-60%);
      font-size: 14px;
      font-family: Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, sans-serif;
      font-weight: 400;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 53px;
      width: fit-content;
      margin: 0 15px;
      padding: 5px 4.6875vw;
      line-height: 15px;
      height: 35px;
      color: #fff;
      background-color: #2f6eff;
      border: 1px solid #2f6eff;
      overflow: hidden;

    }
    .title4{
      position: absolute;
      left:10%;
      bottom:25px;
      margin: 35px auto 0 auto;
      width: 80%;
      height: 18px;
      font-size: 10px;
      font-family: Instrument Sans,Arial;
      font-weight: 400;
      color: white;
      line-height: 14px;
    }
    .layer2 {
      position: absolute;
      left:0;
      .title1 {
        padding:0 15px;
        margin:25px auto;
        width: fit-content;
        text-align: center;
        height: fit-content ;
        font-size: 23px;
        font-family: Instrument Sans,Arial;
        font-weight: 500;
        color: #111111;
        line-height:26PX;
      }

      .title2 {
        margin: 25px auto 0 auto;
        width: 90%;
        text-align: center;
        height: fit-content;
        font-size: 12px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #111111;
        line-height: 22px;
      }
      .title3 {
        margin: 18px auto;
        width: 80%;
        text-align: left;
        height: 17px;
        font-size: 12px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #111111;
        line-height: 24px;
      }
    }

  }
  .screenmake0 {
    padding: 20px 4.6875vw;
    width: 100%;
    height: fit-content;
    background: white;

    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      font-family: Instrument Sans,Arial;
      font-weight: 700;
      line-height: 26px;

    }

    .heightLight2 {
      margin:19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: Instrument Sans,Arial;
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .grid {

      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 1vw;

      .card1 {
        //cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;

        .back {
          border-radius: 9px;
          position: relative;
          height: fit-content;
          overflow: hidden;
          margin-bottom: 1.5248454883vh;

          .imgDesign {
            border-radius: 9px;
            text-align: center;
            width: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign {
            padding: 1.5248454883vh 1.5248454883vw;
            //background-color: #2F6EFF;
            .Title1 {
              color: black;
              text-align: center;
              font-size: 1.1709416522vw;

              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 700;
            }

          }

          .backconsult{
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: Instrument Sans,Arial;
            font-weight: 400;
            color: #2168DB;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 50%;
            transform: translateX(-50%);

            color: #2168DB;
          }
          .backconsultArrow{
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: Instrument Sans,Arial;
            font-weight: 400;
            color: #2168DB;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 80%;
            transform: translateX(-50%);
          }

        }


      }

    }
  }
  .screenmake21{
    width: 100vw;
    height: fit-content;
    margin-top: -25px;
    padding:0  4.6875vw 20px 4.6875vw;
    background: white;
    .heighLight{
      text-align: center;
      height:21px;
      opacity: 1;
      font-size: 21px;
      //color: #53A3F7;
      font-family: Instrument Sans,Arial;
      font-weight: 700;
      line-height: 26px;
    }
    .heightLight2{
      margin:19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: Instrument Sans,Arial;
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .grid{
      position: relative;
      height: fit-content;
      display: grid;
      margin-top: 10px;
      grid-template-columns: minmax(0,1fr) ;
      column-gap: 5px;
      .card{
        cursor: pointer;
        width: 100%;
        height:fit-content ;
        display: flex;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
        opacity: 1;
        .title1{
          margin: 5px auto;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
        }
        .title2{
          margin-top: 5px;

          line-height: 22px;
          font-size: 14px;
        }
        .title3{
          margin-top: 5px;

          line-height: 22px;
          font-size: 14px;
        }
        .titleDesign{
          margin: 0 0 0 15px;
        }
        .back{
          background: #ebf1fd;
          border-radius: 9px;
          column-gap: 5px;
          position: relative;
          padding: 0 0  20px 0;
          height:fit-content;
          overflow:hidden;
          .imgDesign{
            width:20%;
            height:fit-content;
            margin:10px auto 0 auto;
            opacity: 1;
          }
        }
      }
      ::v-deep .el-carousel__container{
        height: 65.87268232385661vh !important;
      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }
      ::v-deep .el-carousel__container {
        position: relative;
        height: 48.83559950556242vh;
      }
      ::v-deep .el-carousel__arrow {
        display: none;
        border: none;
        outline: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 0;
        width: 1.15540150202195vw;
        height: 4.32632880098888vh;
        //background: #2168DB;
        color: #fff;
        position: absolute;
        top: 50%;
        //z-index:: 10;
        transform: translateY(-50%);
        text-align: center;
        font-size: 12px;
      }
    }
  }
  .screenmake2{
    padding: 20px 15px;
    width: 100%;
    height: fit-content;
    background:#FDFDFD;
    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      font-family: Instrument Sans,Arial;
      font-weight: 700;
      line-height: 26px;
    }
    .grid{
      margin-top:5px ;
      display: grid;
      grid-template-columns: minmax(0,1fr);
      column-gap:0 ;
      row-gap: 15px;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          // box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          // background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;border-radius:9px;
            overflow:hidden;
            width: 100%;
            height: 120px;
            opacity: 1;
            background-image: url("../../assets/drilling12.png");
            background-position: center;
            background-size:contain;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign{
            margin-top: 140px;
            display: flex;
            .imgDesign
            {
              border-radius:9px;   position:absolute;
              transition:transform 0.3s ease-out;
              overflow:hidden;
              width: 30px;
              margin-top: -5px;
              height: 30px;
              opacity: 1;
            }
            .Title1{
              display: flex;
              text-align: left;
              //height: 20px;
              margin-left:40px;
              font-size: 16px;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight:bold;
              color:black;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin:13px 15px 10px 13px;
              text-align: left;
              height: fit-content;
              font-size:14px;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
            }

            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  .screenmake3{
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;

    padding:20px 15px;
    background: black;
    .heighLight{
      text-align: center;
      height:21px;
      opacity: 1;
      font-size: 21px;
      color: white;
      font-family: Instrument Sans,Arial;
      font-weight: 700;
      line-height: 26px;
    }
    .heightLight2{
      margin:19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: Instrument Sans,Arial;
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }
    .grid{
      margin-top:2.78739184177998vh ;
      position: relative;

      .card{
        cursor: pointer;
        width: 98%;
        margin:0 auto;
        min-height: 250px ;
        padding:0 0.69324090121317vw 0 0;
        opacity: 1;
        background: #19202e;
        .back{
          border-radius: 9px;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          min-height: 360px ;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: 100%;
            height:200px;
            opacity: 1;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 15px 20px 25px 20px;
            color: white;
            width:100%;
            float: right;
            .title1{
              text-align: center;
              margin-bottom:10px;
              font-size: 16px;
            }
            .title2{
              height: 180px;
              // line-height: 20px;
              font-size:12px;
              ::v-deep li{
                margin-top:5px;
              }
            ;
            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height: 430px !important;

      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }

      ::v-deep .el-carousel__arrow {
        display: block;
        //border: 1px solid white;
        outline: -5px;
        padding: 0;
        margin: -68px -10px  ;
        cursor: pointer;

        border-radius: 0;
        width: 25px;
        height: 48px;
        background: rgba(245, 246, 250, 0.8);
        color: #131313;
        position: absolute;
        top: 50%;
        //z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
</style>
