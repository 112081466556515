<template>
  <div>
    <div v-if="model===false"> <div class="totalmakeHole" ref="total">
      <div class="topback"   :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}">
        <headtip :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
      </div>

      <div class="makeHole1" @click="changeheadVisable">
          <div class="background">
             <kefu @toptop="topTop"></kefu>
          <!--     文字-->
          <div class="titlle1">Intelligent Drilling Solutions </div>
          <div class="title4">*The solution is the field of Oil & Gas development technology services and AI model fusion, through the field of the solution to provide services to clients, for further understanding of the solution cooperation, please feel free to contact us.</div>
          <div class="titlle3" @click="dialogVisible=true">
            Solution Consulting
          </div>
        </div>
        <div class="screenmake0">
          <div class="heighLight">Intelligent Drilling Services Overview</div>
          <div class="heightLight2">
            Anton Drilling Industry Group is a leading drilling service provider, specializing in technically advanced, efficient, and cost-effective solutions, including horizontal drilling and integrated drilling/completion services.</div>
          <div class="heightLight3">Drilling Technology</div>
          <div class="line0"></div>
          <div class="grid0">
            <div class="card">
              <div class="heightLight3">Integrated Drilling And Completion Technology</div>
            </div>
            <div class="card">
              <div class="line"></div>
              <div class="heightLight3">Intelligent Drilling Technology</div>
            </div>
            <div class="card">
              <div class="line1"></div>
              <div class="heightLight3">Drilling And Completion Fluid Technology</div>
            </div>
            <div class="card">
              <div class="line"></div>
              <div class="heightLight3">Drilling Engineering Technology</div>
            </div>
            <div class="card">
              <div class="heightLight3">Cementing Technology</div>
            </div>




          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="Title1">Drilling And Completion Integration</div>
                  <div class="Title2">The first service company in China to undertake integrated general contracting, mainly providing services such as risk general contracting and engineering general contracting, with a cumulative total of more than 400 wells constructed.</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="Title1">Directional</div>
                  <div class="Title2">The first private oil service company in China to own rotary guidance, providing fully integrated services of directional wells and downhole tools, with a total of more than 1,200 wells constructed.</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="Title1">Mud</div>
                  <div class="Title2">Domestic onshore oil-based/biosynthetic-based pioneer, providing drilling and completion fluid technology services for various drilling environments, with a total of more than 1,500 wells constructed.</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="Title1">Well Cementing</div>
                  <div class="Title2">With special technologies such as elastic-plastic cement paste and rotary casing lowering, we provide cementing services and supporting technical services, and have constructed more than 400 wells in total.</div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="screenmake21" style="padding:0 11.0976314269vw 7.7873918418vh ">
          <div class="heighLight" style="font-size: 1.7vw;font-weight: unset">  Business Pain Points and Challenges</div>
          <div class="line"></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/prediction.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">Insufficient Geological Prediction
                  </div>
                  <div class="title2">
                    Traditional drilling relies on manual interpretation of geological data, resulting in limited accuracy of geological models and thereby increasing the uncertainty and risk in the drilling process.
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/risk.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">Insufficient Risk Management
                  </div>
                  <div class="title2">
                    The identification of engineering risk points and response measures often rely on experience, which makes risk assessment lack systematicness and forward-looking nature and makes it difficult to respond to emergencies in a timely manner.
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/optimize.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">Difficulty in Optimizing Drilling Parameters
                  </div>
                  <div class="title2">
                    Optimizing drilling parameters usually requires a large number of tests and adjustments, which not only takes time but also greatly increases the overall cost of the project.
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/Intelligence.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">Insufficient Intelligence in Engineering Measures
                  </div>
                  <div class="title2">
                    Traditional optimization of engineering measures relies on manual judgment, resulting in slow response speed and insufficient accuracy, which in turn affects the overall drilling efficiency and safety.
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="screenmake">
          <div class="heighLight">
            <span style="margin-top:0.5vh;background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">1</span>
            <span>&nbsp;&nbsp;Intelligent Drilling Design</span>
          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent Drilling Geology Design</div>
                  <div class="Title2">AI algorithm learns historical geological data and design information, and intelligently outputs more accurate geological design, providing better reference and risk warning for engineering design.</div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent Drilling Engineering Design</div>
                  <div class="Title2">Based on the learning of historical engineering data and design information, AI actively outputs more accurate engineering design to achieve drilling purposes safely, efficiently and quickly.</div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div class="screenmake2">
          <div class="heighLight">
            <span style="margin-top:0.5vh;background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">2</span>
            <span>&nbsp;&nbsp;Intelligent Drilling Geology</span>
          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent Geological Risk Analysis</div>
                  <div class="Title2">The neural network extracts potential features of faults, suppresses noise interference, and establishes a nonlinear mapping relationship from seismic data to target faults for rapid intervention.</div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent real-time geosteering</div>
                  <div class="Title2">AI automatically identifies feature data to judge the current layer position, which facilitates early intervention to arrange control measures, thus realizing precise geological guidance to improve the drilling encounter rate.
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight">
            <span style="margin-top:0.5vh;background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">3</span>
            <span>&nbsp;&nbsp;Intelligent Drilling Engineering</span>
          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent Risk Warning</div>
                  <div class="Title2">Big data deep learning identifies engineering risk points and formulates countermeasures. It also sets risk thresholds in multiple dimensions and intervenes in advance to control and avoid complex accidents.</div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent parameter real-time optimization</div>
                  <div class="Title2">Machine learning algorithm analyzes the relationship between data elements and drilling speed to obtain prediction, and outputs options and quantitative values for drilling parameter optimization according to the drilling speed prediction model.</div>
                </div>
              </div>


            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent Engineering Measures Optimization</div>
                  <div class="Title2">Intelligent analysis of wellbore fluid, friction and other data to determine the downhole situation, optimize the paddle eye, short start-up and downhole measures under the premise of ensuring safety, and greatly reduce the drilling cycle.</div>
                </div>
              </div>


            </div>
          </div>

        </div>
        <div class="screenmake4">
          <div class="heighLight">Successful Cases</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in success" :key="item">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img :src="item.img"/>
                    </div>
                    <div class="titleDesign">
                      <div class="title1">{{item.title1}}</div>
                      <div class="title1" style="margin-top: 25px">{{item.title3}}</div>
                      <div class="title2">{{item.title4}}</div>

                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <bottomTip></bottomTip>
      </div>
      <partnerTip
          :dialogVisible="dialogconsult"
          @hideDialog="hide"
      ></partnerTip>
      <resultTip
          :dialogVisible="dialogVisible"
          @hideDialog="hideDialog"
      ></resultTip>
    </div></div>
    <div v-else> <div class="totalmakeHoleModel">
      <div class="makeHole1">
        <div class="background">
          <kefuMobile @topMobile="topTopmobile"></kefuMobile>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->
          <headtip @showDialog="showPartner"></headtip>
          <!--     文字-->
          <div class="titlle1">Intelligent Drilling Solutions</div>
          <div class="title4">*The solution is the field of Oil & Gas development technology services and AI model fusion, through the field of the solution to provide services to clients, for further understanding of the solution cooperation, please feel free to contact us.</div>
          <div class="titlle3" @click="dialogVisible=true">
            Solution Consulting
          </div>
        </div>

        <div class="screenmake0">
          <div class="heighLight">Intelligent Drilling Services Overview</div>
          <div class="heightLight2">Anton Drilling Industry Group is a leading drilling service provider, specializing in technically advanced, efficient, and cost-effective solutions, including horizontal drilling and integrated drilling/completion services.
          </div>
          <div class="heightLight3">Drilling Technology</div>
          <div class="line0"></div>
          <div class="grid0">
            <div class="card">
              <div class="heightLight3">Integrated Drilling</div>
            </div>
            <div class="card">
              <div class="line"></div>
              <div class="heightLight3">Intelligent Drilling </div>
            </div>
            <div class="card">
              <div class="line1"></div>
              <div class="heightLight3">Drilling And Completion Fluid</div>
            </div>
            <div class="card">
              <div class="line"></div>
              <div class="heightLight3">Drilling Engineering</div>
            </div>
            <div class="card">
              <div class="heightLight3">Cementing Technology</div>
            </div>




          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="Title1">Drilling And Completion Integration</div>
                  <div class="Title2">The first service company in China to undertake integrated general contracting, mainly providing services such as risk general contracting and engineering general contracting, with a cumulative total of more than 400 wells constructed.</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="Title1">Directional</div>
                  <div class="Title2">The first private oil service company in China to own rotary guidance, providing fully integrated services of directional wells and downhole tools, with a total of more than 1,200 wells constructed.</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="Title1">Mud</div>
                  <div class="Title2">Domestic onshore oil-based/biosynthetic-based pioneer, providing drilling and completion fluid technology services for various drilling environments, with a total of more than 1,500 wells constructed.</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="Title1">Well Cementing</div>
                  <div class="Title2">With special technologies such as elastic-plastic cement paste and rotary casing lowering, we provide cementing services and supporting technical services, and have constructed more than 400 wells in total.</div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="screenmake21" >
          <div class="heighLight" >  Business Pain Points and Challenges</div>
          <div class="line"></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/prediction.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">Insufficient Geological Prediction
                  </div>
                  <div class="title2">
                    Traditional drilling relies on manual interpretation of geological data, resulting in limited accuracy of geological models and thereby increasing the uncertainty and risk in the drilling process.
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/risk.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">Insufficient Risk Management
                  </div>
                  <div class="title2">
                    The identification of engineering risk points and response measures often rely on experience, which makes risk assessment lack systematicness and forward-looking nature and makes it difficult to respond to emergencies in a timely manner.
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/optimize.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">Difficulty in Optimizing Drilling Parameters
                  </div>
                  <div class="title2">
                    Optimizing drilling parameters usually requires a large number of tests and adjustments, which not only takes time but also greatly increases the overall cost of the project.
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/Intelligence.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">Insufficient Intelligence in Engineering Measures
                  </div>
                  <div class="title2">
                    Traditional optimization of engineering measures relies on manual judgment, resulting in slow response speed and insufficient accuracy, which in turn affects the overall drilling efficiency and safety.
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="screenmake">
          <div class="heighLight">
            <span>①&nbsp;Intelligent Drilling Design</span>
          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent Drilling Geology Design</div>
                  <div class="Title2">AI algorithm learns historical geological data and design information, and intelligently outputs more accurate geological design, providing better reference and risk warning for engineering design.</div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent Drilling Engineering Design</div>
                  <div class="Title2">Based on the learning of historical engineering data and design information, AI actively outputs more accurate engineering design to achieve drilling purposes safely, efficiently and quickly.</div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div class="screenmake2">
          <div class="heighLight">
            <span>②&nbsp;Intelligent Drilling Geology</span>
          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent Geological Risk Analysis</div>
                  <div class="Title2">The neural network extracts potential features of faults, suppresses noise interference, and establishes a nonlinear mapping relationship from seismic data to target faults for rapid intervention.</div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent real-time geosteering</div>
                  <div class="Title2">AI automatically identifies feature data to judge the current layer position, which facilitates early intervention to arrange control measures, thus realizing precise geological guidance to improve the drilling encounter rate.</div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight">

            <span>③&nbsp;Intelligent Drilling Engineering</span>
          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent Risk Warning</div>
                  <div class="Title2">Big data deep learning identifies engineering risk points and formulates countermeasures. It also sets risk thresholds in multiple dimensions and intervenes in advance to control and avoid complex accidents.</div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent parameter real-time optimization</div>
                  <div class="Title2">Machine learning algorithm analyzes the relationship between data elements and drilling speed to obtain prediction, and outputs options and quantitative values for drilling parameter optimization according to the drilling speed prediction model.</div>
                </div>
              </div>


            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent Engineering Measures Optimization</div>
                  <div class="Title2">Intelligent analysis of wellbore fluid, friction and other data to determine the downhole situation, optimize the paddle eye, short start-up and downhole measures under the premise of ensuring safety, and greatly reduce the drilling cycle.</div>
                </div>
              </div>


            </div>
          </div>

        </div>
        <div class="screenmake4">
          <div class="heighLight">Successful Cases</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in success" :key="item">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img :src="item.img"/>
                    </div>
                    <div class="titleDesign">
                      <div class="title1">{{item.title1}}</div>
                      <div class="title1">{{item.title3}}</div>
                      <div class="title2">{{item.title4}}</div>

                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <div ref="bottom">
          <bottomTip></bottomTip>
        </div>
      </div>
      <partnerTip
          :dialogVisible="dialogconsult"
          @hideDialog="hide"
      ></partnerTip>
      <resultTip
          :dialogVisible="dialogVisible"
          @hideDialog="hideDialog"
      ></resultTip>
    </div></div>
  </div>


</template>


<script>

import headtip from "@/views/headTip.vue";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import bottomTip from "@/views/bottomTip.vue";
import resultTip from "@/components/ResultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {PartnerTip, resultTip, headtip,bottomTip,kefu,kefuMobile},

  data() {

    return {
        changeVisable:1,
      pageTitle: 'Intelligent drilling solutions_Oil & Gas development operations GPT application project services-Oil & Gas Communication GPT',
      pageDescription: 'Anton Drilling Industry Group is a leading provider of superior drilling and low-cost drilling technology services, covering the entire drilling process, with a series of key drilling technologies, featuring horizontal well drilling, integrated drilling and completion turnkey service model.',
      pageKeywords: 'Intelligent drilling, intelligent drilling design, intelligent drilling geology design, intelligent drilling engineering design, intelligent drilling geology, intelligent geological risk analysis, intelligent real-time geosteering, intelligent drilling engineering, intelligent risk warning, intelligent parameter real-time optimization, intelligent engineering measure optimization, Oil & Gas Development operation GPT, Oil & Gas communication GPT',
      scrollback:false,
      model:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/success2.png"),
        title1:"Geosteering GPT",
        title3:"Landing section stratigraphic classification",
        title4:"AI machine learning data classification model based on 6-element logging data; used in drilling landing section stratigraphic classification judgment scenarios.fine delineate the reservoir spreading;"

      },{
        img:require("@/assets/22.png"),
        title1:"Geosteering GPT",
        title3:"Horizontal section drilling status judgment",
        title4:"The horizontal section of the drilling well gives real-time information on bit penetration, formation-based tangent angle conversion and formation judgment." ,

      },
      ]
    }

  },

  methods: {
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
       window.scrollTo({
         top: 0,
         behavior: 'smooth'
       });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    },

    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
  handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 300) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);


    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Intelligent drilling solutions_Oil & Gas development operations GPT application project services-Oil & Gas Communication GPT',
        pageDescription: 'Anton Drilling Industry Group is a leading provider of superior drilling and low-cost drilling technology services, covering the entire drilling process, with a series of key drilling technologies, featuring horizontal well drilling, integrated drilling and completion turnkey service model.',
        pageKeywords: 'Intelligent drilling, intelligent drilling design, intelligent drilling geology design, intelligent drilling engineering design, intelligent drilling geology, intelligent geological risk analysis, intelligent real-time geosteering, intelligent drilling engineering, intelligent risk warning, intelligent parameter real-time optimization, intelligent engineering measure optimization, Oil & Gas Development operation GPT, Oil & Gas communication GPT',


      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    ////z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      padding: 7.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/makehole1.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 2vw;
         font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 6.82076637824475vh auto 0 auto;
        width: 62vw;
        font-size: 1vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 8.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screenmake0{
      padding: 5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto 3vh auto;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto 3vh auto;
        text-align: center;
        height:fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }

      .heightLight3{
        margin: 0 auto 3.78739184177998vh auto;
        // cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: Instrument Sans,Arial;
        font-weight: 500;
        color: #2f6eff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 2.1989601386vw;
        height:7.5624227441vh;
        background: #B3D0FF;
        border: #B3D0FF 1px solid;
      }
      .line0{
        width: 52.7vw;
        border-top-right-radius: 63px;
        border-top-left-radius: 63px;
        border-top:  #B3D0FF 4px solid;
        border-left:  #B3D0FF 4px solid;
        border-right:  #B3D0FF 4px solid;;
        height: 3.831891223733vh;
        margin:0 auto;
      }
      .grid0{
        width: 65vw;
        margin:0 auto;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        //column-gap: 1vw;

        .card{
          position: relative;
          margin: 0 auto 3vh auto;
          text-align: center;
          .line{
            position: absolute;
            height: 3.831891223733vh;
            width: 4px;
            left: 50%;
            top:-3.831891223733vh;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .line1{
            position: absolute;
            height: 7.663782447466vh;
            width: 4px;
            left: 50%;
            top:-7.663782447466vh;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .heightLight3{
            margin:0 auto;

            // cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.8vw;
            font-family: Instrument Sans,Arial;
            font-weight: 500;
            color: #2f6eff;
            line-height: 2.5vh;
            text-align: center;
            border-radius: 53px;
            width: fit-content;
            padding: 2.4624227441vh 0.5vw;
            height:8.5624227441vh;
            background: transparent;
            border: #B3D0FF 4px solid;
          }
        }


      }
      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        //column-gap: 1vw;
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            border: 2px solid white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:35.72311495673671vh ;
            background: #2F6EFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            display: flex;

            .titleDesign{
              .Title1{
                text-align: center;
                margin:1.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                height: 2.7194066749vh;
                font-size: 1vw;
                 font-family: Instrument Sans,Arial;
                font-weight: 700;
                line-height: 2.1vh;
              }
              .Title2{
                margin:3.8124845488vh 2vw 1.1124845488vh 2vw;
                //text-align: justify;
                height: 4.6971569839vh;
                font-size:0.8243212016vw;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                //color:#2F6EFF;
                line-height: 3vh;
              }
            }
          }


        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background: #FFFFFF;
            margin-bottom: 1.5248454883vh;
            .imgDesign{
              text-align: center;
              height:54vh;
              width: 100%;
              background-image: url("../../assets/serve1.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              background-color: #2F6EFF;
              .Title1{
                color: white;
                text-align: center;
                font-size: 1vw;

                 font-family: Instrument Sans,Arial;
                font-weight: 700;
              }

            }
            .backconsult{
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left:5.55414091470952vw ;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }

        }

      }
    }
    .screenmake21 {
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);

        border-bottom: dashed black 1px;
        position: absolute;
        top:11vh;
        z-index: 1;

      }

      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 2vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              height: 5vh;
              margin:0 auto 2vh auto;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 auto;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake{
      padding: 5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        font-size: 1.7vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap:0 ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            border: 2px solid white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:30.72311495673671vh ;
            display: flex;
            background: #FFFFFF;
            .imgDesign{
              width: 55.69555170421722vw;
              height: 100%;
              opacity: 1;
              border-radius: 9px;
              background-image: url("../../assets/makehole2.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title1{
                margin:3.8124845488vh 1vw 1.1124845488vh 2.155vw;
                height: 2.7194066749vh;
                font-size: 1vw;
                 font-family: Instrument Sans,Arial;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin:2.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                //text-align: justify;
                height: 4.6971569839vh;
                font-size:0.8243212016vw;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                //color:#2F6EFF;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;

          opacity: 1;

          .back{
            border-radius: 9px ;;
            border: 2px solid white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:30.72311495673671vh ;
            display: flex;
            background: #FFFFFF;
            .imgDesign{
              border-radius: 9px;
              width: 55.69555170421722vw;
              height: 100%;
              opacity: 1;
              background-image: url("../../assets/makehole3.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title1{
                margin:3.8124845488vh 1vw 1.1124845488vh 2.155vw;
                height: 2.7194066749vh;
                font-size: 1vw;
                 font-family: Instrument Sans,Arial;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin:2.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                //text-align: justify;
                height: 4.6971569839vh;
                font-size:0.8243212016vw;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                //color:#2F6EFF;
                line-height: 3.194066749vh;
              }
            }
          }




        }


      }
    }
    .screenmake2{

      padding: 5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        font-size: 1.7vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap:0 ;
        .card{

          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            border:2px solid white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:30.72311495673671vh ;
            display: flex;
            background: #FFFFFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            .imgDesign{
              width: 55.69555170421722vw;
              height: 100%;
              border-radius: 9px;
              opacity: 1;
              background-image: url("../../assets/makehole4.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title1{
                margin:3.8124845488vh 1vw 1.1124845488vh 2.155vw;
                height: 2.7194066749vh;
                font-size: 1vw;
                 font-family: Instrument Sans,Arial;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin:2.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                //text-align: justify;
                height: 4.6971569839vh;
                font-size:0.8243212016vw;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                //color:#2F6EFF;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card1{

          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;

          opacity: 1;

          .back{
            border-radius: 9px ;;
            border:2px solid white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:30.72311495673671vh ;
            display: flex;
            background: #FFFFFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            .imgDesign{
              width: 55.69555170421722vw;
              height: 100%;
              border-radius: 9px;
              opacity: 1;
              background-image: url("../../assets/makehole5.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title1{
                margin:3.8124845488vh 1vw 1.1124845488vh 2.155vw;
                height: 2.7194066749vh;
                font-size: 1vw;
                font-family: Instrument Sans,Arial;
                font-weight: 700;
                line-height: 3.194066749vh;
              }
              .Title2{
                margin:2.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                //text-align: justify;
                height: 4.6971569839vh;
                font-size:0.8243212016vw;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                //color:#2F6EFF;
                line-height: 3.194066749vh;
              }
            }
          }




        }


      }
    }
    .screenmake3{
      padding: 5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        font-size: 1.7vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap:0.5vw;
        .card{
          // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border: white 1px solid;
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:55.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFf;
            .imgDesign{
              position:absolute;
              transition:transform 0.3s ease-out;
              overflow:hidden;
              width: 100%;
              height:  27.6996291718vh;
              opacity: 1;
              background-image: url("../../assets/makehole6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title1{
                position: absolute;
                top: 31vh;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1vw;
                 font-family: Instrument Sans,Arial;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{

                margin: 35.5vh 1.5597920277vw auto 1.5597920277vw;
                //text-align: justify;
                height: 4.6971569839vh;
                font-size:0.8243212016vw;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{border: white 1px solid;
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:55.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFf;
            .imgDesign{
              position:absolute;
              transition:transform 0.3s ease-out;
              overflow:hidden;
              width: 100%;
              height:  27.6996291718vh;
              opacity: 1;
              background-image: url("../../assets/makehole7.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              .Title1{
                margin: 31vh auto 0 auto;
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1vw;
                 font-family: Instrument Sans,Arial;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{

                margin:1.5vh 1.5597920277vw auto 1.5597920277vw;
                //text-align: justify;
                height: 4.6971569839vh;
                font-size:0.8243212016vw;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card2{
          // cursor: pointer;
           width: fit-content;
           height:fit-content ;
           padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
           opacity: 1;
          .back{border: white 1px solid;
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:55.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFf;
            .imgDesign{
              position:absolute;
              transition:transform 0.3s ease-out;
              overflow:hidden;
              width: 100%;
              height:  27.6996291718vh;
              opacity: 1;
              background-image: url("../../assets/makehole8.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title1{
               margin: 31vh auto 0 auto;
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1vw;
                 font-family: Instrument Sans,Arial;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{

                margin:1.5vh 1.5597920277vw auto 1.5597920277vw;
                //text-align: justify;
                height: 4.6971569839vh;
                font-size:0.8243212016vw;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
         }
      }
    }
    .screenmake4{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;
      //background-color: #ebf1fd;
      .heighLight{
        color:white;
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 1.7vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        .card{
          //// cursor: pointer;
          margin:0 auto;
          height:65vh;
          width: 86%;
          padding:0.98887515451174vh 0 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:64vh;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width:fit-content;
              height:100%;
              opacity: 1;

              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              color: white;
              width: 30vw;
              margin:auto 0 auto 0;
              float: right;
              .title1{
                font-size:1vw;
              }
              .title2{
                margin-top:25px;
                line-height: 4vh;
                font-size: 0.8243212016vw;
              }
              .success{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                //column-gap: 1vw;
                margin-top: 2vh;
                .suc1{
                  width: 100%;
                  height: 8vh;
                  .title3{
                    text-align: center;
                    margin-top: 2vh;
                    font-size: 0.8243212016vw;
                  }

                }

              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:68vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 550px;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          // cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
  }

}
.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 460px;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      background-image: url("../../assets/gptModel21.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      .titlle1 {
        margin:50px auto;
        width: fit-content;
        text-align: center;
        height: 23px;
        font-size: 23px;
        font-family: Instrument Sans,Arial;
        font-weight: 500;
        color: #111111;
        line-height: 32px;
      }
      .titlle3{
        position: absolute;
        bottom:75px;
        left:50%;
        transform: translateX(-60%);
        font-size:12px;
         font-family: Instrument Sans,Arial;
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }
      .title4{
        position: absolute;
        left:10%;
        bottom:-75px;
        margin: 35px auto 0 auto;
        width: 80%;
        height: fit-content;
        font-size: 12px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #111111;
        line-height: 18px;
        border-radius: 15px;
        padding: 15px;
        background:white;
        border: #e8eefa 1px solid;
      }
    }
    .screenmake21{
      width: 100vw;
      height: fit-content;

      padding:20px 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:90px 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
         height: fit-content;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 20px;
      }
      .heightLight2{
        margin:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .heightLight3{
        margin: 0 auto 25px auto;
        // cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:14px;
        font-family: Instrument Sans,Arial;
        font-weight: 500;
        color: #2f6eff;
        line-height: 22px;
        text-align: center;
        border-radius: 9px;
        width: fit-content;
        padding: 10px 15px;
        height:40px;
        background: #B3D0FF;
        border: #B3D0FF 1px solid;
      }
      .line0{
        width: 300px;
        border-top-right-radius: 63px;
        border-top-left-radius: 63px;
        border-top:  #B3D0FF 4px solid;
        border-left:  #B3D0FF 4px solid;
        border-right:  #B3D0FF 4px solid;;
        height: 35px;
        margin:0 auto;
      }
      .grid0{
        width: 350px;
        margin:0 auto;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 5px;

        .card{
          position: relative;
          margin: 0 auto 3vh auto;
          text-align: center;
          .line{
            position: absolute;
            height: 35px;
            width: 4px;
            left: 50%;
            top:-35px;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .line1{
            position: absolute;
            height: 75px;
            width: 4px;
            left: 50%;
            top:-75px;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .heightLight3{
            margin:0 auto;

            // cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 8px;
            font-family: Instrument Sans,Arial;
            font-weight: 500;
            color: #2f6eff;
            line-height: 16px;
            text-align: center;
            border-radius: 9px;
            width: fit-content;
            padding: 5px 5px;
            height:45px;
            background: transparent;
            border: #B3D0FF 4px solid;
          }
        }


      }
      .grid{
        margin-top:15px;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap:10px;
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:270px;
            background: #2F6EFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            display: flex;
            .titleDesign{
              padding: 0 1.5248454883vw;
              .Title1{
                margin-top: 15px;
                text-align: center;
                height: 32px;
                font-size:12px;
                 font-family: Instrument Sans,Arial;
                font-weight: 500;
                line-height:22px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                //text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 16px;
              }
            }

          }


        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background: #FFFFFF;
            margin-bottom: 1.5248454883vh;
            .imgDesign{
              text-align: center;
              height:54vh;
              width: 100%;
              background-image: url("../../assets/serve1.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              background-color: #2F6EFF;
              .Title1{
                color: white;
                text-align: center;
                font-size: 1vw;

                 font-family: Instrument Sans,Arial;
                font-weight: 500;
              }

            }
            .backconsult{
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left:5.55414091470952vw ;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }

        }

      }
    }
    .screenmake{

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:  #ebf1fd;;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
         height: fit-content;
        opacity: 1;
        font-size: 21px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }


      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        row-gap: 15px;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:180px;
            display: flex;
            background: #FFFFFF;
            .imgDesign{
              width: 300px;
              height: 100%;
              opacity: 1;
              background-image: url("../../assets/makehole2.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              width: 300px;
              padding: 0 1.5248454883vw;
              .Title1{
                margin: 10px auto auto 8px;
                text-align: left;
                height: 20px;
                font-size:12px;
                font-weight: 500;
                line-height:16px;
              }
              .Title2{
                margin: 18px 8px 20px 8px;
                //text-align: justify;
                height: fit-content;
                font-size:12px;
                font-weight: 400;
                line-height: 16px;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:180px;
            display: flex;
            background: #FFFFFF;
            .imgDesign{
              width: 300px;
              height: 100%;
              opacity: 1;
              background-image: url("../../assets/makehole3.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              width: 300px;
              padding: 0 1.5248454883vw;
              .Title1{
                margin: 10px auto auto 8px;
                text-align: left;
                height: 20px;
                font-size:12px;
                font-weight: 500;
                line-height:16px;
              }
              .Title2{
                margin: 18px 8px 20px 8px;
                //text-align: justify;
                height: fit-content;
                font-size:12px;
                font-weight: 400;
                line-height: 16px;
              }
            }
          }
        }


      }
    }
    .screenmake2{

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:  white;;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
         height: fit-content;
        opacity: 1;
        font-size: 21px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }


      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        row-gap: 15px;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:190px;
            display: flex;
            background: #ebf1fd;;

            .imgDesign{
              width: 300px;
              height: 100%;
              opacity: 1;
              background-image: url("../../assets/makehole5.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              width: 300px;
              padding: 0 1.5248454883vw;
              .Title1{
                margin: 10px auto auto 8px;
                text-align: left;
                height: 20px;
                font-size:12px;
                font-weight: 500;
                line-height:16px;
              }
              .Title2{
                margin: 18px 8px 20px 8px;
                //text-align: justify;
                height: fit-content;
                font-size:12px;
                font-weight: 400;
                line-height: 16px;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:200px;
            display: flex;
            background:  #ebf1fd;;

            .imgDesign{
              width: 300px;
              height: 100%;
              opacity: 1;
              background-image: url("../../assets/makehole6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              width: 300px;
              padding: 0 1.5248454883vw;
              .Title1{
                margin: 10px auto auto 8px;
                text-align: left;
                height: 20px;
                font-size:12px;
                font-weight: 500;
                line-height:16px;
              }
              .Title2{
                margin: 18px 8px 20px 8px;
                //text-align: justify;
                height: fit-content;
                font-size:12px;
                font-weight: 400;
                line-height: 16px;
              }
            }
          }
        }


      }
    }
    .screenmake3{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: #ebf1fd;;


      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
         height: fit-content;
        opacity: 1;
        font-size: 21px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        row-gap: 15px;
        .card{
          // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: white;;
            .imgDesign{
              text-align: center;
              height:230px;
              width: 100%;
              background-image: url("../../assets/makehole6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5248454883vw;
              .Title1{
                margin-top: 15px;
                text-align: center;
                height: fit-content;
                font-size:12px;
                 font-family: Instrument Sans,Arial;
                font-weight: 500;
                line-height:16px;
              }
              .Title2{
                margin: 12px 8px 20px 8px;
                //text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
        .card1{
          cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: white;;
            .imgDesign{
              text-align: center;
              height:230px;
              width: 100%;
              background-image: url("../../assets/makehole7.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5248454883vw;
              .Title1{
                margin-top: 15px;
                text-align: center;
                height: fit-content;
                font-size:12px;
                 font-family: Instrument Sans,Arial;
                font-weight: 500;
                line-height:16px;
              }
              .Title2{
                margin: 12px 8px 20px 8px;
                //text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
        .card2{
          cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: white;;
            .imgDesign{
              text-align: center;
              height:230px;
              width: 100%;
              background-image: url("../../assets/makehole8.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5248454883vw;
              .Title1{
                margin-top: 15px;
                text-align: center;
                height: fit-content;
                font-size:12px;
                 font-family: Instrument Sans,Arial;
                font-weight: 500;
                line-height:16px;
              }
              .Title2{
                margin: 12px 8px 20px 8px;
                //text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
    .screenmake4{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: black;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 20px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
          cursor: pointer;
          width: 98%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 400px !important;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:10px;
                font-size:14px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:12px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 400px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -100px -10px  ;
          cursor: pointer;
          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size:12px;
        }
      }
    }
  }

}
</style>
