<template>
  <div>
    <div v-if="model===false"><div class="totalmakeHole" ref="total">
      <div class="topback"   :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}">
        <headtip :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
      </div>
      <div class="makeHole1"  @click="changeheadVisable">
          <div class="background">
             <kefu @toptop="topTop"></kefu>
          <!--     文字-->
          <div class="titlle1">Intelligent Reservoir Geology Solutions</div>
          <div class="title4">*The solution is the field of Oil & Gas development technology services and AI model fusion, through the field of the solution to provide services to clients, for further understanding of the solution cooperation, please feel free to contact us.</div>
          <div class="titlle3" @click="dialogVisible=true">
            Solution Consulting
          </div>


        </div>
        <div class="screenmake0">
          <div class="heighLight">Overview of Intelligent Reservoir Geology Service</div>
          <div class="heightLight2">The Oil & Gas Research Institute specializes in single-well reservoir geology, integrating geology and engineering to offer comprehensive geological research, development plans, and risk assessments for Anton solutions.</div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/serve1.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">Structure Fine Explanation</div>
                </div>
              </div>
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/serve2.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">3D Geological Modeling</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/serve3.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">Sweet Spot Evaluation</div>
                </div>
              </div>
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/serve4.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">Brittleness Index</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/serve5.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">Natural Fracture Prediction</div>
                </div>
              </div>
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/serve6.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">Natural Fracture Prediction</div>
                </div>
              </div>
            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/serve7.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">Geomechanical Analysis</div>
                </div>
              </div>
            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/serve8.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">Address Risk Evaluation</div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="screenmake">
          <div class="heighLight">
            <span style="margin-top:-0.5vh;background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">1</span>
            <span>&nbsp;&nbsp;Intelligent Oil & Gas Recovery Explained</span>
          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent Seismic Phase Recognition</div>
                  <div class="Title2">Machine learning and deep learning to realize data preprocessing, feature extraction, MCC calculation, model training, and seismic phase classification.</div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Deep Learning Logging Petrographic Recognition</div>
                  <div class="Title2">Automatic identification and classification of logging data, realizing classification and identification of logging data such as waveform features, frequency domain features, time domain features, etc.</div>
                </div>

              </div>


            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">High-precision Intelligent Fault Recognition</div>
                  <div class="Title2">Construct a massive fault training sample library and learn to predict faults using three-dimensional high-precision convolutional network technology.</div>
                </div>
              </div>


            </div>
            <div class="card3">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">High-precision Intelligent Layer Tracking And Interpretation</div>
                  <div class="Title2">Using a small number of layer skeletons as a sample set and neural network technology, the efficiency and accuracy of automatic layer tracking is greatly improved.</div>
                </div>

              </div>


            </div>
            <div class="card4">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent Reservoir Prediction</div>
                  <div class="Title2">Reservoir prediction and evaluation through AI algorithms to track and predict the distribution, thickness and lithology of the reservoir.</div>
                </div>

              </div>


            </div>
          </div>

        </div>
        <div class="screenmake2">

          <div class="heighLight"><span style="margin-top:-0.5vh;background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">2</span>
            <span>&nbsp;&nbsp;Intelligent Logging Interpretation</span></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent Prediction Of Logging Physical<br> Parameters</div>
                  <div class="Title2">
                    A regression model based on machine learning theory recognizes the nonlinear mapping relationship between logging data and formation physical parameters, and accurately predicts porosity changes in reservoir sections.

                  </div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent Prediction Of Logging Petrographic Categories</div>
                  <div class="Title2">To establish fuzzy rules between core porosity and petrophysical logging, extract the optimal parameters of fuzzy clustering, and propose a new algorithm for matching data from multiple logging curves.</div>
                </div>
              </div>


            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent Delineation Of Log Stratigraphy</div>
                  <div class="Title2">Establish a target mapping function from logging curves to lithofacies to predict the lithofacies corresponding to uninterpreted logging data.</div>
                </div>
              </div>


            </div>
          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight">Successful Cases</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in 1" :key="item" >
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img src="@/assets/success1.png">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">Technical Services for the Project of Evaluation of High Quality Reservoirs and Selection of Development Targets of Shaximiao Formation in Southwest J Tight Oil & Gas Block</div>
                      <div class="title1" style="margin-top: 10px;font-weight: bold">Research content</div>
                      <div class="title2">
                        <li>Reservoir fine delineation: Based on the logging and logging interpretation, using petrophysical analysis and modeling, clarify the seismic response characteristics of the 8-sand Formation, and fine delineate the reservoir spreading;</li>
                        <li >Reservoir classification: Combining recording and logging results, implement the development of river tectonics and hydrocarbon faults in the 8-sand group of Qiulin block, and carry out classification evaluation;</li>
                        <li >Comprehensive evaluation of high-quality reservoirsComprehensive data from drilling, oil testing and test production were integrated to classify and comprehensively evaluate the high-quality reservoirs in the 8-sand group reservoirs.</li>
                      </div>
<!--                      <div class="title1" style="margin-top: 10px;font-weight: bold">Achievements</div>-->
<!--                      <div class="success" >-->
<!--                        <dv-border-box-10>-->
<!--                          <div class="suc1">-->
<!--                            <div class="title3">Evaluate Favorable Targets</div>-->
<!--                            <div class="title3" style="margin-top: 1vh"><span style="font-size: 0.9vw;margin-right: 0.4vw">4</span></div>-->
<!--                          </div>-->
<!--                        </dv-border-box-10>-->
<!--                        <dv-border-box-10>-->
<!--                          <div class="suc1">-->
<!--                            <div class="title3">Deployment Of Wells</div>-->
<!--                            <div class="title3" style="margin-top: 1vh"><span style="font-size: 0.9vw;margin-right: 0.4vw">5</span></div>-->
<!--                          </div>-->
<!--                        </dv-border-box-10>-->
<!--                      </div>-->
                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <bottomTip></bottomTip>
      </div>
      <partnerTip
          :dialogVisible="dialogconsult"
          @hideDialog="hide"
      ></partnerTip>
      <resultTip
          :dialogVisible="dialogVisible"
          @hideDialog="hideDialog"
      ></resultTip>
    </div></div>
    <div v-else><div class="totalmakeHoleModel">
      <div class="makeHole1">
        <div class="background">
          <kefuMobile @topMobile="topTopmobile"></kefuMobile>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->
          <headtip @showDialog="showPartner"></headtip>
          <!--     文字-->
          <div class="titlle1">Intelligent Reservoir Geology Solutions</div>
          <div class="title4">*The solution is the field of Oil & Gas development technology services and AI model fusion, through the field of the solution to provide services to clients, for further understanding of the solution cooperation, please feel free to contact us.</div>
          <div class="titlle3" @click="dialogVisible=true">
            Solution Consulting
          </div>


        </div>
        <div class="screenmake0">
          <div class="heighLight">Overview of Intelligent Reservoir Geology Service</div>
          <div class="heightLight2">The Oil & Gas Research Institute specializes in single-well reservoir geology, integrating geology and engineering to offer comprehensive geological research, development plans, and risk assessments for Anton solutions.</div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/serve1.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">Structure Fine Explanation</div>
                </div>
              </div>
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/serve2.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">3D Geological Modeling</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/serve3.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">Sweet Spot Evaluation</div>
                </div>
              </div>
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/serve4.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">Brittleness Index</div>
                </div>
              </div>
            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/serve5.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">Natural Fracture Prediction</div>
                </div>
              </div>
            </div>
            <div class="card1">

              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/serve6.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">Natural Fracture Prediction</div>
                </div>
              </div>
            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/serve7Model.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">Geomechanical Analysis</div>
                </div>
              </div>
            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/serve8Model.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">Address Risk Evaluation</div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="screenmake">
          <div class="heighLight">

            <span>①&nbsp;Intelligent Oil & Gas Recovery Explained</span>
          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent Seismic Phase Recognition</div>
                  <div class="Title2">Machine learning and deep learning to realize data preprocessing, feature extraction, MCC calculation, model training, and seismic phase classification.。</div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Deep Learning Logging Petrographic  Recognition</div>
                  <div class="Title2">Automatic identification and classification of logging data, realizing classification and identification of logging data such as waveform features, frequency domain features, time</div>
                </div>

              </div>


            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">High-precision Intelligent Fault Recognition</div>
                  <div class="Title2">Construct a massive fault training sample library and learn to predict faults using three-dimensional high-precision convolutional network technology.</div>
                </div>
              </div>


            </div>
            <div class="card3">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">High-precision Intelligent Layer Tracking And Interpretation</div>
                  <div class="Title2">Using a small number of layer skeletons as a sample set and neural network technology, the efficiency and accuracy of automatic layer tracking is greatly improved.</div>
                </div>

              </div>


            </div>
            <div class="card4">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1" style="display: flex;align-items: center">Intelligent Reservoir Prediction</div>
                  <div class="Title2">Reservoir prediction and evaluation through AI algorithms to track and predict the distribution, thickness and lithology of the reservoir.</div>
                </div>

              </div>


            </div>
          </div>

        </div>
        <div class="screenmake2">

          <div class="heighLight">

            <span>②&nbsp;Intelligent Logging Interpretation</span></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent Prediction Of Logging Physical Parameters</div>
                  <div class="Title2">
                    A regression model based on machine learning theory recognizes the nonlinear mapping relationship between logging data and formation physical parameters, and accurately predicts porosity changes in reservoir sections.
                  </div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent Prediction Of Logging Petrographic Categories</div>
                  <div class="Title2">To establish fuzzy rules between core porosity and petrophysical logging, extract the optimal parameters of fuzzy clustering, and propose a new algorithm for matching data from multiple logging curves.</div>
                </div>
              </div>


            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Intelligent Delineation Of Log Stratigraphy</div>
                  <div class="Title2">Establish a target mapping function from logging curves to lithofacies to predict the lithofacies corresponding to uninterpreted logging data.</div>
                </div>
              </div>


            </div>
          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight">Successful Cases</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in 1" :key="item" >
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img src="@/assets/success1.png">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">Technical Services for the Project of Evaluation of High Quality Reservoirs and Selection of Development Targets of Shaximiao Formation in Southwest J Tight Oil & Gas Block</div>
                      <div class="title1">Research content</div>
                      <div class="title2">
                        <li>Reservoir fine delineation: Based on the logging and logging interpretation, using petrophysical analysis and modeling, clarify the seismic response characteristics of the 8-sand Formation, and fine delineate the reservoir spreading;</li>
                        <li style="margin-top:5px">Reservoir classification: Combining recording and logging results, implement the development of river tectonics and hydrocarbon faults in the 8-sand group of Qiulin block, and carry out classification evaluation;</li>
                        <li style="margin-top:5px">Comprehensive evaluation of high-quality reservoirsComprehensive data from drilling, oil testing and test production were integrated to classify and comprehensively evaluate the high-quality reservoirs in the 8-sand group reservoirs.</li>
                      </div>

                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <div ref="bottom">
          <bottomTip></bottomTip>
        </div>
      </div>
      <partnerTip
          :dialogVisible="dialogconsult"
          @hideDialog="hide"
      ></partnerTip>
      <resultTip
          :dialogVisible="dialogVisible"
          @hideDialog="hideDialog"
      ></resultTip>
    </div></div>
  </div>


</template>


<script>

import headtip from "@/views/headTip.vue";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import bottomTip from "@/views/bottomTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import resultTip from "@/components/ResultTip.vue";
export default {

  name: "",

  props: [],

  components: {
    PartnerTip,  headtip, bottomTip,resultTip,kefu,kefuMobile
  },

  data() {

    return {
        changeVisable:1,

      pageTitle: 'Intelligent Reservoir Geology Solution_Oil & Gas Development Operation GPT Application Project Services-Oil & Gas Communication GPT',
      pageDescription: 'Oil & Gas Field Development Research Institute (OGFDRI) focuses on single-well reservoir geologic research for the overall situation, and provides comprehensive geologic research and evaluation and development plan preparation services for clients by combining geologic engineering, and at the same time, provides geologic risk evaluation services for the overall solution of Anton.',
      pageKeywords: 'Intelligent reservoir geology, intelligent Oil & Gas production interpretation, intelligent seismic facies identification, deep learning logging lithofacies identification, high-precision intelligent fault identification, high-precision intelligent layer tracking and interpretation, intelligent reservoir prediction, intelligent logging interpretation, well logging Intelligent prediction of physical property parameters, intelligent prediction of logging lithofacies categories, intelligent division of logging strata, GPT for Oil & Gas development operations, GPT for Oil & Gas communication',
      scrollback:false,
      model:false,
      dialogconsult:false,
      dialogVisible:false,
    }

  },

  methods: {
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
       window.scrollTo({
         top: 0,
         behavior: 'smooth'
       });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
 handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }


      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);

    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);

    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)

  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      padding: 7.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/ground1.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 2vw;
         font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 6.82076637824475vh auto 0 auto;
        width: 62vw;
        font-size: 1vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 8.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screenmake0 {
      padding: 5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto 3vh auto;;
        text-align: center;
        height: 4.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        line-height: 2.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto 3vh auto;
        text-align: center;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 66vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }

      .grid {

        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        //column-gap: 1vw;

        .card {
         // cursor: pointer;
          width: 100%;
          height: fit-content;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;

          .back {
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 1px solid #2f6dfd;
            height: fit-content;
            overflow: hidden;
            background: #FFFFFF;
            margin-bottom: 1.5248454883vh;

            .imgDesign {
              text-align: center;
              height: 23.24969097651422vh;
              width: 100%;
              background-image: url("../../assets/serve1.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 0.2vw;
              background-color: #2F6EFF;

              .Title1 {
                color: white;
                text-align: center;
                font-size: 0.9vw;
                 font-family: Instrument Sans,Arial;
                font-weight: 700;
              }

            }

            .backconsult {
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 5.55414091470952vw;
            }

            .backconsultArrow {
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }
        }

        .card1 {
         // cursor: pointer;
          width: 100%;
          height: fit-content;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;

          .back {
            border-radius: 9px ;;
            position: relative;
            border: 1px solid #2f6dfd;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height: fit-content;
            overflow: hidden;
            background: #FFFFFF;
            margin-bottom: 1.5248454883vh;

            .imgDesign {
              text-align: center;
              height: 54vh;
              width: 100%;
              background-image: url("../../assets/serve1.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 0.2vw;
              background-color: #2F6EFF;

              .Title1 {
                color: white;
                text-align: center;
                font-size: 0.9vw;

                 font-family: Instrument Sans,Arial;
                font-weight: 700;
              }

            }

            .backconsult {
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 5.55414091470952vw;
            }

            .backconsultArrow {
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }


        }
      }
    }
    .screenmake{
      padding: 5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:4.23733003708282vh;
        font-size: 1.7vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #FF6400;
         line-height: 2.23733003708282vh;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        //column-gap: 1vw;
        .card{
         // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border: 2px solid white;
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:55.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF;
            .imgDesign{
              text-align: center;
              height:23.24969097651422vh;
              width: 100%;
              background-image: url("../../assets/ground2.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1vw;
              .Title1{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 2vh;
                text-align: center;
                font-size: 0.9vw;
                height: 5vh;
                 font-family: Instrument Sans,Arial;
                font-weight: 700;
                line-height: 2.5vh;
              }
              .Title2{
                margin-top: 1.5vh;
                //text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Instrument Sans,Arial;
                font-weight: 500;
                line-height: 3vh;
              }
            }
            .backconsult{
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left:5.55414091470952vw ;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }
        }

        .card1{
         // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            border: 2px solid white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:55.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF;
            .imgDesign{
              text-align: center;
              height:23.24969097651422vh;
              width: 100%;
              background-image: url("../../assets/ground3.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1vw;
              .Title1{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 2vh;
                text-align: center;
                font-size: 0.9vw;
                height: 5vh;
                 font-family: Instrument Sans,Arial;
                font-weight: 700;
                line-height: 2.5vh;
              }
              .Title2{
                margin-top: 1.5vh;
                //text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Instrument Sans,Arial;
                font-weight: 500;
                line-height: 3vh;
              }
            }
            .backconsult{
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left:5.55414091470952vw ;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }
        }

        .card2{
         // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            border: 2px solid white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:55.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF;
            .imgDesign{
              text-align: center;
              height:23.24969097651422vh;
              width: 100%;
              background-image: url("../../assets/ground4.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1vw;
              .Title1{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 2vh;
                text-align: center;
                font-size: 0.9vw;
                height: 5vh;
                 font-family: Instrument Sans,Arial;
                font-weight: 700;
                line-height: 2.5vh;
              }
              .Title2{
                margin-top: 1.5vh;
                //text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Instrument Sans,Arial;
                font-weight: 500;
                line-height: 3vh;
              }
            }
            .backconsult{
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left:5.55414091470952vw ;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }
        }

        .card3{
         // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border: 2px solid white;
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:55.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF;
            .imgDesign{
              text-align: center;
              height:23.24969097651422vh;
              width: 100%;
              background-image: url("../../assets/ground5.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1vw;
              .Title1{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 2vh;
                text-align: center;
                font-size: 0.9vw;
                height: 5vh;
                 font-family: Instrument Sans,Arial;
                font-weight: 700;
                line-height: 2.5vh;
              }
              .Title2{
                margin-top: 1.5vh;
                //text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Instrument Sans,Arial;
                font-weight: 500;
                line-height: 3vh;
              }
            }
            .backconsult{
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left:5.55414091470952vw ;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }
        }

        .card4{
         // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border: 2px solid white;
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:55.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF;
            .imgDesign{
              text-align: center;
              height:23.24969097651422vh;
              width: 100%;
              background-image: url("../../assets/ground6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1vw;
              .Title1{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 2vh;
                text-align: center;
                font-size: 0.9vw;
                height: 5vh;
                 font-family: Instrument Sans,Arial;
                font-weight: 700;
                line-height: 2.5vh;
              }
              .Title2{
                margin-top: 1.5vh;
                //text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Instrument Sans,Arial;
                font-weight: 500;
                line-height: 3vh;
              }
            }
            .backconsult{
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left:5.55414091470952vw ;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }
        }

      }
    }
    .screenmake2{
      padding: 5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:4.23733003708282vh;
        font-size: 1.7vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #FF6400;
        line-height: 2.23733003708282vh;
      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap:0;
        .card{
         // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border: 2px solid white;
            border-radius: 16px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:56.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            .imgDesign{
              position:absolute;
              transition:transform 0.3s ease-out;
              overflow:hidden;
              width: 100%;
              height: 30.69962917181706vh;
              opacity: 1;
              background-image: url("../../assets/ground7.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title1{
                margin-top: 33vh;
                text-align: center;
                height: 2.7194066749vh;
                font-size: 0.9vw;
                 font-family: Instrument Sans,Arial;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{

                margin: 3vh 1.8597920277vw auto 1.8597920277vw;
                //text-align: justify;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 3vh;
              }
            }
          }
        }

        .card1{
          border-radius: 16px;
         // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border: 2px solid white;
            border-radius: 16px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:56.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            .imgDesign{
              position:absolute;
              transition:transform 0.3s ease-out;
              overflow:hidden;
              width: 100%;
              height: 30.69962917181706vh;
              opacity: 1;
              background-image: url("../../assets/ground8.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title1{
                margin-top: 33vh;
                text-align: center;
                height: 2.7194066749vh;
                font-size: 0.9vw;
                 font-family: Instrument Sans,Arial;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{

                margin: 3vh 1.8597920277vw auto 1.8597920277vw;
                //text-align: justify;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 3vh;
              }
            }
          }
        }

        .card2{
          border-radius: 16px;
         // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border: 2px solid white;
            border-radius: 16px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:56.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            .imgDesign{
              position:absolute;
              transition:transform 0.3s ease-out;
              overflow:hidden;
              width: 100%;
              height: 30.69962917181706vh;
              opacity: 1;
              background-image: url("../../assets/ground9.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title1{
                margin-top: 33vh;
                text-align: center;
                height: 2.7194066749vh;
                font-size: 0.9vw;
                 font-family: Instrument Sans,Arial;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{

                margin: 3vh 1.8597920277vw auto 1.8597920277vw;
                //text-align: justify;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 3vh;
              }
            }
          }
        }

      }
    }
    .screenmake3{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;
      //background-color: #ebf1fd;
      .heighLight{
        color:white;
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 1.7vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        .card{
          //cursor: pointer;
          margin:0 auto;
          height:75vh;
          width: 86%;
          padding:0.98887515451174vh 0 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:75vh;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 50vw;
              height:100%;
              opacity: 1;

              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              color: white;
              width: 30vw;
              margin:auto 0 auto 0;
              float: right;
              .title1{
                font-size: 1vw;
              }
              .title2{
                margin-top:5px;
                line-height: 3vh;
                font-size: 0.8243212016vw;
              }
              .success{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                //column-gap: 1vw;
                margin-top: 0.5vh;
                .suc1{
                  width: 100%;
                  height: 8vh;
                  .title3{
                    text-align: center;
                    margin-top: 2vh;
                    font-size: 0.8243212016vw;
                  }

                }

              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:75vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 550px;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
         // cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }

  }

}
.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      //z-index: 3;
      position: relative;
      width: 100%;
      height: 460px;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      background-image: url("../../assets/gptModel11.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin:50px auto;
        width: fit-content;
        text-align: center;
        height: 23px;
        font-size: 23px;
        font-family: Instrument Sans,Arial;
        font-weight: 500;
        color: #111111;
        line-height:32px;
      }
      .titlle3{
        position: absolute;
        bottom:75px;
        left:50%;
        transform: translateX(-60%);
        font-size: 12px;
         font-family: Instrument Sans,Arial;
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }
      .title4{
        position: absolute;
        left:10%;
        bottom:-75px;
        margin: 35px auto 0 auto;
        width: 80%;
        height: fit-content;
        font-size: 12px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #111111;
        line-height: 18px;
        border-radius: 15px;
        padding: 15px;
        background:white;
        border: #e8eefa 1px solid;
      }
    }
    .screenmake0{
      width: 100vw;
      height: fit-content;
      padding:90px 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 20px;
      }
      .heightLight2{
        margin:19px 2vw;
        ////text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {

        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap: 10px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0 0;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border:1px solid #2f6dfd;
            height:fit-content ;
            overflow:hidden;
            background: #FFFFFF;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              height:165px;
              width: 100%;
              background-image: url("../../assets/serve1.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding:10px 1.5248454883vw;
              background-color: #2F6EFF;
              .Title1{
                color: white;
                text-align: center;
                font-size: 12px;
                 font-family: Instrument Sans,Arial;
                font-weight: 500;
              }

            }

          }
        }
        .card1{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.6vh 0.69324090121317vw 0 0;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            border:1px solid #2f6dfd;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background: #FFFFFF;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              height:165px;
              width: 100%;
              background-image: url("../../assets/serve1.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding:10px 1.5248454883vw;
              background-color: #2F6EFF;
              .Title1{
                color: white;
                text-align: center;
                font-size: 12px;
                 font-family: Instrument Sans,Arial;
                font-weight: 700;
              }

            }
          }
          .back:hover{
            transform: scale(1.05);}
        }
      }
    }
    .screenmake{
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:  #ebf1fd;;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        row-gap: 0px;
        .card{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background-color: white;;
            padding: 7px 7.5px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px ;;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 125px;
              height: 100%;
              background-image: url("../../assets/ground2.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 7px 0 8px;
              .Title1{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                text-overflow: ellipsis;
                opacity: 4;
                width: 200px;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-family: Instrument Sans,Arial;
                //font-weight: bold;
                line-height: 20px;
                height: 52px;
              }
              .Title2{
                opacity: 4;
                width: 53vw;
                margin: 2px 0 10px 0;
                ////text-align: justify;
                font-size: 12px;
                font-family: Instrument Sans,Arial;
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card1{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background-color: white;;
            padding: 7px 7.5px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px ;;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 125px;
              height: 100%;
              background-image: url("../../assets/ground3.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 7px 0 8px;
              .Title1{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                text-overflow: ellipsis;
                opacity: 4;
                width: 200px;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-family: Instrument Sans,Arial;
                //font-weight: bold;
                line-height: 20px;
                height: 52px;
              }
              .Title2{
                opacity: 4;
                width: 53vw;
                margin: 2px 0 10px 0;
                ////text-align: justify;
                font-size: 12px;
                font-family: Instrument Sans,Arial;
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card2{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background-color: white;;
            padding: 7px 7.5px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px ;;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 125px;
              height: 100%;
              background-image: url("../../assets/ground4.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 7px 0 8px;
              .Title1{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                text-overflow: ellipsis;
                opacity: 4;
                width: 200px;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-family: Instrument Sans,Arial;
                //font-weight: bold;
                line-height: 20px;
                height: 52px;
              }
              .Title2{
                opacity: 4;
                width: 53vw;
                margin: 2px 0 10px 0;
                ////text-align: justify;
                font-size: 12px;
                font-family: Instrument Sans,Arial;
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card3{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background-color: white;;
            padding: 7px 7.5px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px ;;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 125px;
              height: 100%;
              background-image: url("../../assets/ground5.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 7px 0 8px;
              .Title1{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                text-overflow: ellipsis;
                opacity: 4;
                width: 200px;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-family: Instrument Sans,Arial;
                //font-weight: bold;
                line-height: 20px;
                height: 52px;
              }
              .Title2{
                opacity: 4;
                width: 53vw;
                margin: 2px 0 10px 0;
                ////text-align: justify;
                font-size: 12px;
                font-family: Instrument Sans,Arial;
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card4{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background-color: white;;
            padding: 7px 7.5px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px ;;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 125px;
              height: 100%;
              background-image: url("../../assets/ground6.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 7px 0 8px;
              .Title1{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                text-overflow: ellipsis;
                opacity: 4;
                width: 200px;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-family: Instrument Sans,Arial;
                //font-weight: bold;
                line-height: 20px;
                height: 52px;
              }
              .Title2{
                opacity: 4;
                width: 53vw;
                margin: 2px 0 10px 0;
                ////text-align: justify;
                font-size: 12px;
                font-family: Instrument Sans,Arial;
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
      }
    }
    .screenmake2{
      //z-index: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:  white;;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        row-gap: 15px;
        .card{
          cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: #ebf1fd;;
            .imgDesign{
              text-align: center;
              height:230px;
              width: 100%;
              background-image: url("../../assets/ground7.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1vw;
              .Title1{
                margin-top: 15px;
                text-align: center;
                height: fit-content;
                font-size:12px;
                 font-family: Instrument Sans,Arial;
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 15px 8px 20px 8px;
                ////text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
        .card1{
          cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: #ebf1fd;;
            .imgDesign{
              text-align: center;
              height:230px;
              width: 100%;
              background-image: url("../../assets/ground8.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1vw;
              .Title1{
                margin-top: 15px;
                text-align: center;
                height: fit-content;
                font-size:12px;
                 font-family: Instrument Sans,Arial;
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 15px 8px 20px 8px;
                ////text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
        .card2{
          cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: #ebf1fd;;
            .imgDesign{
              text-align: center;
              height:230px;
              width: 100%;
              background-image: url("../../assets/ground9.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1vw;
              .Title1{
                margin-top: 15px;
                text-align: center;
                height: fit-content;
                font-size:12px;
                 font-family: Instrument Sans,Arial;
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 15px 8px 20px 8px;
                ////text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: Instrument Sans,Arial;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
      }
    }

    .screenmake3{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: black;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 20px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
          cursor: pointer;
          width: 98%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:10px;
                font-size:12px;
                line-height: 20px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:12px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 680px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -280px -10px  ;
          cursor: pointer;
          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

  }

}
</style>
