
<template>
  <div>
    你好，登录
  </div>
</template>



<script>

export default {
  name: "login",
  created() {
    localStorage.setItem("userId",12)
  },
}
</script>

<style scoped lang="scss">

</style>
