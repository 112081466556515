import { render, staticRenderFns } from "./recognition.vue?vue&type=template&id=67f550ba&scoped=true"
import script from "./recognition.vue?vue&type=script&lang=js"
export * from "./recognition.vue?vue&type=script&lang=js"
import style0 from "./recognition.vue?vue&type=style&index=0&id=67f550ba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_420b40f8fe8ddaf3248c7dea96ec9571/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67f550ba",
  null
  
)

export default component.exports