<template>
  <div>
    <div v-if="model===false">
      <div ref="total" class="totalmakeHole">
        <div class="makeHole1">
          <div
               :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}"
              class="topback">
            <headtip @showDialog="showPartner"></headtip>
          </div>
          <div class="screen1">
             <kefu @toptop="topTop"></kefu>
            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1"> <img loading="lazy" style="border-radius: 9px" src="@/assets/AnxunLogo.png"></div>
              <div class="title2">Based on various application scenarios in Oil & Gas fields, Anvision provides professional accompanying services with the most reliable project landing, the fastest project cycle, and the lowest cost to meet the rigid demand for Oil & Gas field digital intelligence. Our services include comprehensive solutions for smart oilfields and digital intelligence services for all Oil & Gas field scenes.</div>
              <div class="title4">Oil & Gas Digitalization, Simple and Practical</div>
              <div class="titlle3"   @click="clickConsult('Oil & Gas Generic AI')">
                Product Inquiry
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenmake0">
            <div class="heighLight">Smart Oil & Gas Production Optimization and AI Information Construction Services</div>
            <div class="heightLight2">
              The service leverages AI, big data analytics, and IIoT to <span style="color: rgb(255, 100, 0)">optimize production processes and support smart decision-making</span>. It helps enterprises <span style="color: rgb(255, 100, 0)">enhance efficiency, reduce costs, and adapt to changing market conditions </span> while providing precise industry insights for informed decisions and continuous innovation.
            </div>
          </div>
          <div class="screenmake6" style="background-color: transparent">
            <div class="heighLight" style="font-size: 1.7vw;"> Business Pain Points and Challenges</div>
            <div class="line"></div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/build01.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Difficulty in integrating technology with business
                    </div>
                    <div class="title2">
                      The Oil & Gas industry is highly specialized with complex business processes, requiring deep integration of artificial intelligence technology with industry knowledge. However, there exists a communication barrier between algorithm engineers and business personnel, making it difficult to form effective technical solutions.
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/build02.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Challenge of iterative upgrades for intelligent applications
                    </div>
                    <div class="title2">
                      Intelligent applications in the Oil & Gas industry require continuous technological iteration and funding. However, current technologies and methodologies are still immature, lacking sustained upgrades and financial support, which hinders the continuous optimization and expansion of these intelligent applications.
                    </div>
                  </div>
                </div>


              </div>

            </div>

          </div>
          <div class="screenmake1">
            <div class="heighLight">Solution Architecture</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <el-tooltip class="item" content="Click to enlarge for a clearer view!" effect="dark" placement="top">
                      <img loading="lazy" src="@/assets/build1.png"
                           style="border-radius: 9px" @click="showModalTrue(require('@/assets/build1.png'))">
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
<!--          <div class="screenmake02" style="background-color: transparent">-->
<!--            <div class="heighLight">核心功能</div>-->
<!--            <div class="grid">-->
<!--              <div class="card">-->
<!--                <div class="back" style="background-color: white">-->
<!--                  <div class="titleDesign">-->
<!--                    <div class="title2">-->
<!--                      <li><span style="font-weight: bold">高效决策支持 </span><br>显著缩短方案执行周期，加速见效。</li>-->
<!--                      <br>-->
<!--                      <li><span style="font-weight: bold">实时成本控制 </span><br>动态调整，减少无效能耗，降低成本。-->
<!--                      </li>-->
<!--                      <br>-->
<!--                      <li><span style="font-weight: bold"> 全面优化方案 </span><br>综合平衡多维度优化方案。-->
<!--                      </li>-->
<!--                      <br>-->
<!--                      <li><span style="font-weight: bold">人力资源优化 </span><br>自动监控、控制生产过程，大幅节省现场服务人力资源-->
<!--                        。-->
<!--                      </li>-->
<!--                      <br>-->
<!--                      <li><span style="font-weight: bold">多样化交互与迭代 </span><br>满足不同用户需求，实现快速迭代与多专业支持。-->
<!--                      </li>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="imgDesign">-->
<!--                    <el-tooltip class="item" effect="dark" content="点击放大，看得更清晰！" placement="top">-->
<!--                      <img loading="lazy" @click="showModalTrue(require('@/assets/build3.png'))"-->
<!--                           style="border-top-right-radius: 9px;border-bottom-right-radius: 9px"-->
<!--                           src="@/assets/build3.png">-->
<!--                    </el-tooltip>-->
<!--                  </div>-->
<!--                </div>-->


<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
          </div>
          <div class="screenmake03">
            <div class="heighLight"> Service Advantages</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign" style="width: 12vh">
                    <img loading="lazy" src="@/assets/guanwang1.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1"> Efficient Decision Support

                    </div>
                    <div class="title2">
                      Significantly shorten the execution cycle of plans and accelerate results.
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/guanwang2.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Real-time Cost Control

                    </div>
                    <div class="title2">
                      Dynamically adjust to reduce ineffective energy consumption and lower costs.
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/guanwang3.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Comprehensive Optimization Plan
                    </div>
                    <div class="title2">
                      Balance multi-dimensional optimization plans comprehensively.
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/guanwang4.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Human Resource Optimization
                    </div>
                    <div class="title2">
                      Automatically monitor and control the production process, significantly saving on-site service human resources.
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/buildnew.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Diversified Interaction and Iteration
                    </div>
                    <div class="title2">
                      Meet the needs of different users and achieve rapid iteration with multi-disciplinary support.
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake01">
            <div class="heighLight" style="margin-top: 15px">Service Capabilities</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <el-tooltip class="item" effect="dark" content="Click to enlarge for a clearer view!" placement="top">
                      <img loading="lazy" @click="showModalTrue(require('@/assets/build2.jpeg'))"
                           style="border-top-left-radius: 9px;border-bottom-left-radius: 9px"
                           src="@/assets/build2.jpeg">
                    </el-tooltip>
                  </div>
                  <div class="titleDesign">
                    <div class="title2" style="display: block">
                      <li><span style="font-weight: bold"> Oil & Gas Production Process Intelligent Optimization Service </span><br>By deploying a global optimization system and intelligent control products for small scenarios, we enhance the intelligent adjustment capabilities of the production process and optimize the production workflow.
                      </li>
                      <li style="margin-top: 10px"><span style="font-weight: bold"> Oil & Gas Production Intelligent Interactive Service </span><br>
                        Through real-time analysis of production and operation data and historical knowledge bases, we provide intelligent question answering and solution recommendations, strengthening decision support in production.
                      </li>
                      <li style="margin-top: 10px"><span style="font-weight: bold"> Oil & Gas Production Intelligent Design and Construction Service </span><br>Combining design experience in petroleum engineering, we achieve intelligent well location gathering and transmission, pipeline network, station, and process design, promoting improvements in design quality and construction efficiency.
                      </li>
                      <li style="margin-top: 10px"><span
                          style="font-weight: bold"> Oil Industry Information AI Platform Construction Service </span><br>We provide online and offline standard/customized information services in the fields of petroleum economy, technology, etc., for petroleum engineering technicians as well as management and decision-makers in the oil & gas sector.
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <!--          <div class="screenmake7">-->
            <!--            <div class="heighLight">-->
            <!--              核心功能-->
            <!--            </div>-->
            <!--            <div style="display: flex;">-->

            <!--              <div style="display: contents">-->
            <!--                <div class="grid" style="width: 34%;margin-top: 2vh;margin-left: 5vw">-->
            <!--                  <div class="card">-->
            <!--                    <div class="back">-->
            <!--                      <div class="titleDesign">-->

            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="card">-->
            <!--                    <div class="back">-->
            <!--                      <div class="titleDesign">-->
            <!--                        <div class="title1">-->
            <!--                          实时监控与数据采集-->
            <!--                        </div>-->
            <!--                        <div class="title2">-->
            <!--                          通过传感器实时监测关键参数并传输至中央系统处理-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="card">-->
            <!--                    <div class="back">-->
            <!--                      <div class="titleDesign">-->
            <!--                        <div class="title1">智能预警与故障诊断-->
            <!--                        </div>-->
            <!--                        <div class="title2">-->
            <!--                          利用AI识别异常，提前预警并提供故障诊断和维护建议-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="card">-->
            <!--                    <div class="back">-->
            <!--                      <div class="titleDesign">-->
            <!--                        <div class="title1">优化调度与资源管理-->
            <!--                        </div>-->
            <!--                        <div class="title2">-->
            <!--                          通过大数据优化气体调度，提升资源利用率，调整储气策略-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="card">-->
            <!--                    <div class="back">-->
            <!--                      <div class="titleDesign">-->
            <!--                        <div class="title1">远程控制与自动化操作-->
            <!--                        </div>-->
            <!--                        <div class="title2">-->
            <!--                          实现设备远程控制与自动化操作，减少人工干预，提高安全性-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="card">-->
            <!--                    <div class="back">-->
            <!--                      <div class="titleDesign">-->
            <!--                        <div class="title1">数据分析与决策支持-->
            <!--                        </div>-->
            <!--                        <div class="title2">-->
            <!--                          集成数据分析，提供运行报告和趋势分析，辅助科学决策-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="fanganimg" style="float: right;height: 100%;width:60% ">-->
            <!--                <img src="@/assets/equipment5new.png" style="float: right;    height: 77%;-->
            <!--    width: 90%;-->
            <!--    margin:3vh auto;">-->
            <!--              </div>-->
            <!--            </div>-->
            <!--          </div>-->
            <!--          <div>-->
            <!--            &lt;!&ndash;            <div class="screenmake21">&ndash;&gt;-->
            <!--            &lt;!&ndash;              <div class="heighLight"> 核心功能</div>&ndash;&gt;-->
            <!--            &lt;!&ndash;              <div class="grid">&ndash;&gt;-->
            <!--            &lt;!&ndash;                <div class="card">&ndash;&gt;-->
            <!--            &lt;!&ndash;                  <div class="back">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="imgDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <img loading="lazy"  style="border-radius: 9px" src="../../assets/setingu1.png">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="titleDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title1">实时监控与数据采集&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title2">&ndash;&gt;-->
            <!--            &lt;!&ndash;                        通过传感器实时监测关键参数并传输至中央系统处理。&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                <div class="card">&ndash;&gt;-->
            <!--            &lt;!&ndash;                  <div class="back">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="imgDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <img loading="lazy"  style="border-radius: 9px" src="../../assets/setingu3.png">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="titleDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title1">智能预警与故障诊断&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title2">&ndash;&gt;-->
            <!--            &lt;!&ndash;                        利用AI识别异常，提前预警并提供故障诊断和维护建议。&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->


            <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                <div class="card">&ndash;&gt;-->
            <!--            &lt;!&ndash;                  <div class="back" >&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="imgDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <img loading="lazy"  style="border-radius: 9px;margin-top: 2.5px" src="../../assets/setingu4.png">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="titleDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title1">优化调度与资源管理&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title2">&ndash;&gt;-->
            <!--            &lt;!&ndash;                        通过大数据优化气体调度，提升资源利用率，调整储气策略。&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->


            <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;              </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;              <div class="grid" style=" grid-template-columns:  minmax(0,1fr) minmax(0,1fr);padding: 0 28vh">&ndash;&gt;-->
            <!--            &lt;!&ndash;                <div class="card">&ndash;&gt;-->
            <!--            &lt;!&ndash;                  <div class="back" style="">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="imgDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <img loading="lazy"  style="border-radius: 9px" src="../../assets/setingu6.png">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="titleDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title1">远程控制与自动化操作&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title2">&ndash;&gt;-->
            <!--            &lt;!&ndash;                        实现设备远程控制与自动化操作，减少人工干预，提高安全性。&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->


            <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                <div class="card">&ndash;&gt;-->
            <!--            &lt;!&ndash;                  <div class="back" style="">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="imgDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <img loading="lazy"  style="border-radius: 9px;width: 40px" src="../../assets/setingu2.png">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="titleDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title1">数据分析与决策支持&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title2">&ndash;&gt;-->
            <!--            &lt;!&ndash;                        集成数据分析，提供运行报告和趋势分析，辅助科学决策。&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;              </div>&ndash;&gt;-->

            <!--            &lt;!&ndash;            </div>&ndash;&gt;-->
            <!--          </div>-->
          </div>
          <div class="screenmake2">
            <div class="heighLight"> Service Scenario</div>
            <div class="grid">

              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/build4.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1"> Smart Brain for Compressor Unit Operation
                    </div>
                    <div class="title2">
                      <li>Achieve optimal operating parameters for equipment and facilities from aspects such as equipment energy efficiency, safe operation, and cost optimization.</li>
                      <li> Iterate large models to form an intelligent control and optimization model for the equipment.</li>
                      <li> Integrate control protocols to enable intelligent control of unit startup, shutdown, and switching.</li>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/build5.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Smart Brain for Energy Consumption in Purification Plants
                    </div>
                    <div class="title2">
                      <li>  Analyze weak links in energy usage by relying on consumption data for water, electricity, gas, wind, oil, and chemicals, combined with process, production, and operational data.</li>
                      <li> Iterate large models to form scenario-based models for various types of energy consumption control.</li>
                      <li> Combine with the control system to issue optimal operating parameters and form a closed-loop control.</li>
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/build6.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Smart Brain for Gas Gathering and Transmission Pipeline Network in Gas Production Plants
                    </div>
                    <div class="title2">
                      <li>Direct individual wells, pipeline networks, and gathering and transmission stations to operate optimally based on selected plans.</li>
                      <li>Achieve intelligent peak shaving, pressure regulation; intelligent distribution of flow and pressure.</li>
                      <li>Enable remote intelligent control of valve chambers; optimize energy consumption of booster and pressure reduction systems.</li>
                    </div>
                  </div>
                </div>


              </div>


            </div>

          </div>
          <div class="screenmake4">
            <div class="heighLight">Successful Cases</div>
            <div class="grid" style="margin-top: -0.212608vh;">
              <el-carousel arrow="always" indicator-position="outside" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <el-tooltip class="item" content="Click to enlarge for a clearer view!" effect="dark" placement="top">
                          <img :src="item.img" loading="lazy" @click="showModalTrue(item.img)"/>
                        </el-tooltip>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{ item.title1 }}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <bottomTip></bottomTip>
        </div>
        <div v-if="showModal" class="modal-overlay" @click="closeModal">
          <div class="modal-content">
            <img :src="largeSrc" alt="Large Image" class="large-image">
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <headtip @showDialog="showPartner"></headtip>
            <kefuMobile @topMobile="topTopmobile"></kefuMobile>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1"> <img loading="lazy" style="border-radius: 9px" src="@/assets/AnxunLogo.png"></div>
              <div class="title2">Based on various application scenarios in Oil & Gas fields, Anvision provides professional accompanying services with the most reliable project landing, the fastest project cycle, and the lowest cost to meet the rigid demand for Oil & Gas field digital intelligence. Our services include comprehensive solutions for smart oilfields and digital intelligence services for all Oil & Gas field scenes.</div>

            </div>
            <div class="title4">Oil & Gas Digitalization, Simple and Practical</div>

            <div class="titlle3"   @click="clickConsult('Oil & Gas Generic AI')">
              Product Inquiry
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenmake0">
            <div class="heighLight">Smart Oil & Gas Production Optimization and AI Information Construction Services</div>
            <div class="heightLight2" >
              The service leverages AI, big data analytics, and IIoT to <span style="color: rgb(255, 100, 0)">optimize production processes and support smart decision-making</span>. It helps enterprises<span style="color: rgb(255, 100, 0)"> enhance efficiency, reduce costs, and adapt to changing market conditions</span> while providing precise industry insights for informed decisions and continuous innovation.
            </div>
          </div>
          <div class="screenmake8" style="padding-top: 0">
            <div class="heighLight" style="height: fit-content;"> Business Pain Points and Challenges</div>
            <div class="grid">
              <div class="card" style="margin-top: 10px">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/build01.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Difficulty in integrating technology with business
                    </div>
                    <div class="title2">
                      The Oil & Gas industry is highly specialized with complex business processes, requiring deep integration of artificial intelligence technology with industry knowledge. However, there exists a communication barrier between algorithm engineers and business personnel, making it difficult to form effective technical solutions.
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/build02.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Challenge of iterative upgrades for intelligent applications
                    </div>
                    <div class="title2">
                      Intelligent applications in the Oil & Gas industry require continuous technological iteration and funding. However, current technologies and methodologies are still immature, lacking sustained upgrades and financial support, which hinders the continuous optimization and expansion of these intelligent applications.
                    </div>
                  </div>
                </div>


              </div>

            </div>

          </div>
          <div class="screenmake1">
            <div class="heighLight">Solution Architecture</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <el-tooltip class="item" content="Click to enlarge for a clearer view!" effect="dark" placement="top">
                      <img loading="lazy" src="@/assets/build1.png"
                           style="border-radius: 9px" @click="showModalTrue(require('@/assets/build1.png'))">
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="screenmakenew">
            <div class="heighLight"> Service Advantages</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/guanwang1.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1"> Efficient Decision Support

                    </div>
                    <div class="title2">
                      Significantly shorten the execution cycle of plans and accelerate results.
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/guanwang2.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Real-time Cost Control

                    </div>
                    <div class="title2">
                      Dynamically adjust to reduce ineffective energy consumption and lower costs.
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/guanwang3.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Comprehensive Optimization Plan
                    </div>
                    <div class="title2">
                      Balance multi-dimensional optimization plans comprehensively.
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/guanwang4.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Human Resource Optimization
                    </div>
                    <div class="title2">
                      Automatically monitor and control the production process, significantly saving on-site service human resources.
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/buildnew.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Diversified Interaction and Iteration
                    </div>
                    <div class="title2">
                      Meet the needs of different users and achieve rapid iteration with multi-disciplinary support.
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake1">
            <div class="heighLight" style="padding: 15px 0">Service Capabilities</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <el-tooltip class="item" effect="dark" content="Click to enlarge for a clearer view!" placement="top">
                      <img loading="lazy" @click="showModalTrue(require('@/assets/build2.jpeg'))"
                           style="border-top-left-radius: 9px;border-bottom-left-radius: 9px"
                           src="@/assets/build2.jpeg">
                    </el-tooltip>
                  </div>
                  <div class="titleDesign">
                    <div class="title2" style="display: block">
                      <li><span style="font-weight: bold"> Oil & Gas Production Process Intelligent Optimization Service </span><br>By deploying a global optimization system and intelligent control products for small scenarios, we enhance the intelligent adjustment capabilities of the production process and optimize the production workflow.
                      </li>
                      <li style="margin-top: 10px"><span style="font-weight: bold"> Oil & Gas Production Intelligent Interactive Service </span><br>
                        Through real-time analysis of production and operation data and historical knowledge bases, we provide intelligent question answering and solution recommendations, strengthening decision support in production.
                      </li>
                      <li style="margin-top: 10px"><span style="font-weight: bold"> Oil & Gas Production Intelligent Design and Construction Service </span><br>Combining design experience in petroleum engineering, we achieve intelligent well location gathering and transmission, pipeline network, station, and process design, promoting improvements in design quality and construction efficiency.
                      </li>
                      <li style="margin-top: 10px"><span
                          style="font-weight: bold"> Oil Industry Information AI Platform Construction Service </span><br>We provide online and offline standard/customized information services in the fields of petroleum economy, technology, etc., for petroleum engineering technicians as well as management and decision-makers in the oil & gas sector.
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <!--          <div class="screenmake7">-->
            <!--            <div class="heighLight">-->
            <!--              核心功能-->
            <!--            </div>-->
            <!--            <div style="display: flex;">-->

            <!--              <div style="display: contents">-->
            <!--                <div class="grid" style="width: 34%;margin-top: 2vh;margin-left: 5vw">-->
            <!--                  <div class="card">-->
            <!--                    <div class="back">-->
            <!--                      <div class="titleDesign">-->

            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="card">-->
            <!--                    <div class="back">-->
            <!--                      <div class="titleDesign">-->
            <!--                        <div class="title1">-->
            <!--                          实时监控与数据采集-->
            <!--                        </div>-->
            <!--                        <div class="title2">-->
            <!--                          通过传感器实时监测关键参数并传输至中央系统处理-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="card">-->
            <!--                    <div class="back">-->
            <!--                      <div class="titleDesign">-->
            <!--                        <div class="title1">智能预警与故障诊断-->
            <!--                        </div>-->
            <!--                        <div class="title2">-->
            <!--                          利用AI识别异常，提前预警并提供故障诊断和维护建议-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="card">-->
            <!--                    <div class="back">-->
            <!--                      <div class="titleDesign">-->
            <!--                        <div class="title1">优化调度与资源管理-->
            <!--                        </div>-->
            <!--                        <div class="title2">-->
            <!--                          通过大数据优化气体调度，提升资源利用率，调整储气策略-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="card">-->
            <!--                    <div class="back">-->
            <!--                      <div class="titleDesign">-->
            <!--                        <div class="title1">远程控制与自动化操作-->
            <!--                        </div>-->
            <!--                        <div class="title2">-->
            <!--                          实现设备远程控制与自动化操作，减少人工干预，提高安全性-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="card">-->
            <!--                    <div class="back">-->
            <!--                      <div class="titleDesign">-->
            <!--                        <div class="title1">数据分析与决策支持-->
            <!--                        </div>-->
            <!--                        <div class="title2">-->
            <!--                          集成数据分析，提供运行报告和趋势分析，辅助科学决策-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="fanganimg" style="float: right;height: 100%;width:60% ">-->
            <!--                <img src="@/assets/equipment5new.png" style="float: right;    height: 77%;-->
            <!--    width: 90%;-->
            <!--    margin:3vh auto;">-->
            <!--              </div>-->
            <!--            </div>-->
            <!--          </div>-->
            <!--          <div>-->
            <!--            &lt;!&ndash;            <div class="screenmake21">&ndash;&gt;-->
            <!--            &lt;!&ndash;              <div class="heighLight"> 核心功能</div>&ndash;&gt;-->
            <!--            &lt;!&ndash;              <div class="grid">&ndash;&gt;-->
            <!--            &lt;!&ndash;                <div class="card">&ndash;&gt;-->
            <!--            &lt;!&ndash;                  <div class="back">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="imgDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <img loading="lazy"  style="border-radius: 9px" src="../../assets/setingu1.png">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="titleDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title1">实时监控与数据采集&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title2">&ndash;&gt;-->
            <!--            &lt;!&ndash;                        通过传感器实时监测关键参数并传输至中央系统处理。&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                <div class="card">&ndash;&gt;-->
            <!--            &lt;!&ndash;                  <div class="back">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="imgDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <img loading="lazy"  style="border-radius: 9px" src="../../assets/setingu3.png">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="titleDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title1">智能预警与故障诊断&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title2">&ndash;&gt;-->
            <!--            &lt;!&ndash;                        利用AI识别异常，提前预警并提供故障诊断和维护建议。&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->


            <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                <div class="card">&ndash;&gt;-->
            <!--            &lt;!&ndash;                  <div class="back" >&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="imgDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <img loading="lazy"  style="border-radius: 9px;margin-top: 2.5px" src="../../assets/setingu4.png">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="titleDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title1">优化调度与资源管理&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title2">&ndash;&gt;-->
            <!--            &lt;!&ndash;                        通过大数据优化气体调度，提升资源利用率，调整储气策略。&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->


            <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;              </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;              <div class="grid" style=" grid-template-columns:  minmax(0,1fr) minmax(0,1fr);padding: 0 28vh">&ndash;&gt;-->
            <!--            &lt;!&ndash;                <div class="card">&ndash;&gt;-->
            <!--            &lt;!&ndash;                  <div class="back" style="">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="imgDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <img loading="lazy"  style="border-radius: 9px" src="../../assets/setingu6.png">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="titleDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title1">远程控制与自动化操作&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title2">&ndash;&gt;-->
            <!--            &lt;!&ndash;                        实现设备远程控制与自动化操作，减少人工干预，提高安全性。&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->


            <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                <div class="card">&ndash;&gt;-->
            <!--            &lt;!&ndash;                  <div class="back" style="">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="imgDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <img loading="lazy"  style="border-radius: 9px;width: 40px" src="../../assets/setingu2.png">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="titleDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title1">数据分析与决策支持&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title2">&ndash;&gt;-->
            <!--            &lt;!&ndash;                        集成数据分析，提供运行报告和趋势分析，辅助科学决策。&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;              </div>&ndash;&gt;-->

            <!--            &lt;!&ndash;            </div>&ndash;&gt;-->
            <!--          </div>-->
          </div>
          <div class="screenmake2">
            <div class="heighLight"> Service Scenario</div>
            <div class="grid">

              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/build4.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1"> Smart Brain for Compressor Unit Operation
                    </div>
                    <div class="title2">
                      <li>Achieve optimal operating parameters for equipment and facilities from aspects such as equipment energy efficiency, safe operation, and cost optimization.</li>
                      <li> Iterate large models to form an intelligent control and optimization model for the equipment.</li>
                      <li> Integrate control protocols to enable intelligent control of unit startup, shutdown, and switching.</li>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/build5.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Smart Brain for Energy Consumption in Purification Plants
                    </div>
                    <div class="title2">
                      <li>  Analyze weak links in energy usage by relying on consumption data for water, electricity, gas, wind, oil, and chemicals, combined with process, production, and operational data.</li>
                      <li> Iterate large models to form scenario-based models for various types of energy consumption control.</li>
                      <li> Combine with the control system to issue optimal operating parameters and form a closed-loop control.</li>
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/build6.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Smart Brain for Gas Gathering and Transmission Pipeline Network in Gas Production Plants
                    </div>
                    <div class="title2">
                      <li>Direct individual wells, pipeline networks, and gathering and transmission stations to operate optimally based on selected plans.</li>
                      <li>Achieve intelligent peak shaving, pressure regulation; intelligent distribution of flow and pressure.</li>
                      <li>Enable remote intelligent control of valve chambers; optimize energy consumption of booster and pressure reduction systems.</li>
                    </div>
                  </div>
                </div>


              </div>


            </div>

          </div>
          <div class="screenmake4">
            <div class="heighLight">Successful Cases</div>
            <div class="grid" style="margin-top: -0.212608vh;">
              <el-carousel arrow="always" indicator-position="outside" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <el-tooltip class="item" content="Click to enlarge for a clearer view!" effect="dark" placement="top">
                          <img :src="item.img" loading="lazy" @click="showModalTrue(item.img)"/>
                        </el-tooltip>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{ item.title1 }}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>

          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>


</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import kefuMobile from "@/views/kefuMobile.vue";
import kefu from "@/views/kefu.vue";

export default {

  name: "",

  props: [],

  components: {PartnerTip, consultTip, headtip, bottomTip, kefu, kefuMobile},

  data() {
    return {
      title: "",
      showModal: false,
      largeSrc: '',
      pageTitle: 'Smart Oil & Gas Production Optimization and AI Information Construction Services',
      pageDescription: ' ？？？？',
      pageKeywords: '智慧储气库解决方案，风险排查与隐患治理，消防机器人，特殊作业管控，精确人员定位平台，智能 AI 安防，飞行器反制，智能AI巡检，智慧培训与应急模拟演练，智能消防，安迅数智，油气通GPT',
      scrollback: false,
      model: false,
      dialogconsult: false,
      dialogVisible: false,
      success: [{
        img: require("@/assets/buildsucesse.png"),
        title1: "Smart Oil & Gas Production Optimization and AI Information Construction Services\n",
        title2: "<li>Someone Downhole Operations Technology Research and Development Status Information Service\n</li>",
      },
      ]
    }

  },


  methods: {
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
       window.scrollTo({
         top: 0,
         behavior: 'smooth'
       });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
       const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    showModalTrue(item) {
      this.showModal = true;
      console.log(item)
      this.largeSrc = item
    },
    closeModal() {
      this.showModal = false;
    },
    clickConsult(item) {

      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide() {
      this.dialogconsult = false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner() {
      this.dialogconsult = true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 500) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  beforeDestroy() {

    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);

    if (screenWidth <= 700) {
      this.model = true
    } else {
      this.model = false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home", 0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '智慧安防解决方案_安迅数智-油气通GPT',
        pageDescription: '安迅数智智慧安防解决方案，基于油田HSSE管理角度设计，提供全方位管理支持和全面安全保障，包括特种设备管理、特殊作业管理、承包商管理；全面安全控制：安全教育、风险分级管控、 隐患排查治理、重大危险源管理、重点现场监控、危化品监管。',
        pageKeywords: '智慧安防，风险排查与隐患治理，消防机器人，特殊作业管控，精确人员定位平台，智能 AI 安防，飞行器反制，智能AI巡检，智慧培训与应急模拟演练，智能消防，安迅数智，油气通GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style lang="scss" scoped type="text/scss">
.totalmakeHole {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: 100vh;

    .topback {
      position: fixed;
      z-index: 999999999999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }

    .screen1 {
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 63vh;
      padding: 7.8430160692vh 2.860774119vw 2.8430160692vh 5.860774119vw;
      background-image: url("../../assets/banner.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 8.1495673671199vh;
        left: 8.17157712305026vw;

        .title1 {
          width: fit-content;
          height: 5.77626699629172vh;
          font-size: 3.50606585788562vw;
          font-family: Instrument Sans,Arial;
          font-weight: 400;
          color: #2f6eff;
          line-height: 12.23733003708282vh;
        }

        .title2 {
          margin-top: 4.82076637824475vh;
          width: 37vw;
          //text-align: justify;
          height: fit-content;
          font-size:0.9597920277vw;
          font-family: Instrument Sans,Arial;
          font-weight: 400;
          color: black;
          line-height: 3.51520395550062vh;
        }
        .title4{
          height: 8.77626699629172vh;
          font-size: 1.3060658579vw;
          font-family: Instrument Sans,Arial;
          font-weight: 400;
          color: #2f6eff;
          line-height: 9.31520395550062vh;
        }
        .titlle3{
          margin-top: 2.07911001236094vh;
          //cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Instrument Sans,Arial;
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }

      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }

    .screenmake0 {
      padding: 7.78739184177998vh 8.09763142692085vw 4vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-weight: 600;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: left;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 80vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }
    }
    .screenmake6 {
      padding: 4.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);
        border-bottom: dashed black 1px;
        position: absolute;
        top:16.5vh;
        z-index: 1;
      }
      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:   minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 2vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              height: 5vh;
              margin:0 auto 2vh auto;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              width: 100%;
              //margin: 0 0 0 1.5vw;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake01 {
      padding: 2.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-weight: 600;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 0.5vw;
        text-align: left;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.15vw;
        font-family: Instrument Sans,Arial;
      }

      .heightLight3 {
        margin: 3vh auto;
        text-align: center;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.5vw;
        font-family: Instrument Sans,Arial;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #eeeeef;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 65vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw 2.5248454883vh 1.5248454883vw;
              margin: auto 1vw auto auto;
              color: black;
              width: 50%;
              float: right;

              .title1 {
                margin-top: 3vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                line-height: 3.0vh;
                font-size: 0.9vw;
              }
            }
          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }

      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 32vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 8vh;
              opacity: 1;
              margin: 3vh auto;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 0 2.5248454883vw 2.5248454883vh 2.5248454883vw;
              margin: 0 auto;
              color: black;
              width: 100%;
              float: right;

              .title1 {
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 1vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake02 {
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 0.5vw;
        text-align: left;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.15vw;
        font-family: Instrument Sans,Arial;
      }

      .heightLight3 {
        margin: 3vh auto;
        text-align: center;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.5vw;
        font-family: Instrument Sans,Arial;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #eeeeef;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 59vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw 2.5248454883vh 1.5248454883vw;
              margin: auto 1vw auto auto;
              color: black;
              width: 50%;
              float: right;

              .title1 {
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                line-height: 3.3vh;
                width: fit-content;
                margin: auto;
                font-size: 0.9vw;
              }
            }
          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }

      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 32vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 8vh;
              opacity: 1;
              margin: 3vh auto;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 0 2.5248454883vw 2.5248454883vh 2.5248454883vw;
              margin: 0 auto;
              color: black;
              width: 100%;
              float: right;

              .title1 {
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 1vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake03{
      padding: 4.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: transparent;
      //background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-weight: 600;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        //column-gap: 2vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: white;
            //display: flex;
            border: 9px;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 39vh;
            overflow: hidden;

            .imgDesign {
              width: 8vh;
              height: 8vh;
              opacity: 1;
              margin: 3vh auto;
              //background-image: url("../../assets/menage6.png");
            }

            .titleDesign {
              padding: 0;
              margin: 0 0 0 1.5vw;
              color: black;
              width: 85%;
              //float: right;
              .title1 {
                margin: 0 auto;
                text-align: center;
                font-size: 1vw;
                line-height: 3vh;
                font-weight: bold;
              }

              .title2 {
                margin: 0vh auto;
                width: fit-content;
                line-height: 3vh;
                font-size: 0.8243212016vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake1 {
      padding: 4vh 8.09763142692085vw 0.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-weight: 600;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }

      .grid {
        margin-top: 0vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              height: fit-content;
              opacity: 1;
            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake5 {
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: transparent;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 0;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: white;
            border-radius: 9px;
            //display: flex;
            position: relative;
            border: 1px solid white;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 38vh;
            padding: 2vh 1.5vw 3vh 1.5vw;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              margin: 0 auto 1vh auto;
              height: 5vh;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 0 0 1.5vw;
              color: black;
              width: 100%;
              float: right;
              margin-top: 20px;

              .title1 {
                margin: 0 auto;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake2 {
      padding: 3.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      //background: transparent;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-weight: 600;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 2vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: 100%;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #EFF5FF;
            //display: flex;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 100%;
            overflow: hidden;

            .imgDesign {
              width: 14vh;
              height: 16vh;
              opacity: 1;
              margin: 3vh auto;
              //background-image: url("../../assets/menage6.png");
            }

            .titleDesign {
              padding: 0;
              margin: 0 0 0 1.5vw;
              color: black;
              width: 85%;
              //float: right;
              .title1 {
                text-align: center;
                margin-top: 0;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin: 2vh 3vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
                height: fit-content;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }
            }
          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake21 {
      padding: 7.78739184177998vh 13.097631vw 4.78739184177998vh 13.097631vw;
      width: 100%;
      height: fit-content;
      background: transparent;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 1vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 15vh;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              height: 5vh;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 0 0 1.5vw;
              color: black;
              width: 85%;
              float: right;

              .title1 {
                margin-top: 0;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake7 {
      padding: 7.78739184177998vh 13.097631vw 4.78739184177998vh 13.097631vw;
      width: 100%;
      //height: 78vh;
      //height: 50vh;
      height: fit-content;
      background: transparent;
      //background-image: url(@/assets/guanwang100.png);
      //background-image: url("../../assets/guanwang100.png");
      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;
      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }

      .grid {
        position: relative;
        //display: grid;
        //grid-template-columns:  minmax(0, 1fr);
        //column-gap: 1vh;
        //row-gap: 1vh;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          //padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: rgba(0, 0, 0, 0);
            border-radius: 9px;
            position: relative;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              height: fit-content;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0.5vh auto;
              color: black;
              width: 100%;
              float: right;

              .title1 {
                margin: 0 1vh;
                text-align: left;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin: 2vh 0;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
                text-align: left;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake3 {
      padding: 0vh 7vw;
      width: 100%;
      height: 78vh;
      //height: 50vh;
      //height: fit-content;
      background: transparent;
      //background-image: url(@/assets/guanwang100.png);
      //background-image: url("../../assets/guanwang100.png");
      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }

      .grid {
        margin-top: 5.787392vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr);
        grid-template-rows: 9vh 14vh 14vh 14vh;
        column-gap: 2vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: rgba(0, 0, 0, 0);
            border-radius: 9px;
            position: relative;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              height: fit-content;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 3vh auto;
              color: black;
              height: 20vh;
              width: 100%;
              float: right;

              .title1 {
                margin: 0 auto;
                text-align: left;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin: 2vh 0;
                width: 69vh;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake4 {
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        color: white;
        font-weight: 600;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //float: right;
          //cursor: pointer;
          width: 90%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 50vh;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: 50%;
              height: 100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw;
              margin: auto;
              text-align: center;
              color: white;
              width: 45%;
              float: right;

              .title1 {
                margin: 0 auto;
                text-align: left;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 52vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }
  }

  .modal-overlay {
    z-index: 999999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  }

  .large-image {
    max-width: 100%;
    max-height: 80vh;
  }
}

.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .screen1 {
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 650px;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      background-image: url("../../assets/bannerModel.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 80px;
        left: 9.17157712305026vw;

        .title1 {
          width:120px;
          font-size: 3.50606585788562vw;
          font-family: Instrument Sans,Arial;
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin-top: 10px;
          width: 95%;
          height: fit-content;
          font-size: 14px;
          font-family: Instrument Sans,Arial;
          font-weight: 400;
          color: #4B5A8B;
          line-height: 20px;
        }



      }
      .title4{
        position: absolute;
        bottom: 100px;
        left: 50%;
        transform: translateX(-50%);
        height: fit-content;
        font-weight: 400;
        width: 100%;
        font-size: 16px;
        color: #2f6dfd;
        line-height: 20px;
        text-align: center; /* 用于内部文本的水平居中 */
      }
      .titlle3{
        position: absolute;
        bottom:30px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }
      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenmake0{
      //z-index:: 10;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw  0  4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        padding:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Instrument Sans,Arial;
        color: #2C3E50;
        line-height: 20px;
        font-weight: bold;
      }

    }
    .screenmakenew{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: transparent;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Instrument Sans,Arial;
        font-weight: bold;
        color: #2C3E50;
        line-height: 25px;
      }

      .grid{
        position: relative;
        height: fit-content;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;

          opacity: 1;
          .title1{
            margin:0 auto;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            margin: 15px 0 0 10px;
            width: 80%;
          }
          .title3{
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0,1fr);
            line-height: 22px;
            font-size: 14px;
          }
          .back{
            margin: 15px 0;
            padding:0 15px 10px 15px ;
            background: white;
            border-radius: 9px;
            position: relative;
            //height:580px ;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              opacity: 1;
              margin: auto;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
      .grid1{
        position: relative;
        height: fit-content;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;
            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
          }
          .back{
            background: white;
            padding: 10px 10px;
            border-radius: 9px;
            position: relative;
            height:fit-content;
            overflow:hidden;
            .imgDesign{
              width: 80px;
              margin: 0 10px 0 auto;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake8{
      width: 100vw;
      height: fit-content;
      padding:20px  4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1{

      width: 100vw;
      height: fit-content;
      padding:0 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Instrument Sans,Arial;
        font-weight: bold;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.4vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            margin-top: 20px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            height:fit-content ;
            margin-top: 15px;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              //border: #666666 1px solid;
              height:100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake2{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: transparent;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Instrument Sans,Arial;
        font-weight: bold;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 15px;
        grid-template-columns:  minmax(0,1fr);
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.4vw 0vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 15px;
            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;
            display: grid;

          }
          .titleDesign{
            padding: 10px 10px 10px 10px ;
            margin-top: 5px;
          }
          .back{
            background: white;
            border-radius: 9px;
            padding: 10px 10px;
            position: relative;
            height:fit-content;
            margin-top: 10px;
            overflow:hidden;
            .imgDesign{
              width: 85px;
              height:85px;
              margin: 0 auto 0px auto;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake3{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Instrument Sans,Arial;
        font-weight: bold;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.4vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            text-align: center;
            font-size: 16px;
            line-height: 22px;
            font-weight: bold;
          }
          .title2{
            margin-top: 10px;
            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0,1fr);
            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            padding: 15px 10px;
          }
          .back{
            background: #ebf1fd;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            height:fit-content;
            margin-top: 15px;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              height:100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake4{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding:20px 4.6875vw;
      background: black;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:15px ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
          cursor: pointer;
          width: 98%;
          margin:15px auto;
          min-height: 250px ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 440px ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 15px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:15px;
                font-size: 16px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:14px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 400px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -78px -10px  ;
          cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
