<template>

  <div>
    <div v-if="model===false">


      <div class="total">
        <div class="screen1">
           <kefu @toptop="topTop"></kefu>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->     <headtip :changeVisable="changeVisable"  @showDialog="showPartner"></headtip>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->

          <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
          <div class="layer2">
            <div class="title1">智能油田管理 GPTs</div>
            <div class="title2">根据场站功能，生成场站设备、设施、管网及其工艺设计参数，并根据设计人员要求不断优化，生产三维设计图，根据最终设计，预估工程周期，生成施工周期计划，指导建设过程</div>
            <div class="title4">*油气人工智能模型的泛化能力有限，实际应用中可能需根据具体场景和需求进行适配和优化。如您有合作意向或Product Inquiry，请随时与我们联系。</div>
            <div class="titlle3"   @click="clickConsult('Oil & Gas Generic AI')">
              Product Inquiry
            </div>
          </div>
          <!--      平台大图-->
          <div class="layer3"></div>
        </div>
        <div class="screenmake0">
          <div class="heighLight">智能油田模型架构</div>
          <div class="heightLight2">以数据为中心，通过“数据感知采集”、“信息传输”、“生产工艺脑”、“执行器”等单元，实现油气生产全流程智能化控制闭环。通过生产解释脑，形成知识库，指导专家决策授权，并结合人工实现生产操作纠偏</div>
          <div class="grid">
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="screenmake2">
          <div class="heighLight">   <span>智能油田管理GPTs</span></div>
          <div class="grid">
            <div class="card" @click="detail">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">    <span style="background-color: #FF6400;border-radius: 50%; width:1.5709416522vw;font-size:1.1vw;height:1.5709416522vw;display: flex;justify-content: center;align-items: center;color: white;margin-top: -0.1vw">1</span>&nbsp;&nbsp;&nbsp; 油气田视频监控</div>
                  <div class="Title2">
                    实现现场违章行为的实时抓取、智能识别与主动预警，还大幅节约监督人力资源，提升监管质效，有效防控安全风险，电子沙盘实施感知风险，优化了人员调配，同时客观上也提高了安全意识，规范了安全行为。
                  </div>
                  <div class="backconsult" @click="detail">
                    查看详情
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size:1.55414091470952vw;margin-left: 1vw;" class="el-icon-right"></i>
                  </div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">  <span style="background-color: #FF6400;border-radius: 50%; width:1.5709416522vw;font-size:1.1vw;height:1.5709416522vw;display: flex;justify-content: center;align-items: center;color: white;margin-top: -0.1vw">2</span>&nbsp;&nbsp;&nbsp;油气田能耗优化</div>
                  <div class="Title2">推进机采、注水、集输、电力四大生产系统节能降耗，推动提质增效，通过“关、停、并、转、减”，以及井筒工艺优化、机采井调平衡、低产井间开等措施，实现机采系统节电，实施电力系统配电减容、更换节能电机等措施，节约电能消耗。</div>
                </div>
              </div>


            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">     <span style="background-color: #FF6400;border-radius: 50%; width:1.5709416522vw;font-size:1.1vw;height:1.5709416522vw;display: flex;justify-content: center;align-items: center;color: white;margin-top: -0.1vw">3</span>&nbsp;&nbsp;&nbsp;生产调度策略优化</div>
                  <div class="Title2">石油开采和加工的过程比较复杂，工序繁多，涉及的范围广，油田企业日常生产过程需要有计划的安排，这就需要发挥油田利用AI技术实现智能运行调度的作用，规划好油田企业的各项工作，保证企业生产和施工有序、高效的进行。

                  </div>
                </div>
              </div>


            </div>
          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight">Successful Cases</div>
          <div class="grid">
            <el-carousel indicator-position="outside">
              <el-carousel-item v-for="item in 1" :key="item">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img src="@/assets/gpt56.png">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">XX石油天然气脱烃增压智慧场站项目</div>
                      <div class="title1">设备检测，精准实时：</div>
                      <div class="title2">
                        根据智能化目标需求，配套高效新技术新产品，通过现场集成应用，全天候实时监测设备运行状况。
                      </div>
                      <div class="title1">方案成果：</div>
                      <div class="title2">
                        <li>降低维护成本: 及时发现潜在故障和问题，使维护更加预防性和精确，降低维护成本；</li>
                        <li style="margin-top: 2vh">增强设备可靠性: 减少设备故障和停机时间，提高设备的寿命；</li>
                        <li style="margin-top: 2vh">优化资源利用: 实时监测和数据分析帮助更好地利用资源，包括能源、原材料和人力资源，降低成本。</li>
                      </div>
                    </div>
                  </div>

                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <bottomTip></bottomTip>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
               <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>


      <div class="totalModel" ref="totalModel">
          <div class="screen1">
          <kefuMobile @topMobile="topTopmobile"></kefuMobile>
          <headtip @showDialog="showPartner"></headtip>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->

          <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
          <div class="layer2">
            <div class="title1">智能油田管理 GPTs</div>
            <div class="title2">根据场站功能，生成场站设备、设施、管网及其工艺设计参数，并根据设计人员要求不断优化，生产三维设计图，根据最终设计，预估工程周期，生成施工周期计划，指导建设过程</div>

          </div>

          <div class="titlle3"   @click="clickConsult('Oil & Gas Generic AI')">
            Product Inquiry
          </div>
          <div class="title4">*油气人工智能模型的泛化能力有限，实际应用中可能需根据具体场景和需求进行适配和优化。如您有合作意向或Product Inquiry，请随时与我们联系。</div>
          <!--      平台大图-->
          <div class="layer3"></div>
        </div>
        <div class="screenmake0">
          <div class="heighLight">智能油田模型架构</div>
          <div class="heightLight2">以数据为中心，通过“数据感知采集”、“信息传输”、“生产工艺脑”、“执行器”等单元，实现油气生产全流程智能化控制闭环。通过生产解释脑，形成知识库，指导专家决策授权，并结合人工实现生产操作纠偏。</div>
          <div class="grid">
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/gptModel52.png">
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="screenmake2">
          <div class="heighLight">   <span>智能油田管理GPTs</span></div>
          <div class="grid">
            <div class="card" @click="detail">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">     <span style="background-color: #FF6400;border-radius: 50%; width:16px;font-size:16px;height:16px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 4px">1</span>&nbsp; 油气田视频监控</div>
                  <div class="Title2">
                    实时抓取违章行为，智能识别主动预警，节约人力提升质效，防控风险优化调配，提高安全意识，规范安全行为。
                  </div>
                  <div class="backconsult" @click="detail">
                    查看详情
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size:14px;margin-left: 15px;" class="el-icon-right"></i>
                  </div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">   <span style="background-color: #FF6400;border-radius: 50%; width:16px;font-size:16px;height:16px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 4px">2</span>&nbsp;油气田能耗优化</div>
                  <div class="Title2">推进机采、注水、集输、电力四大生产系统节能降耗，推动提质增效。通过“关、停、并、转、减”，以及井筒工艺优化、机采井调平衡、低产井间开等措施，实现机采系统节电，实施电力系统配电减容、更换节能电机等措施，节约电能消耗。</div>
                </div>
              </div>


            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">     <span style="background-color: #FF6400;border-radius: 50%; width:16px;font-size:16px;height:16px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 4px">3</span>&nbsp;生产调度策略优化</div>
                  <div class="Title2">石油开采和加工的过程比较复杂，工序繁多，涉及的范围广，油田企业日常生产过程需要有计划的安排，这就需要发挥油田利用AI技术实现智能运行调度的作用，规划好油田企业的各项工作，保证企业生产和施工有序、高效的进行。

                  </div>
                </div>
              </div>


            </div>
          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight">Successful Cases</div>
          <div class="grid">
            <el-carousel indicator-position="outside">
              <el-carousel-item v-for="item in 1" :key="item">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img src="@/assets/gpt56.png">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">XX石油天然气脱烃增压智慧场站项目</div>
                      <div class="title1">方案成果：</div>
                      <div class="title2">
                        <li>降低维护成本: 及时发现潜在故障和问题，使维护更加预防性和精确，降低维护成本；</li>
                        <li style="margin-top: 2vh">增强设备可靠性: 减少设备故障和停机时间，提高设备的寿命；</li>
                        <li style="margin-top: 2vh">优化资源利用: 实时监测和数据分析帮助更好地利用资源，包括能源、原材料和人力资源，降低成本。</li>
                      </div>
                    </div>
                  </div>

                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <bottomTip></bottomTip>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
               <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>


    </div>
  </div>


</template>


<script>

import headtip from "@/views/headTip.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import PartnerTip from "@/components/partnerTip.vue";
import consultTip from "@/components/consultTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import kefu from "@/views/kefu.vue";
export default {

  name: "",

  props: [],

  components: {bottomTip, consultTip, PartnerTip, headtip,kefu,kefuMobile},

  data() {

  return {changeVisable:1,
      title:'', model:false,
      dialogconsult:false,
      dialogVisible:false,
    }

  },

  methods: {
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      const element = this.$refs.totalModel;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    clickConsult(item){

      console.log(item)
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    detail(){
      window.open("/monitor", "_blank");
    },
    showPartner() {
      this.dialogconsult = true;
    },

    hideDialog() {
      this.dialogVisible = false;
    },
    hide() {
      this.dialogconsult = false;
    },
  },

  mounted() {

    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
  }

}

</script>


<style type="text/scss" lang="scss" scoped>
.total {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 74vh;
    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
    background-image: url("../../assets/gpt51.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */

    .layer2 {
      position: absolute;
      top: 12.1495673671199vh;
      left: 9.17157712305026vw;

      .title1 {
        width: fit-content;
        height: 8.77626699629172vh;
        font-size: 3.50606585788562vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2f6eff;
        line-height: 12.23733003708282vh;
      }

      .title2 {
        margin-top: 4.82076637824475vh;
        width: 33.45869439630272vw;
        height:fit-content;
        font-size: 1.35979202772964vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2f6eff;
        line-height: 5.31520395550062vh;
      }
      .title4 {
        margin-top: 2.82076637824475vh;
        width: 33.45869439630272vw;
        font-size: 1vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        line-height: 3.31520395550062vh;
      }
      .titlle3{
        margin-top: 5.07911001236094vh;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.27vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        border-radius: 53px;
        width: fit-content;
        padding: 2.8vh 1.5989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }

    //.layer3 {
    //  position: absolute;
    //  top: 5.06798516687268vh;
    //  right: 11.61178509532062vw;
    //  width: 47.42923165800116vw;
    //  height: 72.80593325092707vh;
    //  background-image: url("../assets/GPTplant.png");
    //  background-size: 100% auto;
    //  background-position: center;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  opacity: 1;
    //}
  }
  .screenmake0 {
    padding: 7.78739184177998vh 11.09763142692085vw;
    width: 100%;
    height: fit-content;
    background: white;

    .heighLight {
      margin: 0 auto;
      text-align: center;
      height:8.23733003708282vh;
      opacity: 1;
      font-size: 1.7vw;
      //color: #53A3F7;
      font-family: Instrument Sans,Arial;
      line-height: 4.23733003708282vh;

    }

    .heightLight2 {
      margin:0 auto;
      text-align: center;
      height: 13.78739184177998vh;
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;

      width: 60.9693818602vw;
      font-size: 1vw;
      font-family: Instrument Sans,Arial;
    }
    .grid {
      //margin-top: 3.78739184177998vh;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 1vw;

      .card1 {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;

        .back {
          border-radius: 5px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height: fit-content;
          overflow: hidden;
          background: #FFFFFF;
          margin-bottom: 1.5248454883vh;

          .imgDesign {
            text-align: center;
            height: 69vh;
            width: 100%;
            background-image: url("../../assets/gpt52.jpg");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign {
            padding: 1.5248454883vh 1.5248454883vw;
            //background-color: #2F6EFF;
            .Title1 {
              color: black;
              text-align: center;
              font-size: 1vw;

               font-family: Instrument Sans,Arial;
              font-weight: 700;
            }

          }



        }


      }

      .card2 {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;

        .back {
          border-radius: 5px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height: fit-content;
          overflow: hidden;
          background: #FFFFFF;
          margin-bottom: 1.5248454883vh;

          .imgDesign {
            text-align: center;
            height: 48vh;
            width: 100%;
            background-image: url("../../assets/frack02.png");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign {
            padding: 1.5248454883vh 1.5248454883vw;
            //background-color: #2F6EFF;
            .Title1 {
              color: black;
              text-align: center;
              font-size: 1vw;

               font-family: Instrument Sans,Arial;
              font-weight: 700;
            }

          }


        }

        .back:hover {
          transform: scale(1.05);
        }
      }
    }
  }
  .screenmake2{
    padding: 7.78739184177998vh 11.09763142692085vw;
    width: 100%;width: 100%;
    height: fit-content;
    background: #ebf1fd;
    .heighLight{
      display: flex;
      justify-content: center;
      text-align: center;
      height:8.23733003708282vh;
      font-size: 1.7vw;
      font-family: Instrument Sans,Arial;
      font-weight: 400;
      color: #FF6400;
      line-height: 4.23733003708282vh;
    }

    .grid{
      margin-top:2.78739184177998vh ;
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
      column-gap:0.81317157712305vw ;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:62.87144622991347vh ;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 25.69962917181706vh;
            opacity: 1;
            background-image: url("../../assets/gpt53.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 30vh;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 2.7194066749vh;
              font-size: 1vw;
               font-family: Instrument Sans,Arial;
              font-weight: 700;
              color:#FF6400;
              line-height: 2.7194066749vh;
            }
            .Title2{

              margin: 3vh 1.8597920277vw auto 1.8597920277vw;
              //text-align: justify;
              height: 4.6971569839vh;
              font-size: 0.8243212016vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              line-height: 3.194066749vh;
            }
            .backconsult{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);

              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
        .back:hover{
          .imgDesign {
            transform: scale(1.05);
          }

        }}

      .card1{
        border-radius: 16px;
        //cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:62.87144622991347vh ;
          overflow:hidden;
          background:white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 25.69962917181706vh;
            opacity: 1;
            background-image: url("../../assets/gpt54.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 30vh;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 2.7194066749vh;
              font-size: 1vw;
               font-family: Instrument Sans,Arial;
              font-weight: 700;
              color:#FF6400;
              line-height: 2.7194066749vh;
            }
            .Title2{

              margin: 3vh 1.8597920277vw auto 1.8597920277vw;
              //text-align: justify;
              height: 4.6971569839vh;
              font-size: 0.8243212016vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              line-height: 3.194066749vh;
            }
          }
        }
      }
      .card1:hover{
        .imgDesign {
          transform: scale(1.05);
        }
      }
      .card2{
        border-radius: 16px;
        //cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:62.87144622991347vh ;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 25.69962917181706vh;
            opacity: 1;
            background-image: url("../../assets/gpt55.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 30vh;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 2.7194066749vh;
              font-size: 1vw;
               font-family: Instrument Sans,Arial;
              font-weight: 700;
              color:#FF6400;
              line-height: 2.7194066749vh;
            }
            .Title2{

              margin: 3vh 1.8597920277vw auto 1.8597920277vw;
              //text-align: justify;
              height: 4.6971569839vh;
              font-size: 0.8243212016vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              line-height: 3.194066749vh;
            }
          }
        }
      }
      .card2:hover{
        .imgDesign {
          transform: scale(1.05);
        }
      }
    }
  }
  .screenmake3{
    padding: 7.78739184177998vh 11.09763142692085vw;
    width: 100%;
    height: fit-content;
    background: #212a3c;
    .heighLight{
      color:white;
      text-align: center;
      height: 7.54017305315204vh;
      font-size: 1.7vw;
      font-family: Instrument Sans,Arial;
      font-weight: 400;
      line-height: 4.23733003708282vh;
    }

    .grid{
      margin-top:2.78739184177998vh ;
      .card{
        //cursor: pointer;
        width: 100%;
        height:65.87144622991347vh ;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 5px;
          display: flex;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          height:65.87144622991347vh ;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: 60.60311958405546vw;
            height:100%;
            opacity: 1;
            background-image: url("../../assets/menage6.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 1.5248454883vh 1.5248454883vw;
            color: white;
            width: 35%;
            float: right;
            .title1{
              margin-top: 2vh;
              font-size: 1vw;
            }
            .title2{
              margin-top: 3vh;
              line-height: 3.194066749vh;
              font-size: 0.8243212016vw;
            }
            .success{
              display: grid;
              grid-template-columns: minmax(0,1fr) minmax(0,1fr);
              column-gap: 1vw;
              margin-top: 3vh;
              .suc1{

                width: 100%;
                height: 8vh;
                .title3{
                  text-align: center;
                  margin-top: 2vh;
                  font-size: 0.8243212016vw;
                }

              }

            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height:65.87268232385661vh !important;
      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }
      ::v-deep .el-carousel__container {
        position: relative;
        height: 48.83559950556242vh;
      }
      ::v-deep .el-carousel__arrow {
        display: none;
        border: none;
        outline: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 0;
        width: 1.15540150202195vw;
        height: 4.32632880098888vh;
        //background: #2168DB;
        color: #fff;
        position: absolute;
        top: 50%;
        z-index: 10;
        transform: translateY(-50%);
        text-align: center;
        font-size: 12px;
      }
    }
  }
}
.totalModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 460px;
    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
    background-image: url("../../assets/gptModel51.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */
    .titlle3{
      position: absolute;
      bottom:50px;
      left:50%;
      transform: translateX(-60%);
      font-size: 17px;
       font-family: Instrument Sans,Arial;
      font-weight: 400;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 53px;
      width: fit-content;
      margin: 0 15px;
      padding: 10px 25px;
      height: 40px;
      color:white;
      background-color: #2f6eff;
      border: 1px solid #2f6eff;
      overflow: hidden;

    }
    .title4{
      position: absolute;
      left:10%;
      bottom:25px;
      margin: 35px auto 0 auto;
      width: 80%;
      height: 17px;
      font-size: 10px;
      font-family: Instrument Sans,Arial;
      font-weight: 400;
      color: white;
      line-height: 16px;
    }
    .layer2 {
      position: absolute;
      left:0;

      .title1 {
        margin:0 auto;
        width: fit-content;
        text-align: center;
        height: 23px;
        font-size: 23px;
        font-family: Instrument Sans,Arial;
        font-weight: 500;
        color: #111111;
      }

      .title2 {
        margin: 35px auto;
        width: 90%;
        text-align: center;
        height: 17px;
        font-size: 14px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #111111;
        line-height: 24px;
      }
    }

  }
  .screenmake0 {
    padding: 20px 4.6875vw;
    width: 100%;
    height: fit-content;
    background: white;

    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      font-family: Instrument Sans,Arial;
      font-weight: 700;
      line-height: 26px;

    }

    .heightLight2 {
      margin:19px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: Instrument Sans,Arial;
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .grid {

      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 1vw;

      .card1 {
        //cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;

        .back {
          border-radius: 5px;
          position: relative;
          height: fit-content;
          overflow: hidden;
          margin-bottom: 1.5248454883vh;

          .imgDesign {
            text-align: center;
            width: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign {
            padding: 1.5248454883vh 1.5248454883vw;
            //background-color: #2F6EFF;
            .Title1 {
              color: black;
              text-align: center;
              font-size: 1vw;

               font-family: Instrument Sans,Arial;
              font-weight: 700;
            }

          }

          .backconsult{
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: Instrument Sans,Arial;
            font-weight: 400;
            color: #2168DB;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 50%;
            transform: translateX(-50%);

            color: #2168DB;
          }
          .backconsultArrow{
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: Instrument Sans,Arial;
            font-weight: 400;
            color: #2168DB;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 80%;
            transform: translateX(-50%);
          }

        }


      }

    }
  }
  .screenmake2{
    padding: 20px 15px;
    width: 100%;
    height: fit-content;
    background: #ebf1fd;
    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      font-family: Instrument Sans,Arial;
      font-weight: 700;
      line-height: 26px;

    }




    .grid{
      margin-top:2.78739184177998vh ;
      display: grid;
      grid-template-columns: minmax(0,1fr);
      column-gap:0 ;
      row-gap: 15px;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 165px;
            opacity: 1;
            background-image: url("../../assets/gpt53.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 175px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
               font-family: Instrument Sans,Arial;
              font-weight: 700;
              color:#FF6400;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 45px 12px;
              //text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card2{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 170px;
            opacity: 1;
            background-image: url("../../assets/gpt55.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 180px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
               font-family: Instrument Sans,Arial;
              font-weight: 700;
              color:#FF6400;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 15px 12px;
              //text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card1{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            height: 185px;
            opacity: 1;
            background-image: url("../../assets/gptModel53.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 195px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
               font-family: Instrument Sans,Arial;
              font-weight: 700;
              color:#FF6400;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 15px 12px;
              //text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  .screenmake3{
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;

    padding:20px 15px;
    background: black;
    .heighLight{
      text-align: center;
      height:21px;
      opacity: 1;
      font-size: 21px;
      color: white;
      font-family: Instrument Sans,Arial;
      font-weight: 700;
      line-height: 26px;
    }
    .heightLight2{
      margin:19px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: Instrument Sans,Arial;
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }
    .grid{
      margin-top:2.78739184177998vh ;
      position: relative;

      .card{
        cursor: pointer;
        width: 98%;
        margin:0 auto;
        min-height: 250px ;
        padding:0 0.69324090121317vw 0 0;
        opacity: 1;
        background: #19202e;
        .back{
          border-radius: 5px;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          min-height: 360px ;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: 100%;
            height:234px;
            opacity: 1;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 15px 20px 25px 20px;
            color: white;
            width:100%;
            float: right;
            .title1{
              margin-top:15px;
              font-size: 16px;
            }
            .title2{
              margin-top: 15px;
              line-height: 22px;
              font-size:14px;
              ::v-deep li{
                margin-top:5px;
              }
            ;
            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height: 520px !important;

      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }

      ::v-deep .el-carousel__arrow {
        display: block;
        //border: 1px solid white;
        outline: -5px;
        padding: 0;
        margin: -68px -10px  ;
        cursor: pointer;

        border-radius: 0;
        width: 25px;
        height: 48px;
        background: rgba(245, 246, 250, 0.8);
        color: #131313;
        position: absolute;
        top: 50%;
        z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
</style>
