<template>
  <div>
    <div v-sliden-in style="height: 40vh; width: 50vw; background-color: red; margin: 5vh;">
      <!-- 这是需要应用滑入效果的内容 -->
    </div>
    <div v-sliden-in style="height: 40vh; width: 50vw; background-color: red; margin: 5vh;">
      <!-- 这是需要应用滑入效果的内容 -->
    </div>
    <div v-sliden-in style="height: 40vh; width: 50vw; background-color: red; margin: 5vh;">
      <!-- 这是需要应用滑入效果的内容 -->
    </div>
    <div v-sliden-in style="height: 40vh; width: 50vw; background-color: red; margin: 5vh;">
      <!-- 这是需要应用滑入效果的内容 -->
    </div>
    <div v-sliden-in style="height: 40vh; width: 50vw; background-color: red; margin: 5vh;">
      <!-- 这是需要应用滑入效果的内容 -->
    </div>
  </div>
</template>

<script>
import vSlidenIn from '../utils/vSlidenIn.js'; // 确保路径正确

export default {
  directives: {
    slidenIn: {
      ...vSlidenIn,
      mounted(el) {
        console.log('Directive mounted:', el); // 打印指令调用信息
        vSlidenIn.mounted(el); // 调用原指令的 mounted 方法
      }
    }
  },
};
</script>

<style scoped lang="scss">
/* 你可以在这里添加样式 */
</style>