<template>
  <div>
    <div v-if="model===false">
      <div ref="total" class="totalmakeHole">
        <div class="topback"   :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}">
          <headtip :changeVisable="changeVisable"  @showDialog="showPartner"></headtip>
        </div>
        <div class="makeHole1" @click="changeheadVisable">
          <div class="screen1">
             <kefu @toptop="topTop"></kefu>
            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1"> <img loading="lazy" style="border-radius: 9px" src="@/assets/AnxunLogo.png"></div>
              <div class="title2">Based on various application scenarios in Oil & Gas fields, Anvision provides professional accompanying services with the most reliable project landing, the fastest project cycle, and the lowest cost to meet the rigid demand for Oil & Gas field digital intelligence. Our services include comprehensive solutions for smart oilfields and digital intelligence services for all Oil & Gas field scenes.</div>
              <div class="title4">Oil & Gas Digitalization, Simple and Practical</div>
              <div class="titlle3"   @click="clickConsult('Oil & Gas Generic AI')">
                Product Inquiry
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenmake0">
            <div class="heighLight">Smart Training and Emergency Simulation Platform</div>
            <div class="heightLight2" style="width: 155vh">
              The smart training and emergency simulation platform is designed to provide customers with a comprehensive, flexible and safe training environment through advanced <span style="color: rgb(255, 100, 0)">virtual reality (VR), artificial intelligence and data modeling technologies </span>. This solution is suitable for dealing with complex process flows and emergency situations, helping customers effectively improve employees' safety awareness and crisis handling capabilities.
            </div>
          </div>
          <div class="screenmake8" style="background-color: transparent">
            <div class="heighLight" style="font-size: 1.7vw">   Business Pain Points and Challenges</div>
            <div class="line"></div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign" style="width: fit-content;margin:0 auto 0 auto;height: 5vh">
                    <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/traning01.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">
                      Difficulty in Practical Drills
                    </div>
                    <div class="title2">
                      Traditional training methods struggle to simulate real and complex scenarios, leading to employees lacking practical operational experience in emergency situations.
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign" style="width: fit-content;margin:0 auto 0 auto;height: 5vh">
                    <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/traning02.png">

                  </div>
                  <div class="titleDesign">
                    <div class="title1">High Training Costs
                    </div>
                    <div class="title2">
                      Organizing offline emergency drills requires significant human and material resources, and they cannot be conducted frequently, resulting in a waste of resources.
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign" style="width: fit-content;margin:0 auto 0 auto;height: 5vh">
                    <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/traning03.png">

                  </div>
                  <div class="titleDesign">
                    <div class="title1">Difficulty in Quantifying Training Effects
                    </div>
                    <div class="title2">
                      The lack of effective evaluation tools and methods makes it impossible to accurately measure the effectiveness of training, making it challenging to ensure the quality of training.
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div class="screenmake1">
            <div class="heighLight"> Solution Architecture</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <el-tooltip class="item" content="Click to enlarge for a clearer view!" effect="dark" placement="top">
                      <img loading="lazy" src="@/assets/training1.png"
                           style="border-radius: 9px" @click="showModalTrue(require('@/assets/training1.png'))">
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="screenmake13">
            <div class="heighLight">Core Features</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/meiti2.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Process and Emergency Simulation Modeling
                    </div>
                    <div class="title2">
                      <li>Accurate simulation and risk control: Precisely simulate key process parameters and safely model potential accidents.</li>
                      <li>Process optimization and decision support: Quickly evaluate and optimize process flow for efficient, safe decision-making.</li>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back" >
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/equipment4new.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Three-dimensional Inspection and Intelligent Analysis
                    </div>
                    <div class="title2">
                      <li>Visualization and interactivity: 3D equipment display, flexible model operation, and enhanced inspection efficiency.</li>
                      <li>Intelligent analysis and real-time updates: Integrate IoT data for real-time problem analysis and improved security.</li>

                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/equipment5new.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Team Interaction and Resource Optimization
                    </div>
                    <div class="title2">
                      <li>Multi-role immersive collaboration: Enable virtual training, real-time collaboration, and improved learning outcomes.</li>
                      <li>Real-time interaction and decision-making training: Facilitate real-time communication, simulate scenarios, and enhance emergency decision-making.</li>
                      <li>Resource scheduling optimization: Simulate emergencies, optimize resource allocation, and improve cost-efficiency.</li>
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/meiti3.jpg">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Intelligent Miniature Full-Terminal Training Platform
                    </div>
                    <div class="title2">
                      <li>Device compatibility and customization: Supports AR, VR, MR devices, blends virtual and real, offers personalized content, and broad applicability.</li>
                      <li>Security and interactivity: Ensure data security across devices with interactive tests for enhanced engagement.</li>
                      <li>Flexible expansion: Easily adaptable for various training scenarios, reducing costs and improving efficiency.</li>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake21">
            <div class="heighLight"> Technical Features</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign" style="width: 3vw;height: 7vh">
                    <img loading="lazy"  style="border-radius: 9px" src="../../assets/guanwang1.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Modular Design
                    </div>
                    <div class="title2">
                      Highly scalable, easy to maintain, and adaptable to changing training needs.
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign" style="width: 5vh;height: 6vh;">
                    <img loading="lazy"  style="border-radius: 9px" src="../../assets/setingu6.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Real-time Feedback
                    </div>
                    <div class="title2">
                      Monitors and quantifies training, optimizing content with personalized suggestions.
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back" >
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px;margin-top: 2.5px" src="../../assets/basic03.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Immersive Experience
                    </div>
                    <div class="title2">
                      Uses VR and 3D tech to replicate environments, enhancing response capabilities.
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back" style="">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="../../assets/basic02.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Intelligent Analysis
                    </div>
                    <div class="title2">
                      Integrates IoT data for real-time analysis, identifying issues to improve security.
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
          <div class="screenmake3">
            <div class="heighLight">
              Customer Values
            </div>
            <div style="display: flex;">
              <div class="fanganimg" style="float: left;width:57% ">
                <img src="@/assets/anxun21new2.png" style="    height: 77%;
    width: 93%;
    margin:14vh auto;">
              </div>
              <div style="display: contents">

                <div class="grid" style="">
                  <div class="card">
                    <div class="back">

                    </div>
                  </div>
                  <div class="card">
                    <div class="back">
                      <div class="titleDesign">
                        <div class="title1">
                          Reduce Training Costs
                        </div>
                        <div class="title2">
                          Effectively reduce equipment purchase and maintenance costs, and eliminate field operation safety risks.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="back">
                      <div class="titleDesign">
                        <div class="title1">Enhance Emergency Response Capabilities
                        </div>
                        <div class="title2">
                          Improve employees’ emergency response capabilities through realistic scenario simulation and interactive training.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="back">
                      <div class="titleDesign">
                        <div class="title1"> Flexibility to Respond to Challenges
                        </div>
                        <div class="title2">
                          Modular design and real-time updates help enterprises quickly adapt to changing security needs.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="back">
                      <div class="titleDesign">
                        <div class="title1"> Safe and Efficient Training
                        </div>
                        <div class="title2">
                          Provide a low-risk, controllable training platform to ensure safe and efficient operation training.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <!--          <div class="screenmake4">-->
            <!--            <div class="heighLight">成功案例</div>-->


            <!--            <div class="grid" style="margin-top: -0.212608vh;">-->
            <!--              <el-carousel arrow="always" indicator-position="outside" interval="5000">-->
            <!--                <el-carousel-item v-for="item in success" :key="item">-->
            <!--                  <div class="card">-->
            <!--                    <div class="back">-->
            <!--                      <div class="imgDesign">-->
            <!--                        <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">-->
            <!--                          <img :src="item.img" loading="lazy" @click="showModalTrue(item.img)"/>-->
            <!--                        </el-tooltip>-->
            <!--                      </div>-->
            <!--                      <div class="titleDesign">-->
            <!--                        <div class="title1">{{ item.title1 }}</div>-->
            <!--                        <div class="title2" v-html="item.title2"></div>-->
            <!--                      </div>-->
            <!--                    </div>-->


            <!--                  </div>-->


            <!--                </el-carousel-item>-->
            <!--              </el-carousel>-->
            <!--            </div>-->

            <!--          </div>-->
          </div>

          <bottomTip></bottomTip>
        </div>
        <div v-if="showModal" class="modal-overlay" @click="closeModal">
          <div class="modal-content">
            <img :src="largeSrc" alt="Large Image" class="large-image">
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <headtip @showDialog="showPartner"></headtip>
            <kefuMobile @topMobile="topTopmobile"></kefuMobile>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1"> <img loading="lazy" style="border-radius: 9px" src="@/assets/AnxunLogo.png"></div>
              <div class="title2">Based on various application scenarios in Oil & Gas fields, Anvision provides professional accompanying services with the most reliable project landing, the fastest project cycle, and the lowest cost to meet the rigid demand for Oil & Gas field digital intelligence. Our services include comprehensive solutions for smart oilfields and digital intelligence services for all Oil & Gas field scenes.</div>

            </div>
            <div class="title4">Oil & Gas Digitalization, Simple and Practical</div>

            <div class="titlle3"   @click="clickConsult('Oil & Gas Generic AI')">
              Product Inquiry
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenmake0">
            <div class="heighLight">Smart Training and Emergency Simulation Platform</div>
            <div class="heightLight2">The smart training and emergency simulation platform is designed to provide customers with a comprehensive, flexible and safe training environment through advanced <span style="color: rgb(255, 100, 0)"> virtual reality (VR), artificial intelligence and data modeling technologies </span>. This solution is suitable for dealing with complex process flows and emergency situations, helping customers effectively improve employees' safety awareness and crisis handling capabilities.
            </div>
          </div>
          <div class="screenmake8" style="padding-top: 15px">
            <div class="heighLight" style="height: fit-content;"> Business Pain Points and Challenges</div>
            <div class="grid">
              <div class="card" style="margin-top: 10px">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/traning01.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Difficulty in Practical Drills
                    </div>
                    <div class="title2">
                      Traditional training methods struggle to simulate real and complex scenarios, leading to employees lacking practical operational experience in emergency situations.
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/traning02.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">High Training Costs
                    </div>
                    <div class="title2">
                      Organizing offline emergency drills requires significant human and material resources, and they cannot be conducted frequently, resulting in a waste of resources.
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/traning03.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Difficulty in Quantifying Training Effects
                    </div>
                    <div class="title2">
                      The lack of effective evaluation tools and methods makes it impossible to accurately measure the effectiveness of training, making it challenging to ensure the quality of training.
                    </div>
                  </div>
                </div>


              </div>

            </div>

          </div>
          <div class="screenmake1">
            <div class="heighLight"> Solution Architecture</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/training1.png" style="border-radius: 9px">
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake6">
            <div class="heighLight" style="">Core Features</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="titleDesign">
                    <div class="title1">
                      Process and Emergency Simulation Modeling
                    </div>
                    <div class="title2">
                      Accurate simulation and risk control: Precisely simulate key process parameters and safely model potential accidents.
                      Process optimization and decision support: Quickly evaluate and optimize process flow for efficient, safe decision-making.
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="titleDesign">
                    <div class="title1">
                      Three-dimensional Inspection and Intelligent Analysis
                    </div>
                    <div class="title2">
                      Visualization and interactivity: 3D equipment display, flexible model operation, and enhanced inspection efficiency.
                      Intelligent analysis and real-time updates: Integrate IoT data for real-time problem analysis and improved security.
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="titleDesign">
                    <div class="title1">
                      Team Interaction and Resource Optimization
                    </div>
                    <div class="title2">
                      Multi-role immersive collaboration: Enable virtual training, real-time collaboration, and improved learning outcomes.
                      Real-time interaction and decision-making training: Facilitate real-time communication, simulate scenarios, and enhance emergency decision-making.
                      Resource scheduling optimization: Simulate emergencies, optimize resource allocation, and improve cost-efficiency.
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="titleDesign">
                    <div class="title1">
                      Intelligent Miniature Full-terminal Training Platform
                    </div>
                    <div class="title2">
                      Device compatibility and customization: Supports AR, VR, MR devices, blends virtual and real, offers personalized content, and broad applicability.
                      Security and interactivity: Ensure data security across devices with interactive tests for enhanced engagement.
                      Flexible expansion: Easily adaptable for various training scenarios, reducing costs and improving efficiency.
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
          <div class="screenmake2">
            <div class="heighLight"> Technical Features</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign" style="width: 8vh;">
                    <img loading="lazy" src="@/assets/guanwang1.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Modular Design
                    </div>
                    <div class="title2">
                      Highly scalable, easy to maintain, and adaptable to changing training needs.
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/guanwang2.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Real-time Feedback
                    </div>
                    <div class="title2">
                      Monitors and quantifies training, optimizing content with personalized suggestions.
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/guanwang3.png"
                         style="border-radius: 9px;height: 40px;margin-top: 2.5px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Immersive Experience
                    </div>
                    <div class="title2">
                      Uses VR and 3D tech to replicate environments, enhancing response capabilities.
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/guanwang4.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Intelligent Analysis
                    </div>
                    <div class="title2">
                      Integrates IoT data for real-time analysis, identifying issues to improve security.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="screenmake3">
            <div class="heighLight" style="">Customer Values</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/anxun21new1.png"
                         style="border-top-right-radius: 9px;border-top-left-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">
                      Reduce Training Costs
                    </div>
                    <div class="title2">
                      effectively reduce equipment purchase and maintenance costs, and eliminate field operation safety risks.
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/anxun21new2.png"
                         style="border-top-right-radius: 9px;border-top-left-radius: 9px">

                  </div>
                  <div class="titleDesign">
                    <div class="title1">Enhance Emergency Response Capabilities
                    </div>
                    <div class="title2">
                      Improve employees’ emergency response capabilities through realistic scenario simulation and interactive training.
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/anxun21new3.png"
                         style="border-top-right-radius: 9px;border-top-left-radius: 9px">

                  </div>
                  <div class="titleDesign">
                    <div class="title1">Flexibility to Respond to Challenges
                    </div>
                    <div class="title2">
                      Modular design and real-time updates help enterprises quickly adapt to changing security needs.
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/meiti3.jpg"
                         style="border-top-right-radius: 9px;border-top-left-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1"> Safe and Efficient Training
                    </div>
                    <div class="title2">
                      Provide a low-risk, controllable training platform to ensure safe and efficient operation training.
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div>
<!--            <div class="screenmake4">-->
<!--              <div class="heighLight">成功案例</div>-->
<!--              <div class="grid">-->
<!--                <el-carousel arrow="always" indicator-position="outside" interval="5000">-->
<!--                  <el-carousel-item v-for="item in success" :key="item">-->
<!--                    <div class="card">-->
<!--                      <div class="back">-->
<!--                        <div class="imgDesign">-->
<!--                          <img :src="item.img" loading="lazy"/>-->
<!--                        </div>-->
<!--                        <div class="titleDesign">-->
<!--                          <div class="title1">{{ item.title1 }}</div>-->
<!--                          <div class="title2" v-html="item.title2"></div>-->
<!--                        </div>-->
<!--                      </div>-->


<!--                    </div>-->


<!--                  </el-carousel-item>-->
<!--                </el-carousel>-->
<!--              </div>-->

<!--            </div>-->
          </div>

          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>


</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import kefuMobile from "@/views/kefuMobile.vue";
import kefu from "@/views/kefu.vue";

export default {

  name: "",

  props: [],

  components: {PartnerTip, consultTip, headtip, bottomTip,kefu,kefuMobile},

  data() {
    return {
    changeVisable:1,
      title: "",
      showModal: false,
      largeSrc: '',
      pageTitle: 'Smart Training and Emergency Simulation Platform',
      pageDescription: 'The smart training and emergency simulation platform is designed to provide customers with a comprehensive, flexible and safe training environment through advanced <span style="color: rgb(255, 100, 0)">virtual reality (VR), artificial intelligence and data modeling technologies </span>. This solution is suitable for dealing with complex process flows and emergency situations, helping customers effectively improve employees\' safety awareness and crisis handling capabilities.',
      pageKeywords: '智慧安防，风险排查与隐患治理，消防机器人，特殊作业管控，精确人员定位平台，智能 AI 安防，飞行器反制，智能AI巡检，智慧培训与应急模拟演练，智能消防，安迅数智，油气通GPT',
      scrollback: false,
      model: false,
      dialogconsult: false,
      dialogVisible: false,
      success: [{
        img: require("@/assets/guanwang22.png"),
        title1: "智慧管网",
        title2: "<li>国内某增压脱烃站外输管网巡检项目</li>",
      },
      ]
    }

  },


  methods: {
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
       window.scrollTo({
         top: 0,
         behavior: 'smooth'
       });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
       const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    showModalTrue(item) {
      this.showModal = true;
      console.log(item)
      this.largeSrc = item
    },
    closeModal() {
      this.showModal = false;
    },
    clickConsult(item) {

      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide() {
      this.dialogconsult = false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner() {
      this.dialogconsult = true;
    },
    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 500) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  beforeDestroy() {

    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);

    if (screenWidth <= 700) {
      this.model = true
    } else {
      this.model = false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home", 0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Smart Training and Emergency Simulation Platform',
        pageDescription: 'The smart training and emergency simulation platform is designed to provide customers with a comprehensive, flexible and safe training environment through advanced <span style="color: rgb(255, 100, 0)">virtual reality (VR), artificial intelligence and data modeling technologies </span>. This solution is suitable for dealing with complex process flows and emergency situations, helping customers effectively improve employees\' safety awareness and crisis handling capabilities.',
        pageKeywords: '智慧安防，风险排查与隐患治理，消防机器人，特殊作业管控，精确人员定位平台，智能 AI 安防，飞行器反制，智能AI巡检，智慧培训与应急模拟演练，智能消防，安迅数智，油气通GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style lang="scss" scoped type="text/scss">
.totalmakeHole {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .screen1 {
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 63vh;
      padding: 7.8430160692vh 2.860774119vw 2.8430160692vh 5.860774119vw;
      background-image: url("../../assets/banner.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 9.1495673671199vh;
        left: 8.17157712305026vw;

        .title1 {
          width: fit-content;
          height: 5.77626699629172vh;
          font-size: 3.50606585788562vw;
          font-family: Instrument Sans,Arial;
          font-weight: 400;
          color: #2f6eff;
          line-height: 12.23733003708282vh;
        }

        .title2 {
          margin-top: 4.82076637824475vh;
          width: 37vw;
          //text-align: justify;
          height: fit-content;
          font-size:0.9597920277vw;
          font-family: Instrument Sans,Arial;
          font-weight: 400;
          color: black;
          line-height: 3.51520395550062vh;
        }
        .title4{
          height: 8.77626699629172vh;
          font-size: 1.3060658579vw;
          font-family: Instrument Sans,Arial;
          font-weight: 400;
          color: #2f6eff;
          line-height: 9.31520395550062vh;
        }
        .titlle3{
          margin-top: 2.07911001236094vh;
          //cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Instrument Sans,Arial;
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }

      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }

    .screenmake0{
      padding: 4.7873918418vh 8.0976314269vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-weight: 600;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: left;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        //width: 80vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }

    }
    .screenmake8{
      padding: 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }
      .line{
        height: 1vh;
        width: 75%;
        left:50%;
        transform: translateX(-50%);
        border-bottom: dashed black 1px;
        position: absolute;
        top:16.5vh;
        z-index: 1;

      }
      .grid{
        z-index: 2;
        margin-top:0vh ;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 2vw;
        .card{
          //cursor: pointer;
          width: 100%;
          height:100% ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            //background: #c9d9f5;
            border-radius: 9px;
            position: relative;
            //border: white 1px solid;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:100%;
            overflow:hidden;
            .imgDesign{
              width:8vh;
              height:8vh;
              opacity: 1;
            }
            .titleDesign{
              padding: 0;
              margin:2vh auto 0 auto;
              color: black;
              width: 100%;
              float: right;
              .title1{
                margin: 0 auto;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
                display: flex;
                height: 7vh;
                justify-content: center;
                align-items: center;
              }
              .title2{
                margin: 2vh auto 0 auto;
                width: fit-content;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1{
      padding:4.7873918418vh 8.0976314269vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-weight: 600;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            //background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content ;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              height:fit-content;
              opacity: 1;
            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake13{
      padding: 4.7873918418vh 8.0976314269vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:3.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-weight: 600;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 0;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .titleDesign{
            z-index: 999;
            margin:3vh 0 0 1.5vw;
            padding:  0 1.5vw;
            color: black;
            width: 100%;
            float: right;
            .title1{
              margin: 0 auto;
              text-align: center;
              font-size: 1vw;
              line-height: 3vh;
              font-weight: bold;
            }
            .title2{
              margin: 2vh auto;
              width: fit-content;
              //text-align: justify;
              line-height: 3vh;
              font-size: 0.8243212016vw;
            }
            .title3{
              display: grid;
              grid-template-columns: minmax(0,1fr) minmax(0,1fr);

              margin-top: 2vh;
              line-height: 3.194066749vh;
              font-size: 0.8243212016vw;
            }

          }
          .back         {
            border-radius: 9px;
            //display: flex;
            position: relative;
            border: 1px solid white;
            background-color:#ebf1fd;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height:72vh ;
            padding: 0 0 3vh 0;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              height:25vh;
            }


          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake5 {
      padding: 4.7873918418vh 8.0976314269vw;
      width: 100%;
      height: fit-content;
      background: transparent;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 0;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: white;
            border-radius: 9px;
            //display: flex;
            position: relative;
            border: 1px solid white;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 38vh;
            padding: 2vh 1.5vw 3vh 1.5vw;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              margin: 0 auto 1vh auto;
              height: 5vh;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 0 0 1.5vw;
              color: black;
              width: 100%;
              float: right;
              margin-top: 20px;

              .title1 {
                margin: 0 auto;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake2 {
      padding: 4.7873918418vh 8.0976314269vw;
      width: 100%;
      height: fit-content;
      //background: transparent;
      background: white;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 2vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #EFF5FF;
            //display: flex;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 39vh;
            overflow: hidden;

            .imgDesign {
              width: 8vh;
              height: 8vh;
              opacity: 1;
              margin: 3vh auto;
              //background-image: url("../../assets/menage6.png");
            }

            .titleDesign {
              padding: 0;
              margin: 0 0 0 1.5vw;
              color: black;
              width: 85%;
              //float: right;
              .title1 {
                text-align: center;
                margin-top: 0;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake21{
      padding: 4.7873918418vh 8.0976314269vw;

      width: 100%;
      height: fit-content;
      background: transparent;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-weight: 600;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0,1fr) minmax(0,1fr)  ;
        column-gap: 1vw;
        //padding: 0 10vh;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            //background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:15vh ;
            overflow:hidden;
            .imgDesign{
              width: 5vh;
              height:5vh;
              opacity: 1;
            }
            .titleDesign{
              padding: 0;
              margin:0 0 0 1.5vw;
              color: black;
              width: 85%;
              float: right;
              .title1{
                margin: 0 auto;
                text-align: left;
                font-size: 1vw;
                line-height: 3vh;
                font-weight: bold;
              }
              .title2{
                margin: 2vh auto;
                width: fit-content;
                //text-align: justify;
                line-height: 3vh;
                font-size: 0.8243212016vw;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake3 {
      padding: 4.7873918418vh 8.0976314269vw;
      width: 100%;
      height: fit-content;
      //height: 50vh;
      //height: fit-content;
      background: white;
      //background-image: url(@/assets/guanwang100.png);
      //background-image: url("../../assets/guanwang100.png");
      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:3.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-weight: 600;
        font-family: Instrument Sans,Arial;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Instrument Sans,Arial;
      }

      .grid {
        margin-top: 9.787392vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr);
        grid-template-rows: 9vh 14vh 14vh 10vh;
        column-gap: 2vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: rgba(0, 0, 0, 0);
            border-radius: 9px;
            position: relative;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              height: fit-content;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 3vh auto 0 auto;
              color: black;
              height: fit-content;
              width: 100%;
              float: right;

              .title1{
                margin: 0 auto;
                text-align: left;
                font-size: 1vw;
                line-height: 3vh;
                font-weight: bold;
              }
              .title2{
                margin: 2vh 0;
                width: fit-content;
                //text-align: justify;
                text-align: left;
                line-height: 3vh;
                font-size: 0.8243212016vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake4 {
      padding: 4.7873918418vh 8.0976314269vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;

      .heighLight {
        display: flex;
        justify-content: center;
        text-align: center;
        height: 8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Instrument Sans,Arial;
        font-weight: bold;
        color: white;
        line-height: 4.23733003708282vh;

      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 86%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 58vh;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw;
              margin: auto;
              text-align: center;
              color: white;
              width: fit-content;
              float: right;

              .title1 {
                text-align: left;
                font-size: 1.1709416522vw;
              }

              .title2 {
                margin-top: 3vh;
                line-height: 4vh;
                font-size: 0.9243212016vw;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 60vh !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }
  }

  .modal-overlay {
    z-index: 999999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  }

  .large-image {
    max-width: 100%;
    max-height: 80vh;
  }
}

.totalmakeHoleModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: 100vh;

    .screen1 {
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 650px;
      padding: 10px 4.6875vw 10px 4.6875vw;
      background-image: url("../../assets/bannerModel.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 80px;
        left: 9.17157712305026vw;

        .title1 {
          width:120px;
          font-size: 3.50606585788562vw;
          font-family: Instrument Sans,Arial;
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin-top: 10px;
          width: 95%;
          height: fit-content;
          font-size: 14px;
          font-family: Instrument Sans,Arial;
          font-weight: 400;
          color: #4B5A8B;
          line-height: 20px;
        }



      }
      .title4{
        position: absolute;
        bottom: 100px;
        left: 50%;
        transform: translateX(-50%);
        height: fit-content;
        font-weight: 400;
        width: 100%;
        font-size: 16px;
        color: #2f6dfd;
        line-height: 20px;
        text-align: center; /* 用于内部文本的水平居中 */
      }
      .titlle3{
        position: absolute;
        bottom:30px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }
      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }

    .screenmake0 {
      //z-index:: 10;
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding: 10px 4.6875vw 10px 4.6875vw;
      background: white;

      .heighLight{
        text-align: center;
        height:43px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2{
        padding:19px 2vw;
        text-align: left;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Instrument Sans,Arial;
        font-weight: bold;
        color: #2C3E50;
        line-height: 25px;
      }

    }
    .screenmake8{
      width: 100vw;
      height: fit-content;
      margin-top: -15px;
      padding:10px 4.6875vw 10px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            margin: 0 0 0 15px;
          }
          .back{
            background: #ebf1fd;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 0  20px 0;
            height:fit-content;
            overflow:hidden;
            .imgDesign{
              width:20%;
              height:fit-content;
              margin:10px auto 10px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1 {
      width: 100vw;
      height: fit-content;
      padding: 10px 4.6875vw 10px 4.6875vw;
      background: transparent;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        position: relative;
        height: fit-content;

        .card {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .titleDesign {
            margin-top: 20px;
          }

          .back {
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            height: fit-content;
            margin-top: 15px;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              //border: #666666 1px solid;
              height: fit-content;
              opacity: 1;

            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake2 {
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding: 10px 4.6875vw 10px 4.6875vw;
      background: transparent;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 5px;

        .card {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .title1 {
            margin: 5px auto;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
          }

          .title2 {
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }

          .title3 {
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign {
            margin-top: 10px;
          }

          .back {
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 15px 10px;
            height: 240px;
            margin-top: 5px;
            overflow: hidden;

            .imgDesign {
              width: 45px;
              height: 45px;
              margin: 0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake3 {
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding: 10px 4.6875vw 10px 4.6875vw;
      background: white;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        position: relative;
        height: fit-content;
        margin-top: 10px;

        .card {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .title1 {
            margin: 5px auto;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
          }

          .title2 {
            margin: 15px auto;
            width: 45vh;
            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign {

            margin: 10px 10px;
          }

          .back {
            margin-top: 10px;
            background-color: #ebf1fd;
            padding: 0 0 10px 0;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              height: 100%;
              opacity: 1;

            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake4 {
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding: 10px 4.6875vw 10px 4.6875vw;
      background: black;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .card {
          cursor: pointer;
          width: 98%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: fit-content;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: 100%;
              height: 194px;
              opacity: 1;

            }

            .titleDesign {
              padding: 15px 20px 25px 20px;
              color: white;
              width: 100%;
              float: right;

              .title1 {
                margin-top: 15px;
                font-size: 16px;
              }

              .title2 {
                margin-top: 15px;
                line-height: 22px;
                font-size: 14px;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 340px !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -65px -10px;
          cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }

    .screenmake6 {
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding: 10px 4.6875vw 10px 4.6875vw;
      background: white;

      .heighLight {
        text-align: center;
        //margin: 10px 50px;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Instrument Sans,Arial;
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2C3E50;
        line-height: 25px;
      }

      .grid {
        position: relative;
        height: fit-content;

        .card {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .title1 {
            margin: 5px 10px ;

            font-size: 16px;
            font-weight: bold;
            text-align: center;
          }

          .title2 {

            width: fit-content;
            height: fit-content;
            margin: 2vh;
            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign {
            margin-top: 20px;
          }

          .title3 {
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            line-height: 22px;
            font-size: 14px;
          }

          .back {
            margin-top: 15px;
            height: fit-content;
            background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            //height:580px ;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              height: 195px;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }

      .grid1 {
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 25px;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 2vw;
        row-gap: 15px;

        .card {
          border-radius: 9px;
          cursor: pointer;
          width: 100%;
          height: 185px;
          background-color: white;
          padding: 5px 10px;
          opacity: 1;

          .title1 {
            height: 45px;
            justify-content: center;
            align-items: center;
            display: flex;
            font-size: 16px;
            font-weight: bold;
          }

          .title2 {
            margin: 0 5px;
            text-align: justify;
            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign {
            margin-top: 10px;
          }

          .title3 {
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            line-height: 22px;
            font-size: 14px;
          }

          .back {
            margin-top: 15px;
            border-radius: 9px;
            position: relative;
            //height:580px ;
            overflow: hidden;

            .imgDesign {
              width: 40px;
              height: 38px;
              margin: 0 10px 0 5px;
              opacity: 1;
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }

}
</style>
