<template>

  <div>
    <div v-if="model===false">
    </div>
    <div v-else>

      <div class="totalModel" ref="totalModel">
        <div class="screen1">
          <kefuMobile @topMobile="topTopmobile"></kefuMobile>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->     <headtip :changeVisable="changeVisable"  @showDialog="showPartner"></headtip>
          <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
          <div class="layer2">
            <div class="title1">Process-related GPTs</div>
            <div class="title2">Process-related GPTs，which not only help to improve the development effect and recovery rate, but also reduce the risk loss during the development process to ensure the safe and stable development of Oil & Gas fields.</div>

          </div>

          <div class="titlle3"   @click="clickConsult('Oil & Gas Generic AI')">
            Product Inquiry
          </div>
          <div class="title4">The generalization ability of Oil & Gas AI models is limited, and may need to be adapted and optimized according to specific scenarios and needs in practical applications.
            <br>①&nbsp;Process-related services are provided by Antonoil's offline technical personnel<br>②&nbsp;GPT development services are provided by Antonoil's AI professionals</div>

          <!--      平台大图-->
          <div class="layer3"></div>
        </div>
        <!-- <div class="screenmake0">
          <div class="heighLight">智能钻井模型架构</div>
          <div class="heightLight2">以精细三维地质建模为基础，运用数字岩屑、钻时、气测等综合录井资料和地震提频、储层反演成果进行综合地质导向，开展实时调整，提高储层钻遇率。</div>
          <div class="grid">
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/gptModel22.png">
                </div>
              </div>
            </div>
          </div>

        </div> -->
        <div class="screenmake2">
          <div class="heighLight">   <span>Process-related GPTs Services</span></div>
          <div class="grid">
            <div class="card">
<!--              <div class="back">-->
              <div class="back" @click="detail">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">      Intelligent Drilling Stratum Analysis Platform</div>
                  <div class="Title2">
                    Intelligent Drilling Stratum Analysis Platform will bring significant economic and social benefits to the oil & gas
                    industry, promote the digital transformation of drilling engineering, improve engineering efficiency and management level, and contribute to the development of energy enterprises.
                       </div>
                  <div class="backconsult" @click="detail">
                    Free Experience
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size: 12px;margin-left:35px;" class="el-icon-right"></i>
                  </div>
                </div>
              </div>


            </div>
            <div class="card">
              <div class="back" @click="detail1">
                <div class="imgDesign1"></div>
                <div class="titleDesign">
                  <div class="Title1">   EUR Prediction And Parameter Reversal Platform <br> Based On Fracturing Parameters</div>
                  <div class="Title2">
                    Through advanced data analysis technology, combined with fracturing parameters, it analyzes the estimated recoverable reserves (EUR) for you. In addition, the system can also back-project key parameters based on the EUR value, enabling deep mining and correlation analysis of historical records to help you optimize reservoir management and improve recovery efficiency.
                      </div>
                  <div class="backconsult" @click="detail1">
                    Free Experience
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size: 12px;margin-left:35px;" class="el-icon-right"></i>
                  </div>
                </div>
              </div>


            </div>
            <div class="card">
              <div class="back" >
<!--              <div class="back" @click="detail2">-->
                <div class="imgDesign2"></div>
                <div class="titleDesign">
                  <div class="Title1">   Intelligent Injection And Recovery Decision-making</div>
                  <div class="Title2">
                    In order to improve the efficiency of water injection and development, the construction personnel need to combine the intelligent layered water injection system with the intelligent layered oil recovery system, through the injection and recovery linkage, real-time monitoring of intelligent layered water injection and intelligent layered oil recovery, dynamic comparison and analysis of water injection, liquid production, water content, pressure, etc., in each layer, to improve the pertinence and flexibility of the reservoir regulation and control, and to improve the implementation rate of the water injection program.
                     </div>
<!--                  <div class="backconsult" @click="detail2">-->
<!--                    Free Experience-->
<!--                  </div>-->
<!--                  <div class="backconsultArrow">-->
<!--                    <i style="font-size: 12px;margin-left:35px;" class="el-icon-right"></i>-->
<!--                  </div>-->
                </div>
              </div>


            </div>

            <div class="card1">
              <div class="back"  @click="detail4">
                <div class="imgDesign11"></div>
                <div class="titleDesign">
                  <div class="Title1">  Abnormal Formation Pressure Condition<br> Alarm Platform</div>
                  <div class="Title2">Fracturing sand plugging is a phenomenon that occurs when fracturing proppant collects and clogs the bottom of a well or a fracture, increasing the construction pressure. If sand plugging is not dealt with in a timely manner, it may lead to fracturing failure, waste of resources, additional sand flushing to unblock the well or overhauling, which increases the cost, and in serious cases, the well may be scrapped and lead to injuries or fatalities. The method is based on time loop neural network, which can handle and predict the risk of sand plugging with relatively long intervals and delays in the time series, and collects real-time pumping data from instrument trucks and fracturing design parameters through the collection terminal at the fracturing site, and transmits them to the cloud service through the network, so as to realize the monitoring of the front and back fracturing construction and the early warning of the risk of sand plugging.
                  </div>
                  <div class="backconsult" @click="detail4">
                    Free Experience
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size: 12px;margin-left:35px;" class="el-icon-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="card1">
              <div class="back" @click="detail5">
                <div class="imgDesign" style="height:140px">
                  <img src="../../assets/drilling12.png">
                </div>
                <div class="titleDesign" >
                  <div class="Title1" style="margin-top: 155px">  Drilling Parameter Optimization Platform</div>
                  <div class="Title2" >Based on the depth of the well and the performance of the drilling fluid, the recommended drilling pressure and drilling speed should ensure maximum efficiency under the premise of safety. It is recommended that the drilling pressure be adjusted according to the rock hardness to avoid excessive wear on the drill bit, while the drilling speed should be optimized according to the drillability factor of the formation and the RPM index to ensure efficient drilling and reduce energy consumption.
                  </div>
                  <div class="backconsult" @click="detail5">
                    Free Experience
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size: 12px;margin-left:35px;" class="el-icon-right"></i>
                  </div>
                </div>
              </div>


            </div>
            <div class="card2" @click="detail6">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1"> &nbsp;Intelligent Sand Plugging Early Warning Platform</div>
                  <div class="Title2">Intelligent Sand Plugging Early Warning Platform is based on real-time data collection and transmission, combined with deep learning and edge computing technology to realize dynamic monitoring and early warning of sand plugging risk. Through efficient data analysis, the system provides early warning and optimization suggestions, ensures accurate warning and timely response, and improves the efficiency and safety of sand plugging management. The solution helps the intelligent transformation of sand plugging management and provides a comprehensive solution to the sand plugging problem in oil & gas extraction.
                  </div>
                  <div class="backconsult" @click="detail6">
                    Free Experience
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size: 12px;margin-left:35px;" class="el-icon-right"></i>
                  </div>
                </div>
              </div>


            </div>
            <div class="card">
              <div class="back" >
                <!--              <div class="back" @click="detail3">-->
                <div class="imgDesign3"></div>
                <div class="titleDesign">
                  <div class="Title1"> Oil & Gas Field Video Surveillance</div>
                  <div class="Title2">
                    Realize real-time capture, intelligent identification and active warning of on-site violations, but also significantly save supervisory human resources, enhance the quality of supervision and effective prevention and control of safety risks, the implementation of the electronic sandbox to perceive the risks and optimize the deployment of personnel, while objectively improving safety awareness and standardizing safety behavior.
                  </div>
                  <!--                  <div class="backconsult" @click="detail3">-->
                  <!--                    Free Experience-->
                  <!--                  </div>-->
                  <!--                  <div class="backconsultArrow">-->
                  <!--                    <i style="font-size: 12px;margin-left:35px;" class="el-icon-right"></i>-->
                  <!--                  </div>-->
                </div>
              </div>


            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign21"></div>
                <div class="titleDesign">
                  <div class="Title1"> Artificial Lift Optimization</div>
                  <div class="Title2">The role of artificial lift is to extract Oil & Gas to the surface, which is an important part in crude oil extraction. Differences in oil wells in terms of well structure, production, and fluid properties promote the diversification of artificial lift technology. Intelligent lift optimization combined with real-time wellhead monitoring solutions can maximize well revenue by slowing down the rate of decline in oilfield production.</div>
                </div>
              </div>


            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign22"></div>
                <div class="titleDesign">
                  <div class="Title1"> Intelligent Control Of Machine Mining</div>
                  <div class="Title2">Based on IOT data, combined with big data technology, working condition diagnosis technology and machine mining efficiency optimization technology, the potential value of massive data can be deeply explored, which can realize the overall control of the operating condition and energy consumption of dilute oil machine mining wells.</div>
                </div>
              </div>


            </div>

            <div class="card2">
              <div class="back">
                <div class="imgDesign23"></div>
                <div class="titleDesign">
                  <div class="Title1"> Optimization Of Energy Consumption In <br> Oil & Gas Fields</div>
                  <div class="Title2">Promote energy saving and consumption reduction in the four major production systems, namely, machine mining, water injection, gathering and transportation, and electric power, to enhance quality and efficiency. Through "shutting down, stopping, merging, transferring and reducing", as well as measures such as wellbore process optimization, balancing of wells for mechanical extraction and opening of low-producing wells, etc., the company realizes power saving in the mechanical extraction system, and implements measures such as reducing the capacity of power distribution in the electric power system and replacing energy-saving electric motors, etc., to save electric energy consumption.
                  </div>
                </div>
              </div>


            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign24"></div>
                <div class="titleDesign">
                  <div class="Title1">  Production Scheduling Strategy Optimization</div>
                  <div class="Title2">The process of oil extraction and processing is relatively complex, with many processes and a wide range of scope, and the daily production process of oilfield enterprises needs to be arranged in a planned manner, which requires the use of AI technology to realize the role of intelligent operation scheduling in the oilfield, planning the work of oilfield enterprises, and ensuring that the enterprise's production and construction are carried out in an orderly and efficient manner.</div>
                </div>
              </div>


            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign25"></div>
                <div class="titleDesign">
                  <div class="Title1">&nbsp;Active Early Warning Of Equipment Failure</div>
                  <div class="Title2">The online monitoring system can analyze the working status of the equipment, predict equipment failures and provide timely alarms to avoid downtime and prolonged maintenance caused by equipment failures.</div>
                </div>
              </div>


            </div>
          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight">Successful Cases</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in success" :key="item">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img :src="item.img"/>
                    </div>
                    <div class="titleDesign">
                      <div class="title1">{{ item.title1 }}</div>
                      <div class="title2" v-html="item.title2"></div>
                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <div ref="bottom">
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
               <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import PartnerTip from "@/components/partnerTip.vue";
import consultTip from "@/components/consultTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import {shareUrl} from "@/utils/vxshare";

export default {

  name: "",

  props: [],

  components: {bottomTip, consultTip, PartnerTip, headtip,kefuMobile},

  data() {

  return {changeVisable:1,
      title:'',
      pageTitle: 'Process GPTs_Oil & Gas industry process large model artificial intelligence application-Oil & Gas Communication GPT',
      pageDescription: 'The Oil & Gas GPT Cloud Platform provides process-oriented GPTs, which not only contribute to enhancing development effectiveness and recovery rates but also minimize risk and losses during the development process, ensuring the safe and stable exploitation of Oil & Gas fields.',
      pageKeywords: 'Process GPTs, process GPTs, intelligent geosteering, intelligent injection and production decision-making, Oil & Gas field video monitoring, drilling risk warning, artificial lift optimization, mechanical production intelligent management and control, Oil & Gas field energy consumption optimization, Oil & Gas communication, Oil & Gas communication GPT, Oil & Gas communication GPT cloud Platform, Oil & Gas channel large model, Oil & Gas channel GPT large model',

      model:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[
        {
          img:require("@/assets/gpt26.png"),
          title1:"XX Deep Coalbed Methane Drilling Project",
          title2:"Anton entered the construction of XX coal project since the third quarter of 2022, with 2 rigs (50LDB with top drive) involved, and constructed a total of 10 deep CBM horizontal wells, with an average depth of 3,530m and a horizontal section length of 1,000-1,500m, with an average drilling cycle of 30.48 days (the shortest of which is 27.58 days), and a number of single wells or openings have set a record for the block, which is highly recognized by the client.",
        },
        {
          img:require("@/assets/gpt46.png"),
          title1:"Intelligent Oil Recovery Robot Service",
          title2:"        <li style=\"margin-top: 1vh\">For the pumping machine wells, real-time data such as pressure and temperature, electrical parameters, schematic diagrams, dynamic liquid level, etc. are collected and inputted into the process brain of the intelligent oil recovery robot, which outputs the optimized production and operation parameters, reduces the number of ineffective strokes, and puts an end to empty pumping, so that the pumping machine operates under the optimal working conditions and the wells can maintain a stable dynamic liquid level, thus achieving a balance between supply and extraction of the wells, and achieving a steady and increased production of the oil wells.</li>\n" +
              "                        <li style=\"margin-top: 0.5vh\">Application performance: The intelligent oil recovery robot has been tested in Dagang Oilfield, North China Oilfield, Shengli Oilfield, Zhongyuan Oilfield, Jianghan Oilfield and Yanchang Petroleum, and has been running stably and achieved good application results.</li>",
        },
        {
          img:require("@/assets/gpt56.png"),
          title1:"XX Petroleum Gas Dehydrocarbonization And Pressurization Intelligent Field Station Project",
          title2:"<span style=\"margin-top: 3vh;font-weight: bold\">Equipment Detection, Accurate And Real-time:</span><br>  " +
              "According to the intelligent target demand, supporting efficient new technology and new products, through on-site integration and application, all-weather real-time monitoring of equipment operating conditions.<br> <span style=\"font-weight: bold\">Program Results：</span>  <li>Reduce maintenance costs: timely detection of potential failures and problems, more preventive and accurate maintenance, reduce maintenance costs;</li>\n" +
              "                        <li style=\"margin-top: 0.5vh\">Enhance equipment reliability: Reduce equipment failures and downtime, increase equipment life;</li>\n" +
              "                        <li style=\"margin-top: 0.5vh\">Optimize resource utilization: Real-time monitoring and data analysis help to better utilize resources, including energy, raw materials and human resources, and reduce costs.</li>",
        },
      ]
    }

  },
  beforeRouteLeave(to, from, next) {
    // 保存滚动位置
    this.scrollPosition = this.$refs.totalModel.scrollTop;
    next();
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 恢复滚动位置
      vm.$nextTick(() => {

        vm.$refs.totalModel.scrollTop = vm.scrollPosition;

      });
    });
  },
  methods: {
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      const element = this.$refs.totalModel;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    clickConsult(item){

      console.log(item)
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    detail(){
      this.$router.push("/Stratum")
    },
    detail1(){
      this.$router.push("/frack")
    },
    detail2(){
      this.$router.push("/decision1")
    },
    detail3(){
      this.$router.push("/monitior1")
    },
    detail4(){
      this.$router.push("/alarm")
    },
    detail5(){
      this.$router.push("/drilling")
    },    detail6(){
      this.$router.push("/plug")
    },
    showPartner() {
      this.dialogconsult = true;
    },

    hideDialog() {
      this.dialogVisible = false;
    },
    hide() {
      this.dialogconsult = false;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    // if (localStorage.getItem("skip") === '3') {
    //   this.$nextTick(() => {
    //     this.skipp3();
    //   });
    // }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Process GPTs_Oil & Gas industry process large model artificial intelligence application-Oil & Gas Communication GPT',
        pageDescription: 'The Oil & Gas GPT Cloud Platform provides process-oriented GPTs, which not only contribute to enhancing development effectiveness and recovery rates but also minimize risk and losses during the development process, ensuring the safe and stable exploitation of Oil & Gas fields.',
        pageKeywords: 'Process GPTs, process GPTs, intelligent geosteering, intelligent injection and production decision-making, Oil & Gas field video monitoring, drilling risk warning, artificial lift optimization, mechanical production intelligent management and control, Oil & Gas field energy consumption optimization, Oil & Gas communication, Oil & Gas communication GPT, Oil & Gas communication GPT cloud Platform, Oil & Gas channel large model, Oil & Gas channel GPT large model',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.total {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 74vh;
    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
    background-image: url("../../assets/gpt21.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */


    .layer2 {
      position: absolute;
      top: 16.1495673671199vh;
      left: 9.17157712305026vw;

      .title1 {
        width: fit-content;
        height: 8.77626699629172vh;
        font-size: 3.50606585788562vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2f6eff;
        line-height: 12.23733003708282vh;
      }

      .title2 {
        margin-top: 4.82076637824475vh;
        width: 33.45869439630272vw;
        height: fit-content;
        font-size: 1.35979202772964vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #2f6eff;
        line-height: 5.31520395550062vh;
      }
      .title4 {
        margin-top: 2.82076637824475vh;
        width: 33.45869439630272vw;
        font-size: 1vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        line-height: 3.31520395550062vh;
      }
      .titlle3{
        margin-top: 5.07911001236094vh;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.27vw;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        border-radius: 53px;
        width: fit-content;
        padding: 2.8vh 1.5989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }

    //.layer3 {
    //  position: absolute;
    //  top: 5.06798516687268vh;
    //  right: 11.61178509532062vw;
    //  width: 47.42923165800116vw;
    //  height: 72.80593325092707vh;
    //  background-image: url("../assets/GPTplant.png");
    //  background-size: 100% auto;
    //  background-position: center;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  opacity: 1;
    //}
  }
  .screenmake0 {
    padding: 7.78739184177998vh 11.09763142692085vw;
    width: 100%;
    height: fit-content;
    background: white;

    .heighLight {
      margin: 0 auto;
      text-align: center;
      height:8.23733003708282vh;
      opacity: 1;
      font-size: 1.7vw;
      //color: #53A3F7;
      font-family: Instrument Sans,Arial;
      line-height: 4.23733003708282vh;

    }

    .heightLight2 {
      margin:0 auto;
      text-align: center;
      height: 13.78739184177998vh;
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;

      width: 60.9693818602vw;
      font-size: 1vw;
      font-family: Instrument Sans,Arial;
    }
    .grid {
      //margin-top: 3.78739184177998vh;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 1vw;

      .card1 {
        //cursor: pointer;/
        width: 100%;
        height: fit-content;
        padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;

        .back {
          border-radius: 5px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height: fit-content;
          overflow: hidden;
          background: #FFFFFF;
          margin-bottom: 1.5248454883vh;

          .imgDesign {
            text-align: center;
            height: 69vh;
            width: 100%;
            background-image: url("../../assets/gpt22.jpg");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign {
            padding: 1.5248454883vh 1.5248454883vw;
            //background-color: #2F6EFF;
            .Title1 {
              color: black;
              text-align: center;
              font-size: 1vw;

               font-family: Instrument Sans,Arial;
              font-weight: 700;
            }

          }



        }

      }

    }
  }
  .screenmake2{
    padding: 7.78739184177998vh 11.09763142692085vw;
    width: 100%;width: 100%;
    height: fit-content;
    background: #ebf1fd;
    .heighLight{
      display: flex;
      justify-content: center;
      text-align: center;
      height:8.23733003708282vh;
      font-size: 1.7vw;
      font-family: Instrument Sans,Arial;
      font-weight: 400;
      color: #FF6400;
      line-height: 4.23733003708282vh;
    }

    .grid{
      margin-top:2.78739184177998vh ;
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
      column-gap:0.81317157712305vw ;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:70.87144622991347vh ;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 30.69962917181706vh;
            opacity: 1;
            background-image: url("../../assets/gpt23.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 36vh;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 2.7194066749vh;
              font-size: 1vw;
               font-family: Instrument Sans,Arial;
              font-weight: 700;
              color:#FF6400;
              line-height: 2.7194066749vh;
            }
            .Title2{

              margin: 3vh 1.8597920277vw auto 1.8597920277vw;
              //text-align: justify;
              height: 4.6971569839vh;
              font-size: 0.8243212016vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              line-height: 3.194066749vh;
            }
            .backconsult{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);

              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
        .back:hover{
          .imgDesign {
            transform: scale(1.05);
          }

        }}

      .card1{
        border-radius: 16px;
        //cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:70.87144622991347vh ;
          overflow:hidden;
          background:white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 30.69962917181706vh;
            opacity: 1;
            background-image: url("../../assets/gpt24.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 36vh;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 2.7194066749vh;
              font-size: 1vw;
               font-family: Instrument Sans,Arial;
              font-weight: 700;
              color:#FF6400;
              line-height: 2.7194066749vh;
            }
            .Title2{

              margin: 3vh 1.8597920277vw auto 1.8597920277vw;
              //text-align: justify;
              height: 4.6971569839vh;
              font-size: 0.8243212016vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              line-height: 3.194066749vh;
            }
          }
        }
      }
      .card1:hover{
        .imgDesign {
          transform: scale(1.05);
        }
      }
      .card2{
        border-radius: 16px;
        //cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:70.87144622991347vh ;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 30.69962917181706vh;
            opacity: 1;
            background-image: url("../../assets/gpt25.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 36vh;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 2.7194066749vh;
              font-size: 1vw;
               font-family: Instrument Sans,Arial;
              font-weight: 700;
              color:#FF6400;
              line-height: 2.7194066749vh;
            }
            .Title2{

              margin: 3vh 1.8597920277vw auto 1.8597920277vw;
              //text-align: justify;
              height: 4.6971569839vh;
              font-size: 0.8243212016vw;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              line-height: 3.194066749vh;
            }
          }
        }
      }
      .card2:hover{
        .imgDesign {
          transform: scale(1.05);
        }
      }
    }
  }
  .screenmake3{
    padding: 7.78739184177998vh 11.09763142692085vw;
    width: 100%;
    height: fit-content;
    background: #212a3c;
    .heighLight{
      color:white;
      text-align: center;
      height: 7.54017305315204vh;
      font-size: 1.7vw;
      font-family: Instrument Sans,Arial;
      font-weight: 400;
      line-height: 4.23733003708282vh;
    }

    .grid{
      margin-top:2.78739184177998vh ;
      .card{
        cursor: pointer;
        width: 100%;
        height:65.87144622991347vh ;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 5px;
          display: flex;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          height:65.87144622991347vh ;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: 45.60311958405546vw;
            height:100%;
            opacity: 1;
            background-image: url("../../assets/menage6.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 1.5248454883vh 1.5248454883vw 1.5248454883vh 3.5248454883vw;
            color: white;
            width: 35%;
            float: right;
            .title1{
              margin-top: 2vh;
              font-size: 1vw;
            }
            .title2{
              margin-top: 3vh;
              line-height: 3.194066749vh;
              font-size: 0.8243212016vw;
            }
            .success{
              display: grid;
              grid-template-columns: minmax(0,1fr) minmax(0,1fr);
              column-gap: 1vw;
              margin-top: 3vh;
              .suc1{

                width: 100%;
                height: 8vh;
                .title3{
                  text-align: center;
                  margin-top: 2vh;
                  font-size: 0.8243212016vw;
                }

              }

            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height:65.87268232385661vh !important;
      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }
      ::v-deep .el-carousel__container {
        position: relative;
        height: 48.83559950556242vh;
      }
      ::v-deep .el-carousel__arrow {
        display: none;
        border: none;
        outline: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 0;
        width: 1.15540150202195vw;
        height: 4.32632880098888vh;
        //background: #2168DB;
        color: #fff;
        position: absolute;
        top: 50%;
        z-index: 10;
        transform: translateY(-50%);
        text-align: center;
        font-size: 12px;
      }
    }
  }
}

.totalModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 540px;
    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
    background-image: url("../../assets/gptModel21.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */
    .titlle3{
      position: absolute;
      bottom:80px;
      left:50%;
      transform: translateX(-60%);
      font-size: 12px;
       font-family: Instrument Sans,Arial;
      font-weight: 400;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 53px;
      width: fit-content;
      margin: 0 15px;
      padding: 5px 4.6875vw;
      line-height: 15px;
      height: 35px;
      color:white;
      background-color: #2f6eff;
      border: 1px solid #2f6eff;
      overflow: hidden;


    }
    .title4{
      position: absolute;
      left:5%;
      bottom:-105px;
      margin: 35px auto 0 auto;
      width: 90%;
      height: fit-content;
      font-size: 12px;
      font-family: Instrument Sans,Arial;
      font-weight: 400;
      color: #111111;
      line-height: 18px;
      border-radius: 15px;
      padding: 15px;
      background:white;
      border: #e8eefa 1px solid;

    }
    .layer2 {
      position: absolute;
      left:0;

      .title1 {
        margin:35px auto 0 auto;
        width: fit-content;
        //text-align: center;
        height: fit-content;
        font-size: 22px;
        font-family: Instrument Sans,Arial;
        font-weight: 500;
        color: #111111;
        line-height: 22px;
      }

      .title2 {
        margin: 16px auto;
        width:85%;
        //text-align: center;
        height: fit-content;
        font-size: 12px;
        font-family: Instrument Sans,Arial;
        font-weight: 400;
        color: #111111;
        line-height: 20px;
      }
    }

  }

  .screenmake2{
    padding:135px 4.6875vw 20px 4.6875vw;
    width: 100%;
    height: fit-content;
    background: #ebf1fd;
    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      font-family: Instrument Sans,Arial;
      font-weight: 700;
      line-height: 26px;

    }




    .grid{
      margin-top:2.78739184177998vh ;
      display: grid;
      grid-template-columns: minmax(0,1fr);
      column-gap:0 ;
      row-gap: 15px;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 170px;
            opacity: 1;
            background-image: url("../../assets/gpt23.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign1{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 170px;
            opacity: 1;
            background-image: url("../../assets/gpt33.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign2{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 170px;
            opacity: 1;
            background-image: url("../../assets/gpt43.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign3{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 170px;
            opacity: 1;
            background-image: url("../../assets/gpt53.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 180px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: fit-content;
              font-size: 14px;
               font-family: Instrument Sans,Arial;
              font-weight: 700;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 4; /* 限制在一个块元素显示的文本的行数 */
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 8px 12px 40px 12px;
              ////text-align: justify;
              height: 88px;
              font-size: 12px;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              line-height: 22px;

            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 12px;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 12px;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card2{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 190px;
            opacity: 1;
            background-image: url("../../assets/gpt25.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 190px;
            opacity: 1;
            background-image: url("../../assets/gpt25.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign21{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 190px;
            opacity: 1;
            background-image: url("../../assets/gpt44.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign22{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 190px;
            opacity: 1;
            background-image: url("../../assets/gpt45.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign23{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 190px;
            opacity: 1;
            background-image: url("../../assets/gptModel53.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign24{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 190px;
            opacity: 1;
            background-image: url("../../assets/gpt55.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign25{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 190px;
            opacity: 1;
            background-image: url("../../assets/gpt64.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{

              margin-top: 200px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: fit-content;
              font-size: 14px;
               font-family: Instrument Sans,Arial;
              font-weight: 700;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{

              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 8px 12px 32px 12px;
              ////text-align: justify;
              height: 110px;
              font-size: 12px;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 12px;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 12px;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card1{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            height: 170px;
            opacity: 1;
            background-image: url("../../assets/gpt34.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign11{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            height: 170px;
            opacity: 1;
            background-image: url("../../assets/alarm1.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{

              margin-top: 180px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: fit-content;
              font-size: 14px;
               font-family: Instrument Sans,Arial;
              font-weight: 700;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
              overflow: hidden;
              text-overflow: ellipsis;

              margin: 8px 12px 30px 12px;
              ////text-align: justify;
              height: 110px;
              font-size: 12px;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 12px;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 12px;
              font-family: Instrument Sans,Arial;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  .screenmake3{
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;

    padding:20px 15px;
    background: black;
    .heighLight{
      text-align: center;
      height:21px;
      opacity: 1;
      font-size: 21px;
      color: white;
      font-family: Instrument Sans,Arial;
      font-weight: 700;
      line-height: 26px;
    }
    .heightLight2{
      margin:19px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 12px;
      font-family: Instrument Sans,Arial;
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }
    .grid{
      margin-top:2.78739184177998vh ;
      position: relative;

      .card{
        cursor: pointer;
        width: 98%;
        margin:0 auto;
        min-height: 280px ;
        padding:0 0.69324090121317vw 0 0;
        opacity: 1;
        background: #19202e;
        .back{
          border-radius: 5px;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          min-height: 540px ;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: 100%;
            height:220px;
            opacity: 1;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 15px 20px 25px 20px;
            color: white;
            width:100%;
            float: right;
            .title1{
              text-align: center;
              margin-bottom:10px;
              font-size: 14px;
            }
            .title2{
              height: 180px;
              // line-height: 20px;
              font-size:12px;
              ::v-deep li{
                margin-top:5px;
              }
            ;
            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height: 540px !important;

      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }

      ::v-deep .el-carousel__arrow {
        display: block;
        //border: 1px solid white;
        outline: -5px;
        padding: 0;
        margin: -165px -10px  ;
        cursor: pointer;

        border-radius: 0;
        width: 25px;
        height: 48px;
        background: rgba(245, 246, 250, 0.8);
        color: #131313;
        position: absolute;
        top: 50%;
        z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-size: 12px;
      }
    }
  }
}
</style>
